import { Image, Text, View } from "@react-pdf/renderer";
import PageLayoutV2 from "../PageLayoutV2";
import PageOverviewDetailV2 from "./PageOverviewDetailV2";
import styles from "./styles";
import NoImage from "assets/images/noImage.png";
import { getDecimalFormating, getFormatedDate } from "utils";

interface PageOverviewV2Props {
  detail: any;
  pageNumber?: string | number;
  id?: string;
  mapData?: any;
}

const FooterView = ({ detail }: any) => {
  return (
    <View style={styles.footer.view}>
      <View style={styles.footer.description.view}>
        <Text style={styles.footer.description.title}>Estimated Timeline</Text>
        <Text style={styles.footer.description.value}>
          The dates are subject to change based on the issuance of additional
          agreements, including but not limited to an Acknowledgment of
          Commencement.
        </Text>
      </View>
      <View style={styles.footer.date.view}>
        {!!detail?.execution_date && (
          <View
            style={{
              ...styles.footer.date.item.view,
              width: `30%`,
            }}
          >
            <Text style={styles.footer.date.item.date}>
              {getFormatedDate(detail?.execution_date)}
            </Text>
            <Text style={styles.footer.date.item.label}>Execution</Text>
          </View>
        )}
        {!!detail?.commencement_date && (
          <View
            style={{
              ...styles.footer.date.item.view,
              width: `30%`,
            }}
          >
            <Text style={styles.footer.date.item.date}>
              {getFormatedDate(detail?.commencement_date)}
            </Text>
            <Text style={styles.footer.date.item.label}>Commencement</Text>
          </View>
        )}
        {!!detail?.expiration_date && (
          <View
            style={{
              ...styles.footer.date.item.view,
              width: "40%",
              textAlign: "right",
            }}
          >
            <Text style={styles.footer.date.item.date}>
              {getFormatedDate(detail?.expiration_date)}
            </Text>
            <Text style={styles.footer.date.item.label}>Expiration</Text>
          </View>
        )}
      </View>
      <View style={styles.footer.progress.view}>
        <View
          style={{
            width: `30%`,
            backgroundColor: "#9FC5D3",
          }}
        />
        <View
          style={{
            width: `70%`,
            backgroundColor: "#4D788B",
          }}
        />
      </View>
    </View>
  );
};

function PageOverviewV2<T extends object>({
  detail,
  pageNumber,
  id,
  mapData,
}: PageOverviewV2Props) {
  let suiteLabel = "";
  detail?.spaces
    ?.filter((space: any) => space?.active_status === 1)
    ?.forEach((space: any) => {
      if (!space?.name && !space?.floor) return;
      if (!!suiteLabel?.length) suiteLabel += ", ";
      if (!!space?.name) {
        suiteLabel += `Suite ${space?.name}`;
        return;
      }

      if (!!space?.floor) {
        suiteLabel += `Floor ${space?.floor}`;
        return;
      }
    });

  return (
    <PageLayoutV2
      pageNumber={pageNumber}
      id={id}
      detail={detail}
      footerStyle={{
        backgroundColor: "#F6F9FA",
      }}
    >
      <View style={styles.view}>
        <Text style={{ ...styles.title, marginBottom: 44 }}>
          Lease Overview
        </Text>
        <View style={styles.section.view}>
          {/* Left */}
          <View style={styles.section.left.view}>
            {!!detail?.building_images?.length ? (
              <Image
                src={detail?.building_images?.[0] || ""}
                style={styles.section.left.image}
              />
            ) : (
              <Image src={NoImage} style={styles.section.left.image} />
            )}
            <View style={styles.section.left.building.view}>
              {!!detail?.building_name?.length && (
                <Text
                  style={{
                    ...styles.section.left.building.label,
                    marginBottom: 8,
                  }}
                >
                  {detail?.building_name || ""}
                </Text>
              )}
              <View
                style={{
                  marginBottom: 10,
                }}
              >
                {!!detail?.building_address?.length && (
                  <Text style={styles.section.left.building.label}>
                    {detail?.building_address || ""}
                  </Text>
                )}
                {(!!detail?.building_city?.length ||
                  !!detail?.building_state?.length) && (
                  <Text
                    style={{
                      ...styles.section.left.building.label,
                    }}
                  >
                    {[detail?.building_city, detail?.building_state]?.join(
                      ", ",
                    )}
                  </Text>
                )}
              </View>

              {!!suiteLabel?.length && (
                <Text
                  style={{
                    ...styles.section.left.building.label,
                  }}
                >
                  {suiteLabel}
                </Text>
              )}
            </View>
          </View>

          {/* Right */}
          <View style={styles.section.right.view}>
            <View style={styles.section.right.info.view}>
              <View style={{ marginBottom: 12 }}>
                <Text style={{ ...styles.section.right.info.item.value }}>
                  {!!detail?.spaces?.[0]?.total_rsf
                    ? `${
                        getDecimalFormating(detail?.spaces?.[0]?.total_rsf) ||
                        ""
                      } RSF`
                    : ""}
                </Text>
                <Text style={{ ...styles.section.right.info.item.label }}>
                  Total Size
                </Text>
              </View>

              <View style={{ marginBottom: 12 }}>
                <Text style={{ ...styles.section.right.info.item.value }}>
                  {!!detail?.rent?.term
                    ? `${detail?.rent?.term || ""} months`
                    : ""}
                </Text>
                <Text style={{ ...styles.section.right.info.item.label }}>
                  Term
                </Text>
              </View>

              <View>
                <Text style={{ ...styles.section.right.info.item.value }}>
                  {!!detail?.rent?.rent
                    ? `$${getDecimalFormating(
                        detail?.rent?.rent,
                        true,
                      )}/RSF/Year`
                    : ""}
                </Text>
                <Text style={{ ...styles.section.right.info.item.label }}>
                  Base Rent
                </Text>
              </View>
            </View>
            <Image
              src={!!mapData?.image ? mapData?.image : NoImage}
              style={styles.section.right.image}
            />
          </View>
        </View>
        {(!!detail?.expiration_date ||
          !!detail?.commencement_date ||
          !!detail?.execution_date) && <FooterView detail={detail} />}
      </View>
    </PageLayoutV2>
  );
}

PageOverviewV2.Detail = PageOverviewDetailV2;

export default PageOverviewV2;
