import { Disclosure, Menu, Transition } from "@headlessui/react";
import React, { Fragment, useContext, useEffect, useState } from "react";
import cn from "classnames";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import JllLogo from "assets/images/jll-logo.svg";
import { Avatar, Button, SelectAutoComplete } from "ui-atoms";
import {
  ExpandLess,
  ExpandMore,
  FileDownloadOutlined,
  ListAltOutlined,
  PictureAsPdfOutlined,
} from "@mui/icons-material";
import { useApiCall } from "hooks";
import {
  getContactUsersAPI,
  getDetailAPI,
  getKeyDatesAPI,
  getMapAPI,
  getUsersAPI,
  logoutApi,
  switchUserAPI,
} from "services";
import { GlobalContext } from "context";
import {
  BASE_RENT_COLUMNS,
  DEFAULT_LEASE_OVERVIEW_FIELDS,
  SEARCH_RESULT_LIMIT,
  SET_CONFIRM_MODAL,
  SET_FULL_LOADING,
  SET_LEASE_PDF,
} from "constant";
import ReactPDF from "@react-pdf/renderer";
import {
  groupBy,
  transformObject,
  getDetailValue,
  sliceIntoChunks,
  getFormatedDateWithMonth,
  getDecimalFormating,
  overrideObject,
  getFormatedDate,
} from "utils";
import { DeliverablePDF } from "pages";
import { LeasePDFViewer } from "ui-molecules";
import RemoveDescriptionItem from "./RemoveDescriptionItem";
import { UserProps } from "types";
import { renderToString } from "react-dom/server";

interface INavbar {
  className?: string;
}

const NavbarDetail: React.FC<INavbar> = ({ className }) => {
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const mainSession = JSON.parse(
    sessionStorage.getItem("mainSession") || "null",
  );
  const navigate = useNavigate();
  const { state, dispatch } = useContext(GlobalContext);
  const { detailInfo, meta } = state;
  const [getDetail] = useApiCall(getDetailAPI);
  const [getKeyDates] = useApiCall(getKeyDatesAPI);
  const [getContactUsers] = useApiCall(getContactUsersAPI);
  const [getMap] = useApiCall(getMapAPI);
  const [getUsers] = useApiCall(getUsersAPI);
  const [switchUser] = useApiCall(switchUserAPI);

  const [searchParams, setSearchParams] = useSearchParams();
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [logout] = useApiCall(logoutApi);

  useEffect(() => {
    if (!session || session?.id === currentUser?.value) return;
    setCurrentUser({
      label: session?.get_full_name || session?.email,
      value: session?.id,
    });
    dispatch({
      type: SET_LEASE_PDF,
      payload: 0,
    });
  }, [session]);

  const onClickLogout = () => {
    logout().then((data: any) => {
      window.location.href = data?.redirect;
    });
  };

  const handleDownload = async () => {
    try {
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: true,
          label: "Generating PDF...",
        },
      });
      const detail = await getDetail(detailInfo?.id);
      const keyRes = await getKeyDates({
        lease_closeout: detailInfo?.id,
        page: 1,
        limit: SEARCH_RESULT_LIMIT,
        active_status: [1],
      });
      const keyData = keyRes?.docs || [];
      const userRes = await getContactUsers({
        lease_closeout: [detailInfo?.id],
        page: 1,
        active_status: [1],
        limit: SEARCH_RESULT_LIMIT,
      });
      const userData = userRes?.docs || [];

      const mapRes = await getMap({
        id: detailInfo?.id,
        zoom: 13,
        width: 350,
        height: 350,
      });
      const mapData = mapRes || {};

      let rentData;
      if (!detail?.rent?.use_custom) {
        if (detail?.rent?.overrides) {
          const { cols, rows } = overrideObject(detail?.rent?.overrides);
          let newRows: any = [];
          rows?.forEach((row: any) => {
            const dates = row?.[0]?.split("-");
            newRows.push([
              `${dates?.[0] ? getFormatedDateWithMonth(dates?.[0]) : ""}${dates?.[0] && dates?.[1] ? "-\n" : ""}${dates?.[1] ? getFormatedDateWithMonth(dates?.[1]) : ""}`,
              row?.[1],
              `${row?.[2] ? "$" : ""}${row?.[2] || ""}`,
              `${row?.[3] ? "$" : ""}${row?.[3] || ""}`,
              `${row?.[4] ? "$" : ""}${row?.[4] || ""}`,
            ]);
          });
          rentData = {
            ...detail?.rent,
            columns: cols,
            rows,
          };
        } else {
          const cols = BASE_RENT_COLUMNS?.map(({ label }) => label);
          const grid = transformObject(detail?.rent?.grid) || [];
          const rows = grid?.map((rent) => {
            return [
              `${rent?.["Period Start"] ? getFormatedDateWithMonth(rent?.["Period Start"]) : ""}${rent?.["Period Start"] && rent?.["Period End"] ? "-\n" : ""}${rent?.["Period End"] ? getFormatedDateWithMonth(rent?.["Period End"]) : ""}`,
              `${rent?.["Month Start"] || ""}-${rent?.["Month End"] || ""}`,
              `${rent?.["Rent PSF"] ? "$" : ""}${getDecimalFormating(Number(rent?.["Rent PSF"]?.toString()?.replace(",", "")), true)}`,
              `${rent?.["Monthly Cost"] ? "$" : ""}${getDecimalFormating(Number(rent?.["Monthly Cost"]?.toString()?.replace(",", "")), true)}`,
              `${rent?.["Annual Cost"] ? "$" : ""}${getDecimalFormating(Number(rent?.["Annual Cost"]?.toString()?.replace(",", "")), true)}`,
            ];
          });
          rentData = {
            ...detail?.rent,
            columns: cols,
            rows,
          };
        }
      } else {
        const { cols, rows } = overrideObject(detail?.rent?.custom);
        let newRows: any = [];
        let colCount = 0;
        cols?.forEach((col: string, idx: number) => {
          if (!col?.length) return;
          colCount = idx + 1;
        });
        rows?.forEach((row: any) => {
          let subRows = [];
          row?.forEach((subRow: string, idx1: number) => {
            subRows.push(subRow);
            if (!!subRow?.length && colCount < idx1 + 1) {
              colCount = idx1 + 1;
            }
          });
          newRows.push([row?.[0], row?.[1], row?.[2], row?.[3], row?.[4]]);
        });
        rentData = {
          ...detail?.rent,
          columns: cols,
          rows,
          colCount,
        };
      }

      const new_contacts: any = detail?.groups
        ?.map((group: any) => {
          const contacts: any =
            groupBy(
              detail?.contacts?.filter(
                (contact: any) =>
                  contact?.lease_closeout_contact_group?.id === group?.id,
              ),
              "company_name",
            ) || [];

          return { ...group, contacts };
        })
        ?.filter(
          (item: any) =>
            !!item?.contacts && !!Object?.keys(item?.contacts)?.length,
        )
        ?.map((group: any) => {
          let row_contacts: any = [];
          const company_list = Object.keys(group?.contacts);
          let index = 0;
          for (; index < company_list?.length; ) {
            if (
              group?.contacts?.[company_list?.[index]]?.length === 1 &&
              !!company_list?.[index + 1] &&
              group?.contacts?.[company_list?.[index + 1]]?.length === 1
            ) {
              row_contacts.push({
                company1: company_list?.[index],
                company2: company_list?.[index + 1],
                rows: [
                  ...group?.contacts?.[company_list?.[index]],
                  ...group?.contacts?.[company_list?.[index + 1]],
                ],
              });
              index += 2;
            } else {
              const chunks = sliceIntoChunks(
                group?.contacts?.[company_list?.[index]],
                2,
              );
              chunks?.forEach((chunk: any, idx1: number) => {
                row_contacts.push({
                  company1: idx1 === 0 ? company_list?.[index] : null,
                  company2: null,
                  rows: [...chunk],
                });
              });
              index++;
            }
          }

          return { ...group, contacts: row_contacts };
        });

      const totalHeight = 550;
      const titleHeight = 30; // 20 + 10
      const companyHeight = 20; // 16 + 6
      const contactHeight = 12;
      const groupBottomH = 16;
      const sectionBottomH = 16;

      let currentHeight = 0;
      let contactsPerPage: any = [];
      let currentContactPage: any = {};

      [...(new_contacts || [])]?.forEach((group: any, idx: number) => {
        if (
          currentHeight +
            titleHeight +
            companyHeight +
            contactHeight * 3 +
            sectionBottomH >
          totalHeight
        ) {
          currentHeight = 0;
          contactsPerPage.push(currentContactPage);
          currentContactPage = {};
        }
        currentHeight += titleHeight;

        group?.contacts?.forEach((contact: any) => {
          let contactH = !!contact?.company1
            ? contactHeight * 3 + companyHeight
            : contactHeight * 3;
          contactH += sectionBottomH;
          if (currentHeight + contactH > totalHeight) {
            contactsPerPage.push(currentContactPage);
            currentContactPage = {};
            currentContactPage[group?.id] = { contacts: [contact] };
            currentHeight = contactH;
          } else {
            if (!!currentContactPage?.[group?.id]) {
              let current_contacts = currentContactPage?.[group?.id]?.contacts;
              current_contacts.push(contact);
              currentContactPage[group?.id] = {
                ...group,
                contacts: current_contacts,
              };
            } else {
              currentContactPage[group?.id] = { ...group, contacts: [contact] };
            }
            currentHeight += contactH;
          }
        });

        currentHeight += groupBottomH;
      });

      if (!!currentContactPage && !!Object.keys(currentContactPage)?.length)
        contactsPerPage.push(currentContactPage);

      const keyTittleHeight = 24;
      const keyDesLineHeight = 14;
      const keyTotalHeight = 415;
      let keyHeight = 0;
      let keyPerPage: any = [];

      let default_data = [];

      if (detail?.commencement_date_description) {
        default_data.push({
          type: "Commencement",
          description: detail?.commencement_date_description || "",
          value_min: detail?.commencement_date,
        });
      }
      if (detail?.expiration_date_description) {
        default_data.push({
          type: "Expiration",
          description: detail?.expiration_date_description || "",
          value_min: detail?.expiration_date,
        });
      }
      if (detail?.execution_date_description) {
        default_data.push({
          type: "Execution Date",
          description: detail?.execution_date_description || "",
          value_min: detail?.execution_date,
        });
      }

      [...default_data, ...keyData]?.forEach((item: any, idx: number) => {
        if (idx === 0) {
          keyPerPage.push([]);
          keyHeight = 0;
        }
        let sectionH = keyTittleHeight + 11;

        item?.description?.split(/\r*\n/)?.forEach((str: string) => {
          sectionH += Math.ceil((str?.length || 0) / 90) * keyDesLineHeight;
        });

        sectionH += 20;

        if (keyHeight < keyTotalHeight - sectionH) {
          keyPerPage[keyPerPage?.length - 1].push(item);
          keyHeight += sectionH;
        } else {
          keyHeight = sectionH;
          keyPerPage.push([]);
          keyPerPage?.[keyPerPage?.length - 1].push(item);
        }
      });

      const totalOverviewHeight = 560;
      const lineHeight = 14;
      const paddingHeight = 16;

      let totalOverviewData: any = [];
      let overviewPerPage: any = [];
      if (detail?.deliverable?.overview) {
        detail?.deliverable?.overview?.forEach((item: any) => {
          if (!item?.isChecked) return;
          totalOverviewData.push(item);
        });
      } else {
        DEFAULT_LEASE_OVERVIEW_FIELDS.forEach(({ label, id, visible }: any) => {
          const value = getDetailValue(detail, id, meta);
          if (!value?.length || !visible) return;
          totalOverviewData.push({
            label,
            value,
          });
        });
        if (!!detail?.additional?.length)
          detail?.additional?.forEach((item: any) => {
            totalOverviewData.push({
              label: item?.name,
              value: item?.description,
            });
          });
      }

      let currentOverviewHeight = 0;
      let currentPage: any = [];
      totalOverviewData.forEach((item: any, idx: number) => {
        let tempH = 0;
        let tempDescription = "";
        let lineDescription = "";
        tempH += paddingHeight;
        item?.value?.split(/\r*\n/)?.forEach((str: string, idx1: number) => {
          if (idx1 > 0 && !!tempDescription?.length) tempDescription += "\n";
          const words = str.split(" ");
          words?.forEach((word) => {
            if (lineDescription?.length + word?.length <= 75) {
              tempDescription += `${word} `;
              lineDescription += `${word} `;
            } else {
              if (
                currentOverviewHeight + tempH + lineHeight >
                totalOverviewHeight
              ) {
                overviewPerPage.push([
                  ...currentPage,
                  { label: item.label, value: tempDescription },
                ]);
                tempDescription = `${word} `;
                lineDescription = `${word} `;
                currentPage = [];
                currentOverviewHeight = 0;
                tempH = paddingHeight;
              } else {
                tempDescription += `${word} `;
                lineDescription = `${word} `;
                tempH += lineHeight;
              }
            }
          });
          lineDescription = "";
          tempH += lineHeight;
          if (
            currentOverviewHeight + tempH + lineHeight >
            totalOverviewHeight
          ) {
            overviewPerPage.push([
              ...currentPage,
              { label: item.label, value: tempDescription },
            ]);
            tempDescription = "";
            currentPage = [];
            currentOverviewHeight = 0;
            tempH = paddingHeight;
          }
        });
        if (currentOverviewHeight + tempH > totalOverviewHeight) {
          currentOverviewHeight = 0;
          currentPage.push({ label: item?.label, value: `${tempDescription}` });
          overviewPerPage.push(currentPage);
          currentPage = [];
        } else {
          currentPage.push({ label: item?.label, value: `${tempDescription}` });
          tempDescription = "";
          currentOverviewHeight += tempH;
        }
      });
      if (!!currentPage?.length) overviewPerPage.push(currentPage);

      const blob = await ReactPDF.pdf(
        <DeliverablePDF
          detail={detail}
          contactsPerPage={contactsPerPage}
          overviewPage={overviewPerPage}
          rentData={rentData}
          meta={meta}
          keyDataPerPage={keyPerPage}
          userData={userData}
          mapData={mapData}
        />,
      ).toBlob();
      const fileUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = `lease_overview_${detail?.client || ""}_${detail?.building_name || ""}.pdf`;
      link.click();
      URL.revokeObjectURL(fileUrl);
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: false,
          label: null,
        },
      });
    } catch (err) {
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: false,
          label: null,
        },
      });
    }
  };

  const handleUser = async (option: any) => {
    try {
      if (!option) {
        const action = async () => {
          const payload = {
            user: mainSession?.id,
          };
          await switchUser(payload);
          window.location.reload();
        };

        if (!mainSession) {
          setCurrentUser({
            label: session?.get_full_name || session?.email,
            value: session?.id,
          });
          return;
        }

        dispatch({
          type: SET_CONFIRM_MODAL,
          payload: {
            open: true,
            content: {
              description: renderToString(
                <RemoveDescriptionItem
                  target={`${mainSession?.get_full_name || mainSession?.email}`}
                  description="Temporarily switch user to: "
                  suffix="This will render pages as the listed user sees them."
                />,
              ),
              action,
            },
          },
        });
        return;
      }
      if (option?.value === session?.id) return;
      const action = async () => {
        const payload = {
          user: option?.value,
        };
        await switchUser(payload);
        window.location.reload();
      };

      dispatch({
        type: SET_CONFIRM_MODAL,
        payload: {
          open: true,
          content: {
            description: renderToString(
              <RemoveDescriptionItem
                target={`${option?.label}`}
                description="Temporarily switch user to: "
                suffix="This will render pages as the listed user sees them."
              />,
            ),
            action,
          },
        },
      });
    } catch (err) {}
  };

  const loadCoordinatorOptions = async (keyword: string) => {
    return await getUsers({ keyword }).then((res: any) => {
      return res.docs.map((user: UserProps) => ({
        value: user.pk,
        label:
          user?.first_name || user?.last_name
            ? [user?.first_name, user?.last_name].join(" ")
            : user?.email,
      }));
    });
  };

  const isAdmin =
    session?.is_superuser ||
    session?.is_staff ||
    !!session?.groups?.find((group: any) => group?.id === 1);

  return (
    <>
      <Disclosure
        as="nav"
        className={cn(
          "bg-white border-b border-b-jll-stroke-subdued fixed top-0 left-0 w-full z-20",
        )}
      >
        {({ open }) => (
          <>
            <div className="mx-auto w-full px-4 sm:px-6 lg:px-8">
              <div className="flex h-20 justify-between">
                <div className="flex flex-row w-full">
                  <div className="flex flex-shrink-0 items-center w-[200px] pr-7 border-r border-r-jll-stroke-subdued">
                    <Link to="/" className="flex flex-row items-center">
                      <img
                        className="block h-7 w-auto relative mr-3"
                        src={JllLogo}
                        alt="Jll"
                      />
                      <div className="pl-4 border-l border-black">
                        <span className="text-jll-text-base-default">
                          My Leases
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="w-full px-8 flex flex-row items-center space-x-2">
                    <span>{detailInfo?.client || ""}</span>
                    <span>{"-"}</span>
                    <span>
                      {detailInfo?.building_name ||
                        detailInfo?.building_address ||
                        ""}
                    </span>
                  </div>
                  <div className="flex flex-row items-center space-x-6 pl-8">
                    {!!detailInfo?.files?.length && (
                      <Button
                        variant="secondary"
                        leadingIcon={ListAltOutlined}
                        onClick={() => {
                          dispatch({
                            type: SET_LEASE_PDF,
                            payload: 1,
                          });
                        }}
                        className="whitespace-nowrap"
                      >
                        View Lease
                      </Button>
                    )}
                    <Button
                      variant="secondary"
                      leadingIcon={PictureAsPdfOutlined}
                      onClick={() => {
                        searchParams.set("preview", "true");
                        setSearchParams(searchParams);
                      }}
                    >
                      Preview
                    </Button>
                    <Button
                      variant="primary"
                      leadingIcon={FileDownloadOutlined}
                      onClick={handleDownload}
                    >
                      Download
                    </Button>
                    {/* Profile dropdown */}
                    <Menu as="div" className="relative">
                      {({ open }) => (
                        <>
                          <div className="flex space-x-2 items-center">
                            <Menu.Button className="flex bg-white ">
                              <div className="text-jll-text-base-default flex flex-row items-center space-x-2">
                                <Avatar
                                  src=""
                                  name={session?.get_full_name || ""}
                                />
                                {open ? (
                                  <ExpandLess className="text-lg text-jll-text-base-subdued" />
                                ) : (
                                  <ExpandMore className="text-lg text-jll-text-base-subdued" />
                                )}
                              </div>
                            </Menu.Button>
                          </div>
                          {open && (
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 z-10 mt-2 w-60 origin-top-right divide-y divide-jll-stroke-subdued rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                  {isAdmin && (
                                    <Menu.Item>
                                      {({ active }) => (
                                        <button
                                          onClick={() =>
                                            navigate(`/role-management`)
                                          }
                                          className={cn(
                                            "block px-4 py-2 text-sm text-gray-700 w-full text-left",
                                            { "bg-gray-100": active },
                                          )}
                                        >
                                          Role Management
                                        </button>
                                      )}
                                    </Menu.Item>
                                  )}
                                  {(session?.is_superuser ||
                                    mainSession?.is_superuser) && (
                                    <Menu.Item disabled>
                                      <div className="px-4 py-2 text-sm text-gray-700">
                                        <SelectAutoComplete
                                          label="Switch User"
                                          name="user"
                                          className="!mb-0"
                                          onChange={(option: any) => {
                                            handleUser(option);
                                          }}
                                          value={currentUser}
                                          loadOptions={loadCoordinatorOptions}
                                        />
                                      </div>
                                    </Menu.Item>
                                  )}
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        onClick={onClickLogout}
                                        className={cn(
                                          "block px-4 py-2 text-sm text-gray-700 w-full text-left",
                                          { "bg-gray-100": active },
                                        )}
                                      >
                                        Sign Out
                                      </button>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          )}
                        </>
                      )}
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Disclosure>

      <LeasePDFViewer />
    </>
  );
};

export default NavbarDetail;
