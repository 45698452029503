import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import { useContext, useEffect, useState } from "react";
import {
  deleteBrokerAPI,
  deleteUserGroupAPI,
  getAccessAPI,
  postBrokerAPI,
  postUserGroupAPI,
} from "services";
import { UserProps } from "types";
import { Select, SelectAutoComplete } from "ui-atoms";

export interface StyledRoleValueProps {
  user: any;
  valueKey: string;
}

const StyledRoleValue: React.FC<StyledRoleValueProps> = ({
  user,
  valueKey,
}) => {
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const { state } = useContext(GlobalContext);
  const { meta } = state;
  const [brokerList, setBrokerList] = useState<any>([]);
  const [postUserGroup] = useApiCall(postUserGroupAPI);
  const [deleteUserGroup] = useApiCall(deleteUserGroupAPI);
  const [getAccess] = useApiCall(getAccessAPI);
  const [postBroker] = useApiCall(postBrokerAPI);
  const [deleteBroker] = useApiCall(deleteBrokerAPI);

  useEffect(() => {
    if (!!user?.coordinator_brokers?.length) {
      const list =
        user?.coordinator_brokers?.map((broker: any) => ({
          label: broker?.broker_name,
          value: broker?.broker_user,
          id: broker?.id,
        })) || [];
      setBrokerList(list);
    } else setBrokerList([]);
  }, [user]);

  const onChangeRole = (user: any, groups: any[]) => {
    const userGroups = user?.group || [];
    const updateGroups = [...groups]?.map((x) => x.value) || [];
    const deleteGroups =
      [...userGroups]?.filter((x) => {
        return !updateGroups.includes(x);
      }) || [];

    updateGroups?.forEach((group) => {
      postUserGroup({
        user: user.pk,
        group,
      });
    });

    deleteGroups?.forEach((group) => {
      deleteUserGroup({
        user: user.pk,
        group,
      });
    });
  };

  const handleBroker = (options: any) => {
    try {
      const values = options?.map((option: any) => option?.value);
      const originVals = brokerList?.map((origin: any) => origin?.value);

      if (values?.length > originVals?.length) {
        const newVals = values?.filter(
          (val: any) => !originVals?.includes(val),
        );
        newVals?.forEach((val: any) => {
          const data = options?.find((option: any) => option?.value === val);
          postBroker({
            user: user?.pk,
            broker_user_json: data?.json,
          }).then((res: any) => {
            if (!res) return;
            if (
              !brokerList?.find(
                (broker: any) => broker?.value === res?.broker_user,
              )
            )
              setBrokerList([
                ...brokerList,
                {
                  value: res?.broker_user,
                  label: res?.broker_name,
                  id: res?.id,
                },
              ]);
          });
        });
      } else if (values?.length < originVals?.length) {
        const removedVals = originVals?.filter(
          (val: any) => !values?.includes(val),
        );
        const idList = [...brokerList]
          ?.filter?.((broker: any) => removedVals?.includes(broker?.value))
          ?.map((broker: any) => broker?.id);
        idList?.forEach(async (val: any) => {
          await deleteBroker(val);
        });
        const filteredBrokerList = [...brokerList]?.filter?.(
          (broker: any) => !removedVals?.includes(broker?.value),
        );
        setBrokerList(filteredBrokerList);
      }
    } catch (err) {}
  };

  const loadBrokerOptions = async (keyword: string) => {
    if (!keyword?.length) {
      return [];
    }
    return await getAccess({ keyword }).then((res: any) => {
      return res.data.map((user: any) => ({
        json: user,
        value: user?.userId,
        label:
          user?.displayName ||
          [user?.firstName, user?.lastName]?.join(" ") ||
          user?.email,
      }));
    });
  };

  return (
    <>
      {(() => {
        switch (valueKey) {
          case "name":
            return (
              <>
                {user?.["display_name"] ||
                  [user?.["first_name"], user?.["last_name"]]?.join(" ") ||
                  ""}
              </>
            );

          case "role":
            return (
              <Select.Role
                options={
                  [...(meta?.user_group || [])]?.map((x: any) => ({
                    value: x?.id,
                    label: x?.name,
                  })) || []
                }
                selectedOptions={
                  user?.group?.map((groupId: number, index: number) => ({
                    value: groupId,
                    label: user?.group_name?.[index] || "",
                  })) || []
                }
                onChange={(options: any) => onChangeRole(user, options)}
              />
            );

          case "team":
            return (
              <SelectAutoComplete
                name="broker"
                className="!mb-0 min-w-[150px]"
                onChange={(option) => {
                  handleBroker(option);
                }}
                value={brokerList}
                loadOptions={loadBrokerOptions}
                isMenuPortalTarget={true}
                isMulti
                size="md"
                isDisabled={!user?.group?.includes(2)}
              />
            );

          default:
            if (user?.hasOwnProperty(valueKey)) {
              return <>{user?.[valueKey] || ""}</>;
            }
            return <>-</>;
        }
      })()}
    </>
  );
};

export default StyledRoleValue;
