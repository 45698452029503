import { getDecimalFormating, getFormatedDate } from "utils";

export const getDetailValue = (detail: any, valueKey: string, meta: any) => {
  switch (valueKey) {
    case "address":
      return (
        [
          detail?.building_address,
          detail?.building_city,
          detail?.building_state,
        ].join(", ") || ""
      );

    case "suite":
      let suiteLabel = "";
      detail?.spaces
        ?.filter((space: any) => space?.active_status === 1)
        ?.forEach((space: any) => {
          if (!space?.name && !space?.floor) return;
          if (!!suiteLabel?.length) suiteLabel += ", ";
          if (!!space?.name) {
            suiteLabel += `Suite ${space?.name}`;
            return;
          }
          if (!!space?.floor) {
            suiteLabel += `Floor ${space?.floor}`;
            return;
          }
        });
      return suiteLabel;

    case "total_size":
      if (!detail?.spaces?.length || !detail?.spaces?.[0]?.total_rsf) return "";
      return `${getDecimalFormating(detail?.spaces?.[0]?.total_rsf) || "0.00"} RSF`;

    case "rent":
      if (!detail?.rent?.rent || Number(detail?.rent?.rent) === 0) return "";
      return `$${getDecimalFormating(detail?.rent?.rent, true) || "0.00"}/RSF/Year`;

    case "rent_type":
      if (!detail?.rent?.rent_type) return "";
      return meta?.leasecloseoutrentcalculator?.rent_type?.[
        detail?.rent?.rent_type
      ]?.value;

    case "free_rent":
      if (!detail?.rent?.free_rent) return "";
      return `${detail?.rent?.free_rent} months`;

    case "escalation":
      if (!detail?.rent?.escalation) return "";
      return `${Number(detail?.rent?.escalation_type) === 1 ? "" : "$"}${getDecimalFormating(detail?.rent?.escalation, true)}${Number(detail?.rent?.escalation_type) !== 1 ? "" : "%"}`;

    case "operating_expenses":
      if (
        !detail?.rent?.operating_expenses ||
        Number(detail?.rent?.operating_expenses) === 0
      )
        return "";
      return `$${getDecimalFormating(detail?.rent?.operating_expenses, true) || "0.00"}/RSF/Year`;
    case "opex_year":
      if (!detail?.rent?.opex_year || Number(detail?.rent?.opex_year) === 0)
        return "";
      return `${detail?.rent?.opex_year}`;

    case "execution_date":
    case "expiration_date":
    case "commencement_date":
      if (!detail?.[valueKey]) return "";
      return getFormatedDate(detail?.[valueKey]);

    case "term":
      if (!detail?.rent?.term) return;
      return `${detail?.rent?.term || ""} months`;

    default:
      if (detail?.hasOwnProperty(valueKey)) {
        return detail?.[valueKey] || "";
      }
      return "";
  }
};
