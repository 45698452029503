import queryString from "query-string";
import axios from "../axiosConfig";
import { APIProps } from "types";
import { API_PREFIX_URL, AUTH_API_PREFIX_URL } from "constant";

export const getUsersAPI = async (props: APIProps) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/user/?${param_str}`);
  return res;
};

export const getUserAPI = async (pk: string | number) => {
  const res = await axios.get(`${API_PREFIX_URL}/user/${pk}/`);
  return res;
};

export const postUserGroupAPI = async (data: APIProps) => {
  const res = await axios.post(`${API_PREFIX_URL}/user/group/`, data);
  return res;
};

export const deleteUserGroupAPI = async (props: any) => {
  const res = await axios.delete(
    `${API_PREFIX_URL}/user/group/${props.user}/?group=${props.group}&user=${props.user}`,
  );
  return res;
};

export const switchUserAPI = async (payload: any) => {
  const res = await axios.post(`${API_PREFIX_URL}/switch/`, payload);
  return res;
};

export const logoutApi = async () => {
  const res = await axios.get(`${AUTH_API_PREFIX_URL}/auth/logout/`);
  return res;
};

export const getBrokersAPI = async (props: APIProps) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/brokers/?${param_str}`);
  return res;
};

export const postBrokerAPI = async (data: APIProps) => {
  const res = await axios.post(`${API_PREFIX_URL}/coordinator/broker/`, data);
  return res;
};

export const deleteBrokerAPI = async (id: any) => {
  const res = await axios.delete(`${API_PREFIX_URL}/coordinator/broker/${id}/`);
  return res;
};
