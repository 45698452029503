import queryString from "query-string";
import axios from "../axiosConfig";
import originalAxios from "axios";
import { APIProps } from "types";
import { API_PREFIX_URL } from "constant";

export const getAbstractionAPI = async (id: number | string) => {
  const res = await axios.get(
    `${API_PREFIX_URL}/lease-abstraction/${id}/?simplified=true`,
  );
  return res;
};

export const getSourceAPI = async (id: number | string) => {
  const res = await axios.get(`${API_PREFIX_URL}/closeout/source/${id}/`);
  return res;
};

export const postTriggerAbstractionAPI = async (data: any) => {
  const res = await axios.post(
    `${API_PREFIX_URL}/trigger/ai-abstraction/`,
    data,
  );
  return res;
};

export const getTriggerAbstractionAPI = async (id: string | number) => {
  const res = await axios.get(
    `${API_PREFIX_URL}/trigger/ai-abstraction/${id}/`,
  );
  return res;
};

export const getTriggerDataAPI = async (url: string) => {
  const res = await originalAxios.get(url);
  return res;
};
