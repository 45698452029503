import { Close, InfoOutlined } from "@mui/icons-material";
import { SET_NO_DATA_MODAL } from "constant";
import { GlobalContext } from "context";
import { useContext } from "react";
import { Button } from "ui-atoms";
import { Modal } from "ui-molecules";

const NoDataModal = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { noDataModalOpen, noDataModalContent } = state;

  const handleClose = () => {
    dispatch({
      type: SET_NO_DATA_MODAL,
      payload: {
        open: false,
        content: null,
      },
    });
  };

  return (
    <Modal isOpen={noDataModalOpen} setIsOpen={handleClose} size="normal">
      <Modal.Body>
        <Button
          variant="neutral"
          onClick={handleClose}
          leadingIcon={Close}
          leadingIconClass="text-jll-text-base-default"
          size="large"
          className="absolute top-4 right-4"
        />
        <div className="w-full h-full flex flex-row space-x-4 mb-6 mt-2">
          <div className="rounded-full p-[14px] bg-jll-surface-base-secondary h-full">
            <InfoOutlined className="text-jll-text-base-subdued !w-[30px] !h-[30px]" />
          </div>
          <div className="flex flex-col space-y-1">
            <p className="text-jll-text-base-default text-lg">
              {!!noDataModalContent?.label
                ? `${noDataModalContent?.label}`
                : ""}
            </p>
          </div>
        </div>
        <div className="w-full flex flex-row justify-end">
          <Button
            variant="primary"
            size="medium"
            className="w-[104px]"
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NoDataModal;
