import { DatePicker, Input } from "ui-atoms";
import {
  getApiDate,
  getDecimalFormating,
  getFormatedDate,
  getUTCDate,
} from "utils";
import cn from "classnames";

interface IEditableField {
  children?: any;
  isCustom?: boolean;
  value?: any;
  type?: "number" | "text" | "email" | undefined;
  kind?: "default" | "date" | "months";
  leadingCurrency?: string;
  className?: string;
  rowData?: any;
  setRowData?: any;
  column?: any;
  setColumn?: any;
  rowIndex: number;
  colIndex: number;
}

const Valid = (input: string | undefined) => {
  try {
    if (!input) return undefined;
    const pattern = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
    if (pattern.test(input)) return input;
    else return undefined;
  } catch (err) {
    return undefined;
  }
};

const EditableField: React.FC<IEditableField> = ({
  isCustom,
  children,
  value,
  type = "text",
  kind,
  leadingCurrency = undefined,
  className,
  rowData,
  setRowData,
  column,
  setColumn,
  rowIndex,
  colIndex,
}) => {
  return (
    <>
      {isCustom ? (
        <>
          {(() => {
            switch (kind) {
              case "date":
                const startDate =
                  rowData?.[rowIndex]?.[colIndex]?.split("-")?.[0] || undefined;
                const endDate =
                  rowData?.[rowIndex]?.[colIndex]?.split("-")?.[1] || undefined;
                return (
                  <DatePicker
                    onChange={(dates: any) => {
                      const updatedRowData = [...rowData];
                      if (!updatedRowData[rowIndex]) {
                        updatedRowData[rowIndex] = ["", "", "", "", ""];
                      }
                      updatedRowData[rowIndex][colIndex] =
                        `${getFormatedDate(dates?.[0]?.toString() || undefined)}-${getFormatedDate(dates?.[1]?.toString() || undefined)}`;
                      setRowData(updatedRowData);
                    }}
                    selectsRange={true}
                    startDate={
                      !!startDate && Valid(startDate)
                        ? getUTCDate(startDate)
                        : undefined
                    }
                    endDate={
                      !!endDate && Valid(endDate)
                        ? getUTCDate(endDate)
                        : undefined
                    }
                    placeholderText="MM/DD/YYYY - MM/DD/YYYY"
                    className="!mb-0 field-data-picker"
                    pickerClassName="!py-px px-2.5 min-w-[220px]"
                    isClearable={true}
                  />
                );

              case "months":
                return (
                  <div className="flex flex-row space-x-2">
                    <Input
                      name={""}
                      value={value?.[0]}
                      type={type}
                      className="!mb-0 w-12"
                      inputClassName={cn("!py-px px-2.5", {
                        "!pl-6": !!leadingCurrency,
                        "!pr-1": type === "number",
                      })}
                      leadingCurrency={leadingCurrency}
                    />
                    <div className="">-</div>
                    <Input
                      name={""}
                      value={value?.[1]}
                      type={type}
                      className="!mb-0 w-12"
                      inputClassName={cn("!py-px px-2.5", {
                        "!pl-6": !!leadingCurrency,
                        "!pr-1": type === "number",
                      })}
                      leadingCurrency={leadingCurrency}
                    />
                  </div>
                );

              default:
                return (
                  <Input
                    name={""}
                    value={
                      type === "number"
                        ? value?.toString()?.replace(",", "")
                        : value
                    }
                    type={type}
                    className="!mb-0"
                    inputClassName={cn("w-full !py-px px-2.5", {
                      "!pl-6": !!leadingCurrency,
                      "!pr-1": type === "number",
                    })}
                    leadingCurrency={leadingCurrency}
                    onChange={(e: any) => {
                      let value = e?.target?.value;
                      if (rowIndex < 0) {
                        const updatedData = [...column];
                        updatedData[colIndex] = value;
                        setColumn(updatedData);
                      } else {
                        const updatedRowData = [...rowData];
                        if (!updatedRowData[rowIndex]) {
                          updatedRowData[rowIndex] = ["", "", "", "", ""];
                        }
                        updatedRowData[rowIndex][colIndex] = value;
                        setRowData(updatedRowData);
                      }
                    }}
                  />
                );
            }
          })()}
        </>
      ) : (
        <div>{children}</div>
      )}
    </>
  );
};

export default EditableField;
