import { GlobalContext } from "context";
import { useContext } from "react";
import { renderToString } from "react-dom/server";

export interface StyledAdditionalInfoValueProps {
  info: any;
  valueKey: string;
  index?: number;
}

const StyledAdditionalInfoValue: React.FC<StyledAdditionalInfoValueProps> = ({
  info,
  valueKey,
  index,
}) => {
  const { state } = useContext(GlobalContext);
  const { showTooltip, hideTooltip } = state;

  return (
    <>
      {(() => {
        switch (valueKey) {
          case "description":
            if (info?.hasOwnProperty(valueKey)) {
              return (
                <p
                  className="whitespace-pre-wrap line-clamp-3"
                  id={`info_description_${index}`}
                  onMouseOver={() => showTooltip(`info_description_${index}`)}
                  onMouseLeave={() => {
                    setTimeout(() => {
                      hideTooltip();
                    }, 50);
                  }}
                  data-tooltip-html={renderToString(
                    <p className="whitespace-pre-wrap md:max-w-[500px] sm:max-w-8/12">
                      {info?.[valueKey] || ""}
                    </p>,
                  )}
                >
                  {info?.[valueKey] || ""}
                </p>
              );
            }
            return <></>;

          default:
            if (info?.hasOwnProperty(valueKey)) {
              return <>{info?.[valueKey] || ""}</>;
            }
            return <></>;
        }
      })()}
    </>
  );
};

export default StyledAdditionalInfoValue;
