import { CalendarMonthOutlined } from "@mui/icons-material";
import { differenceInDays, formatDuration, intervalToDuration } from "date-fns";
import { useState } from "react";
import { Button, DatePicker } from "ui-atoms";
import { getFormatedDate, getUTCDate } from "utils";

const DurationCalc = () => {
  const [startDate, setStateDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [results, setResults] = useState<any>([]);

  const handleCalc = () => {
    if (!startDate || !endDate) return;
    const duration = intervalToDuration({
      end: new Date(endDate),
      start: new Date(startDate),
    });
    let list = [];
    list.push(
      formatDuration(duration, {
        format: ["years", "months", "days"],
        zero: true,
      }),
    );
    list.push(
      `${(duration.years || 0) * 12 + (duration.months || 0)} months ${duration.days || 0} days`,
    );
    const days = differenceInDays(endDate, startDate);
    list.push(`${days} days`);
    setResults(list);
  };

  return (
    <section className="mt-6">
      <div className="flex flex-row items-center space-x-2.5">
        <DatePicker
          className="w-full !mb-6"
          trailingIcon={CalendarMonthOutlined}
          selected={startDate ? getUTCDate(startDate) : null}
          value={
            startDate
              ? getFormatedDate(getUTCDate(startDate).toString())
              : undefined
          }
          onChange={(d: any) => setStateDate(d)}
          label="Start Date"
          placeholderText="MM/DD/YYYY"
        />
        <div className="border border-jll-stroke-default w-2.5 h-px -mb-3" />
        <DatePicker
          className="w-full !mb-6"
          trailingIcon={CalendarMonthOutlined}
          selected={endDate ? getUTCDate(endDate) : null}
          value={
            endDate
              ? getFormatedDate(getUTCDate(endDate).toString())
              : undefined
          }
          onChange={(d: any) => setEndDate(d)}
          label="End Date"
          placeholderText="MM/DD/YYYY"
          startDate={startDate}
        />
      </div>
      <div className="flex flex-row justify-end mb-6">
        <Button
          variant="secondary"
          onClick={handleCalc}
          disabled={!startDate || !endDate}
        >
          Calculate
        </Button>
      </div>
      <div className="rounded bg-jll-color-text-interaction flex flex-row justify-between items-center bg-opacity-5 py-4 px-[26px]">
        <span className="text-jll-text-base-subdued text-sm">RESULT</span>
        <div className="text-right flex flex-col">
          {!results?.length ? (
            <span className="text-jll-surface-base-reversed">-</span>
          ) : (
            <>
              <span className="text-jll-surface-base-reversed leading-8">
                {results?.[0] || "years   months   days"}
              </span>
              <span className="text-jll-surface-base-reversed leading-8">
                {`or ${results?.[1] || "   months   days"}`}
              </span>
              <span className="text-jll-surface-base-reversed leading-8">
                {`or ${results?.[2] || "   days"}`}
              </span>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default DurationCalc;
