import { SET_CONFIRM_MODAL } from "constant";
import { GlobalContext } from "context";
import { useContext } from "react";
import { Button } from "ui-atoms";
import { Modal } from "ui-molecules";
import ReactHtmlParser from "react-html-parser";
import { Close } from "@mui/icons-material";

const ConfirmModal = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { confirmModalOpen, confirmModalContent } = state;

  const handleCloseClick = () => {
    dispatch({
      type: SET_CONFIRM_MODAL,
      payload: { open: false },
    });
  };

  const handleClose = () => {
    confirmModalContent?.additionalCancel &&
      confirmModalContent?.additionalCancel();
    handleCloseClick();
  };

  const handleCancel = () => {
    confirmModalContent?.cancel && confirmModalContent?.cancel();
    handleCloseClick();
  };

  const actionFunc = () => {
    confirmModalContent?.action && confirmModalContent?.action();
    handleCloseClick();
  };

  const action2Func = () => {
    confirmModalContent?.action2 && confirmModalContent?.action2();
    handleCloseClick();
  };

  return (
    <Modal isOpen={confirmModalOpen} setIsOpen={handleClose} size="medium">
      <Modal.Header>
        <div className="flex flex-row items-center justify-between">
          <h3 className="text-xl leading-6 text-jll-text-base-default">
            {confirmModalContent?.title || "Warning"}
          </h3>
          <Button
            variant="neutral"
            onClick={handleClose}
            leadingIcon={Close}
            size="large"
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="mt-6">
          {confirmModalContent?.description &&
            ReactHtmlParser(confirmModalContent?.description)}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex flex-row space-x-3 w-full justify-end">
          <Button variant="secondary" onClick={handleCancel}>
            {confirmModalContent?.cancelTitle || "Cancel"}
          </Button>
          <div className="flex flex-row items-center space-x-2">
            {confirmModalContent?.action && (
              <Button variant="primary" onClick={actionFunc}>
                {confirmModalContent?.btnTitle || "Confirm"}
              </Button>
            )}
            {confirmModalContent?.action2 && (
              <Button variant="primary" onClick={action2Func}>
                {confirmModalContent?.btnTitle2 || "Confirm"}
              </Button>
            )}
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
