import { format } from "date-fns";

// /**
//  * Get full formated date
//  * Build with Intl. For more information about Intl please go to
//  * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
//  * @param value Ex: 2023-02-18T21:52:16.381000Z
//  * @param options Ex: { dateStyle: "long" }
//  * @returns Ex: March 20, 2023 at 2:26 PM
//  */
// export const getFormatedDateTime = (value?: string, options?: any) => {
//   const defaultOption: any = {
//     dateStyle: "long",
//     timeStyle: "short",
//   };
//   try {
//     const mydate = value ? getUTCDate(value) : getUTCDate();
//     return new Intl.DateTimeFormat("en-US", options || defaultOption).format(
//       mydate
//     );
//   } catch (err) {
//     return "";
//   }
// };

export const isValidDate = (dateString: string) => {
  return !isNaN(Date.parse(dateString));
};

/**
 * Get formated date
 * @param value Ex: 2023-02-18T21:52:16.381000Z
 * @returns Ex: 2/18/2023
 */
export const getFormatedDate = (value: string = "") => {
  if (!value?.length) return "";
  if (!isValidDate(value)) return value;
  try {
    const mydate = getUTCDate(value);
    if (!!mydate && typeof mydate !== "string")
      return format(mydate, "MM/dd/yyyy");
    else return "";
  } catch (err) {
    return "";
  }
};

/**
 * Get formated date
 * @param value Ex: 2023-02-18T21:52:16.381000Z
 * @returns Ex: February 18, 2023
 */
export const getFormatedDateWithMonth = (value: string = "") => {
  if (!value?.length) return "";
  if (!isValidDate(value)) return value;
  try {
    const mydate = getUTCDate(value);
    if (!!mydate && typeof mydate !== "string")
      return format(mydate, "MMMM dd, yyyy");
    else return "";
  } catch (err) {
    return "";
  }
};

/**
 * Get formated date
 * @param value Ex: 2023-02-18T21:52:16.381000Z
 * @returns Ex: 2/18/2023 10:10 am
 */
export const getFormatedDateTime = (value: string = "") => {
  if (!value?.length) return "";
  try {
    const mydate = getUTCDate(value);
    if (!!mydate && typeof mydate !== "string")
      return format(mydate, "MM/dd/yyyy hh:mma");
    else return "";
  } catch (err) {
    return "";
  }
};

export const getUTCDate = (value: string | null = "") => {
  try {
    let date = new Date()?.toLocaleString("en-US", { timeZone: "UTC" });
    if (value)
      date = new Date(value)?.toLocaleString("en-US", { timeZone: "UTC" });
    const result = new Date(date);
    return result;
  } catch (err) {
    return "";
  }
};

/**
 * Get formated date for api
 * @param value Ex: 2023-02-18T21:52:16.381000Z
 * @returns Ex: YYYY-MM-DD
 */
export const getApiDate = (value: Date | string | undefined) => {
  if (!value) return null;
  try {
    const result = format(
      new Date(new Date(value)?.toLocaleString("en-US", { timeZone: "UTC" })),
      "yyyy-MM-dd",
    );
    return result;
  } catch (err) {
    return null;
  }
};
