import {
  Close,
  DeleteOutlineOutlined,
  FileUploadOutlined,
} from "@mui/icons-material";
import { SET_ERROR_MODAL, SET_FULL_LOADING } from "constant";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import { useContext, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useParams } from "react-router-dom";
import { postImageAPI, postSpaceAPI, putSpaceAPI } from "services";
import { Button, Dropzone, Input, Label } from "ui-atoms";
import { Modal } from "ui-molecules";
import { checkFileType } from "utils";
import * as Yup from "yup";

interface ISpaceModal {
  isOpen: boolean;
  setIsOpen: any;
  content?: any;
  data?: any;
  setData?: any;
  setTotalSize?: any;
}

const INIT_SPACE = {
  floor: "",
  name: "",
  rsf: "",
};

const validationSchema = Yup.object().shape({
  floor: Yup.string()
    .nullable()
    .test("floor-required", "Floor or Suite is required.", function (value) {
      const { name } = this.parent;
      return name || value ? true : false;
    }),
  name: Yup.string()
    .nullable()
    .test("name-required", "Suite or Floor is required.", function (value) {
      const { floor } = this.parent;
      return floor || value ? true : false;
    }),
  rsf: Yup.number().required("This field is required"),
});

const SpaceModal: React.FC<ISpaceModal> = ({
  isOpen,
  setIsOpen,
  content,
  data,
  setData,
  setTotalSize,
}) => {
  const { dispatch, state } = useContext(GlobalContext);
  const { errorModalOpen } = state;
  const [postSpace] = useApiCall(postSpaceAPI);
  const [putSpace] = useApiCall(putSpaceAPI);
  const { closeoutId } = useParams();
  const [files, setFiles] = useState([]);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
    setValues,
    isValid,
    dirty,
    setTouched,
  } = useFormik({
    initialValues: INIT_SPACE,
    validationSchema,
    onSubmit: async () => {
      try {
        dispatch({
          type: SET_FULL_LOADING,
          payload: {
            open: true,
            label: "Updating Space...",
          },
        });

        let results: any[] = [];
        if (files?.length > 0) {
          await Promise.all(
            files?.map(async (file: any) => {
              if (typeof file === "string") {
                results.push(file);
                return;
              }
              const result = await postImageAPI(file);
              if (result) {
                results.push(result);
              }
            }),
          );
        }

        if (content) {
          putSpace({
            ...values,
            lease_closeout: closeoutId,
            floorplan: results,
            pk: content?.pk,
          })
            .then((res: any) => {
              if (!res) return;
              let newData = [...data];
              let index = newData?.findIndex(
                (item: any) => item?.pk === res?.pk || item?.pk === res?.id,
              );
              newData[index] = {
                ...res,
                pk: res?.id,
              };
              setData(newData);
              setTotalSize(res?.total_rsf);
            })
            .finally(() => {
              setIsOpen(false);
              dispatch({
                type: SET_FULL_LOADING,
                payload: {
                  open: false,
                  label: "Updating Space...",
                },
              });
            });
        } else
          postSpace({
            ...values,
            lease_closeout: closeoutId,
            floorplan: results,
          })
            .then((res: any) => {
              if (!res) return;
              let newData = [...data];
              newData.push({
                ...res,
                pk: res?.id,
              });
              setData(newData);
              setTotalSize(res?.total_rsf);
            })
            .finally(() => {
              setIsOpen(false);
              dispatch({
                type: SET_FULL_LOADING,
                payload: {
                  open: false,
                  label: "Updating Space...",
                },
              });
            });
      } catch (err) {
        dispatch({
          type: SET_FULL_LOADING,
          payload: {
            open: false,
            label: "Updating Space...",
          },
        });
      }
    },
  });

  useEffect(() => {
    if (!isOpen) return;
    setTouched({}, false);
    if (!content) {
      setValues(INIT_SPACE);
      setFiles([]);
      return;
    }
    let formValues: any = {};
    Object.keys(INIT_SPACE).forEach((key: string) => {
      formValues = {
        ...formValues,
        [key]: content?.[key] || "",
      };
    });
    setValues(formValues);
    setFiles(content?.floorplan || []);
  }, [isOpen, content]);

  const handleClose = () => {
    if (errorModalOpen) return;
    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={handleClose} size="default">
      <Modal.Header>
        <div className="flex flex-row items-center justify-between">
          <h3 className="text-xl leading-6 text-jll-text-base-default">
            Space
          </h3>
          <Button
            variant="neutral"
            onClick={() => setIsOpen(false)}
            leadingIcon={Close}
            size="large"
          />
        </div>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="mt-6">
            <div className="flex flex-row space-x-6">
              <Input
                type="number"
                name="floor"
                label="Floor"
                className="w-full !mb-6"
                onChange={handleChange}
                error={touched.floor ? errors.floor : ""}
                onBlur={handleBlur}
                value={values?.floor}
              />
              <Input
                name="name"
                label="Suite"
                className="w-full !mb-6"
                onChange={handleChange}
                error={touched.name ? errors.name : ""}
                onBlur={handleBlur}
                value={values?.name}
              />
            </div>
            <Input
              name="rsf"
              label="Size (RSF)"
              onChange={(e: any) => {
                const inputValue = e?.target?.value?.replaceAll(",", "");
                const regex = /^[0-9\b]+$/;
                if (regex.test(inputValue) || inputValue === "") {
                  setFieldValue("rsf", Number(inputValue));
                }
              }}
              isComma
              error={touched.rsf ? errors.rsf : ""}
              onBlur={handleBlur}
              value={values?.rsf}
              className="!mb-6"
            />

            <div>
              <div className="mb-2 flex flex-row justify-between items-center">
                <Label>Floor plan</Label>
                {!!files?.length && (
                  <div className="flex flex-row items-center">
                    <Dropzone
                      divClassName="!border-none"
                      setDropFiles={async (files: any) => {
                        const fileType = await checkFileType(files[0]);

                        if (
                          !fileType?.includes("image/jpeg") &&
                          !fileType?.includes("image/png")
                        ) {
                          dispatch({
                            type: SET_ERROR_MODAL,
                            payload: {
                              open: true,
                              content: {
                                label: "Warning",
                                value:
                                  "File not accepted. Only PNG and JPEG files can be rendered in the deliverable. Please upload a PNG or JPEG. ",
                              },
                            },
                          });
                          return;
                        }
                        setFiles(files);
                      }}
                      acceptType={{ "image/*": [".png", ".jpg", ".jpeg"] }}
                      maxFileCount={1}
                    >
                      <button className="p-1" type="button">
                        <FileUploadOutlined className="!w-5 !h-5 text-jll-text-base-subdued" />
                      </button>
                    </Dropzone>
                    <button
                      className="p-1"
                      type="button"
                      onClick={() => setFiles([])}
                    >
                      <DeleteOutlineOutlined className="!w-5 !h-5 text-jll-text-base-subdued" />
                    </button>
                  </div>
                )}
              </div>
              {!files?.length && (
                <Dropzone
                  className="bg-jll-surface-base-secondary-subdued h-[150px]"
                  setDropFiles={async (files: any) => {
                    const fileType = await checkFileType(files[0]);

                    if (
                      !fileType?.includes("image/jpeg") &&
                      !fileType?.includes("image/png")
                    ) {
                      dispatch({
                        type: SET_ERROR_MODAL,
                        payload: {
                          open: true,
                          content: {
                            label: "Warning",
                            value:
                              "File not accepted. Only PNG and JPEG files can be rendered in the deliverable. Please upload a PNG or JPEG. ",
                          },
                        },
                      });
                      return;
                    }
                    setFiles(files);
                  }}
                  acceptType={{ "image/*": [".png", ".jpg", ".jpeg"] }}
                  maxFileCount={1}
                >
                  <div className="flex flex-col items-center justify-center">
                    <FileUploadOutlined className="!w-8 !h-8 mb-2 text-jll-color-text-interaction" />
                    <p className="text-sm text-jll-text-base-subdued">
                      Drag a floor plan image here, or{" "}
                    </p>
                    <p className="text-sm text-jll-text-base-subdued">
                      click to browse
                    </p>
                  </div>
                </Dropzone>
              )}
              {!!files?.length && (
                <div className="bg-jll-surface-base-secondary-subdued h-[150px] w-full flex flex-row justify-center items-center py-2">
                  <LazyLoadImage
                    src={
                      typeof files?.[0] === "string"
                        ? files?.[0]
                        : URL.createObjectURL(files?.[0])
                    }
                    className="h-full object-contain"
                  />
                </div>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="flex flex-row space-x-3 justify-end w-full">
            <Button
              variant="secondary"
              className="w-32"
              size="medium"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="w-32"
              type="submit"
              size="medium"
              disabled={!isValid || !dirty}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default SpaceModal;
