import { useApiCall } from "hooks";
import { LandingPage } from "pages";
import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { getSessionAPI } from "services";
import { FullStory } from "@fullstory/browser";

export const RequiredAuth: React.FC = () => {
  const location = useLocation();
  const [getSession, , sessionInfo, error] = useApiCall(getSessionAPI);

  useEffect(() => {
    getSession();
  }, [location]);

  useEffect(() => {
    if (sessionInfo) {
      if (sessionInfo?.mock_user) {
        sessionStorage.setItem("mainSession", JSON.stringify(sessionInfo));
        sessionStorage.setItem(
          "session",
          JSON.stringify(sessionInfo?.mock_user),
        );
      } else {
        sessionStorage.setItem("session", JSON.stringify(sessionInfo));
        sessionStorage.removeItem("mainSession");
      }
      FullStory("setIdentity", {
        uid: sessionInfo?.id,
        properties: {
          displayName:
            sessionInfo?.get_full_name || sessionInfo?.usermeta?.name || "",
          email: sessionInfo?.email || sessionInfo?.usermeta?.email || "",
          reviewsWritten: 14,
        },
      });
    } else {
      sessionStorage.removeItem("session");
    }
  }, [sessionInfo]);

  if (!error && sessionInfo) {
    return (
      <>
        <Outlet />
      </>
    );
  }

  return <>{error && <LandingPage />}</>;
};
