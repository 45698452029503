import { SET_FULL_LOADING } from "constant";
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { EditableField, Table } from "ui-molecules";
import { overrideObject } from "utils";
import cn from "classnames";
import { GlobalContext } from "context";
import { putRentAPI } from "services";
import { useApiCall } from "hooks";
import { useParams } from "react-router-dom";

interface ICustom {
  data?: any;
  isEditing?: boolean;
  setIsEditing?: any;
  setData?: any;
}

const Custom = forwardRef(
  ({ data, isEditing, setIsEditing, setData }: ICustom, ref) => {
    const { dispatch } = useContext(GlobalContext);
    const { closeoutId } = useParams();
    const [putRent] = useApiCall(putRentAPI);
    const [rowData, setRowData] = useState<any>([]);
    const [originData, setOriginData] = useState<any>({ cols: [], rows: [] });
    const [columns, setColumns] = useState(["", "", "", "", ""]);

    useEffect(() => {
      if (!data) {
        setRowData([]);
        return;
      }
      if (!!data?.custom) {
        const { cols, rows } = overrideObject(data?.custom);
        setColumns(cols);
        setRowData(rows);
        setOriginData({ rows, cols });
      }
    }, [data]);

    useImperativeHandle(ref, () => ({
      onSave() {
        handleSave();
      },
      onCancel() {
        handleCancel();
      },
      onClear() {
        handleClear();
      },
    }));

    const handleSave = () => {
      try {
        dispatch({
          type: SET_FULL_LOADING,
          payload: {
            open: true,
            label: "Updating",
          },
        });
        let overrides: any = {};
        [...Array(5)]?.forEach((_, idx) => {
          if (!overrides[idx]) overrides[idx] = {};
          overrides[idx][0] = columns[idx];
        });
        const filteredRows = rowData?.filter((row: any) => {
          let flag = false;
          row?.forEach((item: any) => {
            if (!!item?.length && item !== "-") {
              flag = true;
              return;
            }
          });
          if (flag) return row;
        });
        filteredRows?.forEach((row: any, rowIdx: number) => {
          row?.forEach((val: any, colIdx: number) => {
            overrides[colIdx][rowIdx + 1] = val;
          });
        });

        let payload = {
          ...data,
          use_custom: true,
          custom: overrides,
        };

        putRent({ id: closeoutId, payload })
          .then((res: any) => {
            if (!res) {
              setData([]);
              return;
            }
            setData(res);
          })
          .finally(() => {
            dispatch({
              type: SET_FULL_LOADING,
              payload: {
                open: false,
                label: "",
              },
            });
            setIsEditing(false);
          });
      } catch (err) {
        dispatch({
          type: SET_FULL_LOADING,
          payload: {
            open: false,
            label: "",
          },
        });
      }
    };

    const handleCancel = () => {
      setRowData(originData?.rows);
      setColumns(originData?.cols);
      setIsEditing(false);
    };

    const handleClear = () => {
      setRowData([]);
      setColumns(["", "", "", "", ""]);
    };

    return (
      <>
        {!isEditing && !rowData?.length ? (
          <Table.Loading size={6} />
        ) : (
          <div className="h-full -mx-14">
            <Table>
              <Table.Thead>
                <Table.Tr>
                  {columns.map((column: any, idx: number) => (
                    <Table.Th
                      key={idx}
                      itemClassName={cn("w-full flex flex-wrap", {
                        "!pl-14": idx === 0,
                        "!pr-14": idx === columns?.length - 1,
                      })}
                    >
                      <EditableField
                        isCustom={isEditing}
                        value={column?.label || column}
                        rowIndex={-1}
                        colIndex={idx}
                        column={columns}
                        setColumn={setColumns}
                      >
                        {column}
                      </EditableField>
                    </Table.Th>
                  ))}
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {(!isEditing
                  ? [...rowData]
                  : [...rowData, ...Array(1)?.fill(["", "", "", "", ""])]
                )?.map((override: any, idx: number) => (
                  <Table.Tr key={idx}>
                    {override?.map((item: any, idx1: number) => (
                      <Table.Td
                        key={idx1}
                        className={cn({
                          "!pl-14": idx1 === 0,
                          "!pr-14": idx1 === override?.length - 1,
                        })}
                      >
                        <EditableField
                          isCustom={isEditing}
                          value={item}
                          rowIndex={idx}
                          colIndex={idx1}
                          rowData={rowData}
                          setRowData={setRowData}
                        >
                          {item}
                        </EditableField>
                      </Table.Td>
                    ))}
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </div>
        )}
      </>
    );
  },
);

export default Custom;
