import { StyledKeyDateValueProps } from "ui-molecules";

export const KEY_DATE_COLUMNS: {
  id: StyledKeyDateValueProps["valueKey"];
  label: string;
}[] = [
  { label: "Event", id: "type" },
  { label: "Date", id: "date" },
  { label: "Description", id: "description" },
];

export const INIT_KEY_DATA = [
  { label: "Execution", id: "execution_date" },
  { label: "Commencement", id: "commencement_date" },
  { label: "Expiration", id: "expiration_date" },
];
