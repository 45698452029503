import {
  EditOutlined,
  FilterAltOutlined,
  PictureAsPdfOutlined,
} from "@mui/icons-material";
import { Loading, StyledListValue, Table } from "ui-molecules";
import { LayoutHome } from "ui-organisms";
import InsightsImg from "assets/images/insights.svg";
import { useApiCall } from "hooks";
import { getListAPI } from "services";
import { useContext, useEffect, useRef, useState } from "react";
import {
  LIST_TABLE_COLUMNS,
  LIST_TABLE_COLUMNS_FOR_ADMIN,
  SEARCH_RESULT_LIMIT,
  SET_LEASE_PDF,
  SET_LEASE_PDF_CONTENT,
} from "constant";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { DirectionEnum, ListProps } from "types";
import { Button } from "ui-atoms";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import AdminFilter from "./AdminFilter";
import { GlobalContext } from "context";

const Homepage = () => {
  const { dispatch } = useContext(GlobalContext);
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const tableRef = useRef<any>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [getList, isLoading] = useApiCall(getListAPI);
  const [data, setData] = useState<ListProps[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [facets, setFacets] = useState<any>();
  const [sortFields, setSortFields] = useState<string[]>([]);
  const [paramFilters, setParamFilters] = useState<any>(null);
  const [isSlidePane, setIsSlidePane] = useState(false);
  const isAdmin =
    session?.is_superuser ||
    session?.is_staff ||
    !!session?.groups?.find((group: any) => group?.id === 1);
  const columns = isAdmin ? LIST_TABLE_COLUMNS_FOR_ADMIN : LIST_TABLE_COLUMNS;

  useEffect(() => {
    dispatch({
      type: SET_LEASE_PDF_CONTENT,
      payload: null,
    });
    dispatch({
      type: SET_LEASE_PDF,
      payload: 0,
    });
  }, []);

  useEffect(() => {
    const filters = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    setParamFilters(filters);
  }, [location]);

  useEffect(() => {
    setPage(1);
    if (!paramFilters) return;
    if (tableRef?.current) {
      tableRef.current.scrollTop = 0;
    }
    getList({
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      ...paramFilters,
    })
      .then((res: any) => {
        if (!res) return;
        setData(res?.docs);
        setPage(res?.page);
        setTotal(res?.total);
        setFacets(res?.facets);
        setSortFields(res?.sort_fields);
      })
      .catch(() => {
        setData([]);
        setTotal(0);
      });
  }, [paramFilters]);

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    getList({
      page: nextPage,
      limit: SEARCH_RESULT_LIMIT,
      ...paramFilters,
    }).then((res: any) => {
      if (!res) return;
      setData((prevData: ListProps[]) => [...prevData, ...res?.docs]);
      setPage(res?.page);
      setTotal(res?.total);
      setFacets(res?.facets);
      setSortFields(res?.sort_fields);
    });
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: total > data?.length,
    onLoadMore: loadMore,
  });

  const onClickSorting = (sort: string) => {
    const updatedFilters = {
      ...paramFilters,
      sort,
      direction:
        paramFilters?.direction === DirectionEnum.asc
          ? DirectionEnum?.desc
          : DirectionEnum.asc,
    };
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`,
    );
  };

  const handleFilter = (options: any, key: string) => {
    const filter = (options || []).map((option: any) => option?.value);
    const updatedFilters = { ...paramFilters };
    if (filter?.length) updatedFilters[key] = filter;
    else delete updatedFilters[key];

    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`,
    );
  };

  return (
    <>
      <LayoutHome>
        {/* Header */}
        <section className="px-8 py-8 flex flex-row items-center w-full justify-between">
          <h3 className="text-xl text-jll-text-base-default">
            Welcome, {session?.get_full_name}
          </h3>

          <div className="flex flex-row space-x-3.5">
            <Button
              variant="neutral"
              leadingIcon={FilterAltOutlined}
              size="medium"
              onClick={() => setIsSlidePane(true)}
            >
              Filters
            </Button>
          </div>
        </section>

        {!isLoading && !data?.length && (
          <section className="w-auto flex flex-row justify-center items-center bg-jll-surface-base-secondary-subdued mx-8 h-[240px]">
            <div className="flex flex-col justify-center items-center text-jll-text-base-default">
              <img src={InsightsImg} className="mb-4 w-[50px] h-[50px]" />
              <p className="mb-2">
                Create and share meaningful lease insights with your clients at
                the end of every lease transaction.
              </p>
              <p>Get started by closing out a transaction in Dealio.</p>
            </div>
          </section>
        )}

        {!!data?.length && (
          <section className="h-full overflow-y-auto" ref={tableRef}>
            <Table>
              <Table.Thead>
                <Table.Tr>
                  {columns?.map((column, idx) => (
                    <Table.Th
                      key={idx}
                      itemClassName={idx === 0 ? "!pl-8" : ""}
                      filterId={column.id}
                      sortFields={sortFields}
                      filters={paramFilters}
                      onClick={onClickSorting}
                    >
                      {column.label}
                    </Table.Th>
                  ))}
                  <Table.Th itemClassName="!pr-8"></Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {data?.map((item, idx) => (
                  <Table.Tr
                    key={idx}
                    onClick={(e: any) => {
                      if (e?.metaKey || e?.ctrlKey) {
                        window.open(`/detail/${item?.pk}`);
                      } else navigate(`/detail/${item?.pk}`);
                    }}
                  >
                    {columns?.map((column, idx1) => (
                      <Table.Td
                        key={idx1}
                        className={idx1 === 0 ? "!pl-8" : ""}
                      >
                        <StyledListValue list={item} valueKey={column.id} />
                      </Table.Td>
                    ))}
                    <Table.Td className="!pr-8 space-x-1 text-right">
                      <Button
                        variant="neutral"
                        kind="icon"
                        leadingIcon={EditOutlined}
                        onClick={(e) => {
                          e?.stopPropagation();
                          navigate(`/detail/${item?.pk}`);
                        }}
                      />
                      <Button
                        variant="neutral"
                        kind="icon"
                        leadingIcon={PictureAsPdfOutlined}
                        onClick={(e) => {
                          e?.stopPropagation();
                          navigate(`/detail/${item?.pk}?preview=true`);
                        }}
                      />
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
            {!!(total > data?.length) && (
              <div
                className="w-full py-2 flex justify-center items-center"
                ref={sentryRef}
              >
                <Loading />
              </div>
            )}
          </section>
        )}
      </LayoutHome>

      <SlidingPane
        width="300px"
        from="right"
        isOpen={isSlidePane}
        onRequestClose={() => setIsSlidePane(false)}
      >
        <AdminFilter facets={facets} setIsSlidePane={setIsSlidePane} />
      </SlidingPane>
    </>
  );
};

export default Homepage;
