import { Close } from "@mui/icons-material";
import { Button } from "ui-atoms";
import { Modal } from "ui-molecules";

interface IRentConfirmModal {
  isOpen: boolean;
  setIsOpen: any;
}

const RentConfirmModal: React.FC<IRentConfirmModal> = ({
  isOpen,
  setIsOpen,
}) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="default">
      <Modal.Header>
        <div className="flex flex-row items-center justify-between">
          <h3 className="text-xl leading-6 text-jll-text-base-default">
            Confirmation
          </h3>
          <Button
            variant="neutral"
            onClick={() => setIsOpen(false)}
            leadingIcon={Close}
            size="large"
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <p className="text-jll-text-base-subdued mt-4">
          Please click cancel or save to switch to calculated rent schedule.
        </p>
      </Modal.Body>
      <Modal.Footer className="!mt-8">
        <div className="flex flex-row space-x-3 justify-end w-full">
          <Button
            variant="primary"
            className="w-32"
            onClick={() => setIsOpen(false)}
            size="medium"
          >
            OK
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default RentConfirmModal;
