import type { Orientation } from "@react-pdf/types";

export const PAGE_SIZE = "LETTER";
export const PAGE_ORIENTATION = {
  PORTRAIT: "portrait" as Orientation,
  LANDSCAPE: "landscape" as Orientation,
};

export const CSS = {
  PADDING: "20px",
  PORTRAIT: {
    WIDTH: "612px",
    HEIGHT: "792px",
  },
  LANDSCAPE: {
    WIDTH: "792px",
    HEIGHT: "612px",
  },
};

export const PDF_SIZE = {
  PORTRAIT: {
    WIDTH: 612,
    HEIGHT: 792,
  },
  LANDSCAPE: {
    WIDTH: 792,
    HEIGHT: 612,
  },
};

export const LEASE_OVERVIEW = 1;
export const RENT_EXPENSES = 2;
export const FLOORPLAN = 3;
export const KEY_DATES = 4;
export const ADDITIONAL_CONTACT = 5;
export const COVER = 6;
export const CONTENT = 7;
export const TEAM = 8;

export const DEFAULT_LEASE_OVERVIEW_FIELDS = [
  { label: "Address", id: "address", visible: true },
  { label: "Suite or Floor", id: "suite", visible: true },
  { label: "Total Size", id: "total_size", visible: true },
  { label: "Base Rent", id: "rent", visible: true },
  { label: "Rent Type", id: "rent_type", visible: true },
  { label: "Free Rent", id: "free_rent", visible: true },
  { label: "Escalation", id: "escalation", visible: true },
  { label: "Execution", id: "execution_date", visible: false },
  { label: "Operating Expenses", id: "operating_expenses", visible: true },
  { label: "OPEX Year", id: "opex_year", visible: true },
  { label: "Term", id: "term", visible: true },
  { label: "Commencement", id: "commencement_date", visible: true },
  { label: "Expiration", id: "expiration_date", visible: true },
];
