import {
  DETAIL_MENU_LIST,
  SET_DETAIL_INFO,
  SET_FULL_LOADING,
  STATUS_TO_DO,
} from "constant";
import cn from "classnames";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import queryString from "query-string";
import { EditOutlined } from "@mui/icons-material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getMetaOptions, lazyLoadImageOnError } from "utils";
import { Select } from "ui-atoms";
import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import { getDocFileAPI, patchDetailAPI } from "services";

const Sidebar = () => {
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const { state, dispatch } = useContext(GlobalContext);
  const { meta, detailInfo, showTooltip, hideTooltip } = state;
  const location = useLocation();
  const navigate = useNavigate();
  const { closeoutId } = useParams();
  const [patchDetail] = useApiCall(patchDetailAPI);
  const [getDocFile] = useApiCall(getDocFileAPI);
  const isAdmin =
    session?.is_superuser ||
    session?.is_staff ||
    !!session?.groups?.find((group: any) => group?.id === 1);
  const [activeTab, setActiveTab] = useState(
    isAdmin
      ? DETAIL_MENU_LIST[0]?.sub_menu?.[0].value
      : DETAIL_MENU_LIST[1]?.sub_menu?.[0].value,
  );
  const [status, setStatus] = useState(STATUS_TO_DO);

  useEffect(() => {
    const filters: any = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    if (filters?.tab && filters?.tab?.toString()?.toLowerCase() !== activeTab) {
      setActiveTab(filters?.tab?.toString()?.toLowerCase());
    }
  }, [location]);

  useEffect(() => {
    if (!detailInfo) return;
    setStatus(detailInfo?.status);
  }, [detailInfo]);

  const handleStatus = (value: number) => {
    try {
      setStatus(value);
      patchDetail({ id: closeoutId, payload: { status: value } }).then(
        (res: any) => {
          if (!res) return;
          dispatch({
            type: SET_DETAIL_INFO,
            payload: {
              ...detailInfo,
              status: res?.status,
            },
          });
        },
      );
    } catch (err) {}
  };

  const handleTab = (value: string) => {
    const updatedFilters: any = { tab: value };
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`,
    );
  };

  const handleDownload = async (item: any) => {
    try {
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: true,
          label: "Downloading...",
        },
      });
      const data = await getDocFile({
        id: closeoutId,
        props: { doc_id: item?.DocID },
      });
      const fileData = new Blob([data]);
      const fileUrl = URL.createObjectURL(fileData);
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = `${item?.File_Name}`;
      link.click();
      URL.revokeObjectURL(fileUrl);
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: false,
          label: null,
        },
      });
    } catch (err) {
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: false,
          label: null,
        },
      });
    }
  };

  return (
    <div className="fixed top-[80px] left-0 w-[232px] bottom-0 border-r border-r-jll-stroke-subdued bg-jll-surface-base-secondary-subdued overflow-y-auto">
      <div className="m-4 py-4 px-5 border border-jll-stroke-subdued rounded bg-white">
        <Link
          to={`${location?.pathname}?tab=client_logo`}
          className="flex flex-row space-x-2 mb-2 relative truncate"
        >
          <LazyLoadImage
            src={detailInfo?.client_logo ? detailInfo?.client_logo : ""}
            onError={lazyLoadImageOnError}
            className="w-11 h-11 rounded object-contain"
          />
          <div className="w-full truncate">
            <p
              className="text-jll-text-base-default truncate"
              title={detailInfo?.client || ""}
            >
              {detailInfo?.client || ""}
            </p>
            <p
              className="text-sm text-jll-text-base-subdued truncate"
              title={
                detailInfo?.building_name || detailInfo?.building_address || ""
              }
            >
              {detailInfo?.building_name || detailInfo?.building_address || ""}
            </p>
          </div>
          <EditOutlined className="!w-4 !h-4 text-jll-text-base-subdued absolute right-0 top-0 cursor-pointer" />
        </Link>
        <p className="text-sm text-jll-text-base-subdued mb-1">
          Dealio #{detailInfo?.dealio || ""}
        </p>
        <div className="flex flex-col mb-2">
          {!!detailInfo?.files?.length &&
            detailInfo?.files?.map((item: any, idx: number) => (
              <div className="flex w-full truncate" key={idx}>
                <a
                  type="button"
                  className="text-jll-color-text-interaction leading-5 text-sm cursor-pointer truncate"
                  onClick={() => handleDownload(detailInfo?.files[0])}
                  id={`file-name-${idx}`}
                  onMouseOver={() => showTooltip(`file-name-${idx}`)}
                  onMouseLeave={() => {
                    setTimeout(() => {
                      hideTooltip();
                    }, 50);
                  }}
                  data-tooltip-content={item?.File_Name}
                >
                  {`Executed Lease ${idx > 0 ? `(${idx + 1})` : ""}`}
                </a>
              </div>
            ))}
        </div>

        <Select.Status
          options={getMetaOptions(meta?.leasecloseout?.status)}
          selectedOption={
            status
              ? {
                  label: meta?.leasecloseout?.status?.[status]?.value,
                  value: status,
                }
              : undefined
          }
          onChange={(value: any) => {
            handleStatus(Number(value));
          }}
        />
      </div>
      {DETAIL_MENU_LIST.map((menu: any, idx) => {
        if (!isAdmin && idx === 0) return;
        const Icon = menu.icon;
        return (
          <div key={idx} className="mb-2">
            <div
              className={cn(
                "flex flex-row items-center space-x-2.5 py-3 px-8",
                {
                  "cursor-pointer hover:bg-jll-color-surface-interactionSubdued":
                    menu?.isRoot,
                  " border-r border-r-jll-color-text-interaction bg-jll-color-surface-interactionSubdued":
                    activeTab === menu?.value,
                },
              )}
              onClick={() => {
                if (!menu?.isRoot) return;
                handleTab(menu.value);
              }}
            >
              <Icon
                className={cn("!w-5 !h-5", {
                  "text-jll-text-base-subdued": activeTab !== menu?.value,
                  "text-jll-surface-interaction-default":
                    activeTab === menu?.value,
                })}
              />
              <span
                className={cn("text-sm", {
                  "text-jll-text-base-subdued": activeTab !== menu?.value,
                  "text-jll-surface-interaction-default":
                    activeTab === menu?.value,
                })}
              >
                {menu.label}
              </span>
            </div>
            {!menu?.isRoot &&
              menu?.sub_menu.map((sub: any, idx1: number) => (
                <div
                  className={cn(
                    "py-2 pl-16 pr-4 text-sm cursor-pointer hover:bg-jll-color-surface-interactionSubdued",
                    {
                      "text-jll-text-base-subdued": activeTab !== sub.value,
                      "text-jll-surface-interaction-default border-r border-r-jll-color-text-interaction bg-jll-color-surface-interactionSubdued":
                        activeTab === sub.value,
                    },
                  )}
                  key={idx1}
                  onClick={() => handleTab(sub.value)}
                >
                  {sub.label}
                </div>
              ))}
          </div>
        );
      })}
    </div>
  );
};

export default Sidebar;
