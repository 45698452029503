import { Footer, RentClearModal, RentConfirmModal } from "ui-molecules";
import {
  CalendarMonthOutlined,
  CheckOutlined,
  DisabledByDefaultOutlined,
  EditOutlined,
} from "@mui/icons-material";
import { SET_FULL_LOADING } from "constant";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getRentAPI, postRentAPI, putRentAPI } from "services";
import { Button, DatePicker, Input, Select } from "ui-atoms";
import { getApiDate, getFormatedDate, getMetaOptions, getUTCDate } from "utils";
import * as Yup from "yup";
import Custom from "./Custom";
import Standard from "./Standard";
import { renderToString } from "react-dom/server";

const INIT_RENT = {
  term: "",
  rent_type: "",
  escalation: "",
  free_rent: "",
  operating_expenses: "",
  opex_year: "",
  monthly_opex: "",
  rent: "",
  escalation_date: "",
  rent_term: "",
  opex_term: "",
  escalation_type: null,
};

const validationSchema = Yup.object().shape({
  term: Yup.number()
    .required("This field is required")
    .moreThan(0, "This field will be over than 0"),
  rent_type: Yup.number().optional(),
  rent: Yup.number()
    .required("This field is required")
    .moreThan(0, "This field will be over than 0"),
  free_rent: Yup.number().optional(),
  escalation: Yup.number().optional(),
  escalation_date: Yup.string().optional().nullable(),
  operating_expenses: Yup.number().optional(),
  opex_year: Yup.number().test(
    "opex-required",
    "This field is required.",
    function (value) {
      const { operating_expenses } = this.parent;
      return !operating_expenses || value ? true : false;
    },
  ),
  rent_term: Yup.string().required("This field is required"),
  opex_term: Yup.string().required("This field is required"),
});

const RentDetail = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { meta, showTooltip, hideTooltip, detailInfo } = state;
  const { closeoutId } = useParams();
  const standardRef = useRef<any>(null);
  const customRef = useRef<any>(null);
  const [getRent] = useApiCall(getRentAPI, false, true);
  const [putRent] = useApiCall(putRentAPI);
  const [postRent] = useApiCall(postRentAPI);
  const [data, setData] = useState<any>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isClearModal, setIsClearModal] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [mode, setMode] = useState(0); // 1 - Custom, 2 - Standard

  useEffect(() => {
    if (!closeoutId) return;
    getRent(closeoutId).then((res: any) => {
      if (!res) {
        setData(null);
        return;
      }
      setData(res);
      if (res?.use_custom) {
        setMode(1);
      } else {
        if (!!res?.grid || !!res?.overrides) setMode(2);
        else setMode(0);
      }
    });
  }, [closeoutId]);

  useEffect(() => {
    if (!data || !meta) {
      setValues(INIT_RENT);
      setFieldValue(
        "opex_term",
        getMetaOptions(meta?.leasecloseoutrentcalculator?.opex_term)[0]?.value,
      );
      setFieldValue(
        "rent_term",
        getMetaOptions(meta?.leasecloseoutrentcalculator?.rent_term)[0]?.value,
      );
      return;
    }

    let formValues: any = {};
    Object.keys(INIT_RENT).forEach((key: string) => {
      let value = data?.[key] || "";
      if (
        (key === "rent" ||
          key === "operating_expenses" ||
          key == "escalation") &&
        value
      ) {
        value = Number(value).toFixed(2);
      } else if (key === "opex_year" && !value) {
        value = new Date().getFullYear();
      }

      formValues = {
        ...formValues,
        [key]: value,
      };
    });
    setValues(formValues);
    if (!formValues?.opex_term)
      setFieldValue(
        "opex_term",
        getMetaOptions(meta?.leasecloseoutrentcalculator?.opex_term)[0]?.value,
      );
    if (!formValues?.rent_term)
      setFieldValue(
        "rent_term",
        getMetaOptions(meta?.leasecloseoutrentcalculator?.rent_term)[0]?.value,
      );
  }, [data, meta]);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
    setValues,
    isValid,
    dirty,
  } = useFormik({
    initialValues: INIT_RENT,
    validationSchema,
    onSubmit: async () => {
      if (isEditing) {
        setIsConfirmModal(true);
        return;
      }
      try {
        dispatch({
          type: SET_FULL_LOADING,
          payload: {
            open: true,
            label: "Submitting",
          },
        });
        let payload: any = {
          ...values,
          overrides: null,
          use_custom: false,
          escalation_date: getApiDate(values?.escalation_date),
          lease_closeout: closeoutId,
          opex_year: values?.opex_year ? values?.opex_year : null,
        };
        if (!payload?.escalation_type) delete payload["escalation_type"];

        if (!data)
          postRent(payload)
            .then((res: any) => {
              if (!res) {
                setData(null);
                return;
              }
              setData(res);
            })
            .finally(() => {
              dispatch({
                type: SET_FULL_LOADING,
                payload: {
                  open: false,
                  label: "",
                },
              });
            });
        else
          putRent({ id: closeoutId, payload })
            .then((res: any) => {
              if (!res) {
                setData(null);
                return;
              }
              setData(res);
            })
            .finally(() => {
              dispatch({
                type: SET_FULL_LOADING,
                payload: {
                  open: false,
                  label: "",
                },
              });
            });
      } catch (err) {
        dispatch({
          type: SET_FULL_LOADING,
          payload: {
            open: false,
            label: "",
          },
        });
      }
    },
  });

  return (
    <div className="relative w-full h-full">
      <section className="absolute top-10 left-0 right-0 bottom-[80px] flex flex-col px-14">
        <div className="flex flex-row items-center space-x-4 mb-4">
          <span className="text-jll-text-base-default text-2xl">
            Rent & Expenses
          </span>
          {/* {!!detailInfo?.files?.length && (
            <Button
              variant="secondary"
              size="medium"
              leadingIcon={AutoAwesomeOutlined}
              className="rounded-full"
              onClick={() => {
                dispatch({
                  type: SET_LEASE_PDF,
                  payload: 1,
                });
              }}
            />
          )} */}
        </div>
        <div className="h-full relative">
          <div className="-mx-14 flex flex-col h-full overflow-y-auto absolute top-0 left-0 right-0 bottom-0">
            <div className="mx-14">
              <form onSubmit={handleSubmit}>
                <div className="flex flex-row space-x-6 mb-6">
                  <Select.Single
                    options={getMetaOptions(
                      meta?.leasecloseoutrentcalculator?.rent_type,
                    )}
                    name="rent_type"
                    label="Rent Type"
                    divClassName="w-[240px] !mb-0"
                    onBlur={handleBlur as any}
                    onChange={(value: any) => {
                      if (!value) {
                        setFieldValue("rent_type", null);
                      } else {
                        setFieldValue("rent_type", value);
                      }
                    }}
                    selectedOption={
                      values?.rent_type
                        ? {
                            label:
                              meta?.leasecloseoutrentcalculator?.rent_type?.[
                                values?.rent_type
                              ]?.value,
                            value: values?.rent_type,
                          }
                        : undefined
                    }
                  />
                  <Input
                    name="rent"
                    type="number"
                    label="Base Rent"
                    className="w-[240px] !mb-0"
                    leadingCurrency="$"
                    trailingSelect
                    options={getMetaOptions(
                      meta?.leasecloseoutrentcalculator?.rent_term,
                    )}
                    onSelectChange={(option: any) => {
                      setFieldValue("rent_term", option?.value);
                    }}
                    selectedValue={values?.rent_term}
                    inputClassName="pr-28"
                    onChange={handleChange}
                    error={touched.rent ? errors.rent : ""}
                    onBlur={(e) => {
                      handleBlur(e);
                      setFieldValue("rent", Number(values?.rent).toFixed(2));
                    }}
                    value={values?.rent}
                    isRequired
                  />
                  <Input
                    name="term"
                    type="number"
                    label="Term"
                    month
                    className="w-[240px] !mb-0"
                    onChange={handleChange}
                    error={touched.term ? errors.term : ""}
                    onBlur={handleBlur}
                    value={values?.term}
                    isRequired
                  />
                  <Input
                    name="free_rent"
                    type="number"
                    label="Free Rent"
                    month
                    className="w-[240px] !mb-0"
                    onChange={handleChange}
                    error={touched.free_rent ? errors.free_rent : ""}
                    onBlur={handleBlur}
                    value={values?.free_rent}
                  />
                </div>

                <div className="flex flex-row space-x-6 mb-8">
                  <Input
                    name="escalation"
                    type="number"
                    label="Escalation"
                    leadingCurrency={
                      Number(values?.escalation_type) === 2 ? "$" : ""
                    }
                    trailingSelect
                    options={getMetaOptions(
                      meta?.leasecloseoutrentcalculator?.escalation_type,
                    )}
                    onSelectChange={(option: any) => {
                      setFieldValue("escalation_type", Number(option?.value));
                    }}
                    selectedValue={values?.escalation_type}
                    inputClassName={
                      Number(values?.escalation_type) === 2 ? "pr-28" : "pr-16"
                    }
                    className="w-[240px] !mb-0"
                    onChange={handleChange}
                    error={touched.escalation ? errors.escalation : ""}
                    onBlur={(e) => {
                      handleBlur(e);
                      setFieldValue(
                        "escalation",
                        Number(values?.escalation).toFixed(2),
                      );
                    }}
                    value={values?.escalation}
                    min={0}
                    step="0.01"
                  />

                  {values?.escalation ? (
                    <DatePicker
                      name="escalation_date"
                      label="First Rent Escalation Date"
                      trailingIcon={CalendarMonthOutlined}
                      className="w-[240px] !mb-0"
                      onBlur={handleBlur}
                      selected={
                        values?.escalation_date
                          ? getUTCDate(values?.escalation_date)
                          : null
                      }
                      error={
                        touched.escalation_date ? errors.escalation_date : ""
                      }
                      value={
                        values?.escalation_date
                          ? getFormatedDate(
                              getUTCDate(values?.escalation_date).toString(),
                            )
                          : undefined
                      }
                      onChange={(date: any) => {
                        setFieldValue("escalation_date", date);
                      }}
                      isRequired
                    />
                  ) : (
                    <></>
                  )}

                  <Input
                    name="operating_expenses"
                    type="number"
                    label="Operating Expenses"
                    className="w-[240px] !mb-0"
                    leadingCurrency="$"
                    trailingSelect
                    options={getMetaOptions(
                      meta?.leasecloseoutrentcalculator?.opex_term,
                    )}
                    inputClassName="pr-28"
                    onSelectChange={(option: any) => {
                      setFieldValue("opex_term", option?.value);
                    }}
                    selectedValue={values?.opex_term}
                    onChange={handleChange}
                    error={
                      touched.operating_expenses
                        ? errors.operating_expenses
                        : ""
                    }
                    onBlur={(e) => {
                      handleBlur(e);
                      setFieldValue(
                        "operating_expenses",
                        Number(values?.operating_expenses).toFixed(2),
                      );
                    }}
                    value={values?.operating_expenses}
                  />
                  {values?.operating_expenses ? (
                    <Input
                      name="opex_year"
                      type="number"
                      label="OPEX Year"
                      className="w-[240px] !mb-0"
                      onChange={handleChange}
                      error={touched.opex_year ? errors.opex_year : ""}
                      onBlur={handleBlur}
                      value={values?.opex_year}
                      isRequired
                    />
                  ) : (
                    <></>
                  )}

                  {!values.operating_expenses && !values.escalation ? (
                    <>
                      <div className="w-[240px] pt-8">
                        <Button
                          variant="secondary"
                          size="medium"
                          type="submit"
                          disabled={!isValid || !dirty}
                          className="h-full !px-5"
                        >
                          Save
                        </Button>
                      </div>
                      <div className="w-[240px]" />
                    </>
                  ) : (
                    (!values.operating_expenses || !values.escalation) && (
                      <div className="w-[240px] pt-8">
                        <Button
                          variant="secondary"
                          size="medium"
                          type="submit"
                          disabled={!isValid || !dirty}
                          className="h-full !px-5"
                        >
                          Save
                        </Button>
                      </div>
                    )
                  )}
                </div>

                {!!values.operating_expenses && !!values.escalation && (
                  <div>
                    <div className="w-[240px] mb-4">
                      <Button
                        variant="secondary"
                        size="medium"
                        type="submit"
                        disabled={!isValid || !dirty}
                        className="h-full !px-5"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                )}

                <div className="flex flex-col mb-8">
                  {data && (
                    <>
                      <p className="text-jll-text-base-subdued mb-4">
                        <span className="text-xl text-jll-text-base-default mr-5">
                          Choose an option to create a rent schedule.
                        </span>
                      </p>
                      <div className="flex flex-row space-x-6 items-center justify-between">
                        <div className="flex flex-row space-x-6">
                          <Button
                            variant={mode === 1 ? "primary" : "secondary"}
                            size="medium"
                            onClick={() => {
                              if (isEditing) {
                                return;
                              }
                              setMode(1);
                            }}
                            leadingIcon={mode === 1 ? CheckOutlined : undefined}
                            id={`custom_tooltip`}
                            onMouseOver={() => showTooltip(`custom_tooltip`)}
                            onMouseLeave={() => {
                              setTimeout(() => {
                                hideTooltip();
                              }, 10);
                            }}
                            data-tooltip-html={renderToString(
                              <div className="w-[200px] text-jll-text-base-default text-sm p-1">
                                <p className="font-semibold mb-2">
                                  Custom Rent Schedule
                                </p>
                                <p>
                                  This allows you to create a rent schedule by
                                  entering relevant details into a blank table.
                                </p>
                              </div>,
                            )}
                          >
                            Custom
                          </Button>
                          <Button
                            variant={mode === 2 ? "primary" : "secondary"}
                            size="medium"
                            leadingIcon={mode === 2 ? CheckOutlined : undefined}
                            onClick={() => {
                              if (isEditing) {
                                return;
                              }
                              setMode(2);
                            }}
                            id={`standard_tooltip`}
                            onMouseOver={() => showTooltip(`standard_tooltip`)}
                            onMouseLeave={() => {
                              setTimeout(() => {
                                hideTooltip();
                              }, 10);
                            }}
                            data-tooltip-html={renderToString(
                              <div className="w-[200px] text-jll-text-base-default text-sm p-1">
                                <p className="font-semibold mb-2">
                                  Standard Rent Schedule
                                </p>
                                <p>
                                  This allows you to automatically create a rent
                                  schedule using the values inputted above.
                                </p>
                              </div>,
                            )}
                          >
                            Standard
                          </Button>
                        </div>
                        <div className="flex flex-row space-x-4">
                          {/* {mode === 2 && (
                            <Button
                              variant="secondary"
                              size="medium"
                              type="submit"
                              disabled={!isValid || !dirty}
                            >
                              Calculate
                            </Button>
                          )} */}
                          {!isEditing && mode !== 0 && (
                            <Button
                              variant="neutral"
                              size="medium"
                              leadingIcon={EditOutlined}
                              leadingIconClass="jll-color-strokeOndark-default"
                              className="!border !border-jll-stroke-default"
                              onClick={() => setIsEditing(true)}
                            >
                              Edit Table
                            </Button>
                          )}
                          {mode !== 0 && isEditing && (
                            <Button
                              variant="neutral"
                              size="medium"
                              leadingIcon={DisabledByDefaultOutlined}
                              leadingIconClass="jll-color-strokeOndark-default"
                              className="!border !border-jll-stroke-default"
                              onClick={() => setIsClearModal(true)}
                            >
                              Clear Table
                            </Button>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </form>

              <div>
                {mode === 1 && (
                  <Custom
                    data={data}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    setData={setData}
                    ref={customRef}
                  />
                )}
                {mode === 2 && (
                  <Standard
                    data={data}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    setData={setData}
                    ref={standardRef}
                    values={values}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {isEditing ? (
        <section className="absolute bottom-0 left-0 right-0 h-[80px] bg-white border-t border-t-jll-stroke-subdued flex flex-row justify-center space-x-4 items-center px-14">
          <div className="text-sm text-jll-text-base-default flex flex-row items-center space-x-6">
            <Button
              variant="secondary"
              size="medium"
              onClick={() => {
                if (mode === 2) {
                  standardRef.current.onCancel();
                } else if (mode === 1) {
                  customRef.current.onCancel();
                }
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              size="medium"
              onClick={() => {
                if (mode === 2) {
                  standardRef.current.onSave();
                } else if (mode === 1) {
                  customRef.current.onSave();
                }
              }}
            >
              Save
            </Button>
          </div>
        </section>
      ) : (
        <Footer />
      )}
      <RentClearModal
        isOpen={isClearModal}
        setIsOpen={setIsClearModal}
        action={() => {
          if (mode === 2) {
            standardRef.current.onClear();
          } else if (mode === 1) {
            customRef.current.onClear();
          }
        }}
      />
      <RentConfirmModal isOpen={isConfirmModal} setIsOpen={setIsConfirmModal} />
    </div>
  );
};

export default RentDetail;
