import { Close } from "@mui/icons-material";
import { Button } from "ui-atoms";
import { Modal, StyledKeyDateValue } from "ui-molecules";
import "./styles.css";
import { useApiCall } from "hooks";
import { getAbstractionAPI, getKeyDatesAPI } from "services";
import { useContext, useEffect, useState } from "react";
import { capitalize, getDecimalFormating, getFormatedDate } from "utils";
import { GlobalContext } from "context";
import { INIT_KEY_DATA, KEY_DATE_COLUMNS, SET_FULL_LOADING } from "constant";
import { useParams } from "react-router-dom";
import { KeyDateProps } from "types";

interface AbstractionModalProps {
  isOpen: boolean;
  setIsOpen: any;
}

const AbstractionModal: React.FC<AbstractionModalProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { detailInfo } = state;
  const { closeoutId } = useParams();
  const [getAbstraction] = useApiCall(getAbstractionAPI);
  const [getKeyDates] = useApiCall(getKeyDatesAPI);
  const [data, setData] = useState<any>([]);
  const [keyData, setKeyData] = useState<any>([]);

  useEffect(() => {
    if (!isOpen || !detailInfo) {
      setData([]);
      return;
    }
    loadData();
  }, [isOpen, detailInfo]);

  const loadData = async () => {
    try {
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: true,
          label: "Loading AI Abstraction",
        },
      });
      const res = await getAbstraction(detailInfo?.dealio);
      let updated_data: any = [];
      Object.keys(res?.basic)
        ?.filter((key: string) => !!res?.basic?.[key])
        ?.forEach((key) => {
          switch (key) {
            case "Execution date":
            case "Lease commencement date":
            case "Lease expiration date":
              updated_data = [
                ...updated_data,
                {
                  label: capitalize(key),
                  value: `${res?.basic?.[key]?.["Date"] ? `${getFormatedDate(res?.basic?.[key]?.["Date"])}\n` : ""}${res?.basic?.[key]?.["Description"]}`,
                },
              ];
              return;

            case "Building address":
              updated_data = [
                ...updated_data,
                {
                  label: capitalize(key),
                  value:
                    res?.basic?.[key]?.["Full address"] ||
                    [
                      [
                        res?.basic?.[key]?.["City"],
                        res?.basic?.[key]?.["State"],
                      ]?.join(" "),
                      res?.basic?.[key]?.["Zip"],
                    ]?.join(", "),
                },
              ];
              return;

            case "Premise size":
              let value_label = "";
              res?.basic?.[key]?.forEach((item: any, idx: number) => {
                if (idx > 0) value_label += "\n";
                value_label += `Floor: ${item?.floor}, Suite: ${item?.suite}, Size: ${item?.size}`;
              });
              updated_data = [
                ...updated_data,
                { label: capitalize(key), value: value_label },
              ];
              return;

            default:
              if (typeof res?.basic?.[key] === "object") {
                updated_data = [
                  ...updated_data,
                  ...Object.keys(res?.basic?.[key])?.map((key1) => {
                    return {
                      label: capitalize(key1),
                      value: res?.basic?.[key]?.[key1],
                    };
                  }),
                ];
                return;
              }
              updated_data = [
                ...updated_data,
                {
                  label: capitalize(key),
                  value: res?.basic?.[key] || "",
                },
              ];
              return;
          }
        });

      const key_data = await getKeyDates({
        lease_closeout: closeoutId,
        page: 1,
        limit: 30,
        active_status: [1],
      });
      setKeyData((prevData: KeyDateProps[]) => [
        ...prevData,
        ...key_data?.docs,
      ]);

      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: false,
          label: "Loading AI Abstraction",
        },
      });
    } catch (err) {
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: false,
          label: "Loading AI Abstraction",
        },
      });
    }
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="xlarge">
      <Modal.Header>
        <div className="flex flex-row items-start justify-between">
          <div>
            <h3 className="text-2xl leading-6 text-jll-text-base-default mb-2.5">
              Abstraction sources
            </h3>
            <p className="text-jll-text-base-subdued">
              Changing source will replace current close out data with what you
              select” or something of that nature
            </p>
          </div>
          <Button
            variant="neutral"
            onClick={() => setIsOpen(false)}
            leadingIcon={Close}
            size="large"
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="mt-8 data-source">
          <table className="text-black w-full">
            <tbody>
              {data?.map((item: any, idx: number) => (
                <tr key={idx}>
                  <td className="px-4 py-2 align-top">{item?.label}</td>
                  <td className="px-4 py-2 whitespace-pre-wrap">
                    {item?.value}
                  </td>
                </tr>
              ))}
              <tr>
                <td className="px-4 py-2 align-top">Key dates and options</td>
                <td className="p-0">
                  <table className="w-full border-none" border={0}>
                    <thead>
                      <tr>
                        {KEY_DATE_COLUMNS.map((column: any, idx: number) => (
                          <td className="px-2 py-1">{column.label}</td>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {[...INIT_KEY_DATA, ...(keyData || [])]?.map(
                        (item, idx) => (
                          <tr key={idx}>
                            {KEY_DATE_COLUMNS.map((column, idx1: number) => (
                              <td key={idx1} className="px-2 py-1">
                                <StyledKeyDateValue
                                  keyDate={item}
                                  valueKey={column?.id}
                                  detail={detailInfo}
                                  index={idx}
                                />
                              </td>
                            ))}
                          </tr>
                        ),
                      )}
                    </tbody>
                  </table>
                </td>
              </tr>
              {detailInfo?.rent?.grid &&
                (() => {
                  const keys: any = Object.keys(detailInfo?.rent?.grid);
                  return (
                    <tr>
                      <td className="px-4 py-2 align-top">Rent Schedule</td>
                      <td className="p-0">
                        <table>
                          <thead>
                            <tr>
                              {keys?.map((column: any, idx: number) => (
                                <td className="px-2 py-1" key={idx}>
                                  {column}
                                </td>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {detailInfo?.rent?.grid?.[keys?.[0]]?.map(
                              (item: any, idx: number) => (
                                <tr key={idx}>
                                  {keys?.map((column: any, idx1: number) => (
                                    <td className="px-2 py-1" key={idx1}>
                                      {column
                                        ?.toLowerCase()
                                        ?.includes("period start") ||
                                      column
                                        ?.toLowerCase()
                                        ?.includes("period end")
                                        ? getFormatedDate(
                                            detailInfo?.rent?.grid?.[column]?.[
                                              idx
                                            ],
                                          )
                                        : detailInfo?.rent?.grid?.[column]?.[
                                            idx
                                          ]}
                                    </td>
                                  ))}
                                </tr>
                              ),
                            )}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  );
                })()}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex flex-row space-x-3 w-full justify-end">
          <Button
            variant="secondary"
            className="w-32"
            size="medium"
            onClick={() => setIsOpen(false)}
          >
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AbstractionModal;
