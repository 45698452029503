import { Search } from "@mui/icons-material";
import { Input } from "ui-atoms";
import { Footer, Loading, StyledRoleValue, Table } from "ui-molecules";
import cn from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useApiCall } from "hooks";
import { getBrokersAPI, getUsersAPI } from "services";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { SEARCH_RESULT_LIMIT } from "constant";
import debounce from "lodash.debounce";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { LayoutHome } from "ui-organisms";

const ROLE_COLUMNS = [
  { label: "Name", id: "name" },
  { label: "Email", id: "email" },
  { label: "Role", id: "role" },
  { label: "Team", id: "team" },
];

const RoleManagementDetail = () => {
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const location = useLocation();
  const navigate = useNavigate();
  const [getUsers, isLoading] = useApiCall(getUsersAPI);
  const [data, setData] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [keyword, setKeyword] = useState<string>();
  const [paramFilters, setParamFilters] = useState<any>(null);

  useEffect(() => {
    const filters = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    setParamFilters(filters);
  }, [location]);

  useEffect(() => {
    if (paramFilters === null) return;
    setKeyword(paramFilters?.keyword || "");
    setPage(1);
    setData([]);
    getUsers({
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      keyword: paramFilters?.keyword,
    }).then((data: any) => {
      if (!data) return;
      setData([...data?.docs]);
      setPage(data?.page);
      setTotal(data?.total);
    });
  }, [paramFilters]);

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    getUsers({
      page: nextPage,
      limit: SEARCH_RESULT_LIMIT,
      keyword: paramFilters?.keyword,
    }).then((data: any) => {
      if (!data) return;
      setData((prevData: any) => [...prevData, ...data?.docs]);
      setPage(data?.page);
      setTotal(data?.total);
    });
  };

  const updateKeywordFilter = (e: any) => {
    const updatedFilters = { ...paramFilters };
    const value = e.target.value;
    if (value) {
      updatedFilters["keyword"] = value;
    } else if (updatedFilters.hasOwnProperty("keyword")) {
      delete updatedFilters.keyword;
    }
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`,
    );
  };

  const debounceUpdateKeyword = useMemo(
    () => debounce(updateKeywordFilter, 300),
    [paramFilters],
  );

  const onChangeInput = (e: any) => {
    setKeyword(e.target.value);
    debounceUpdateKeyword(e);
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: total > data?.length,
    onLoadMore: loadMore,
  });

  const isAdmin =
    session?.is_superuser ||
    session?.is_staff ||
    !!session?.groups?.find((group: any) => group?.id === 1);

  if (!isAdmin) {
    navigate("/error?status=404");
    return <></>;
  }

  return (
    <LayoutHome>
      <div className="relative w-full h-full">
        <section className="absolute top-0 left-0 right-0 bottom-0 pt-10 flex flex-col px-14">
          <p className="text-jll-text-base-default text-2xl mb-8">
            Role Management
          </p>
          <Input
            name=""
            placeholder="Search for users"
            leadingIcon={Search}
            className="max-w-[400px] !mb-8"
            onChange={onChangeInput}
            value={keyword}
            isClearable
          />
          {!data?.length ? (
            <Table.Loading size={4} />
          ) : (
            <div className="h-full -mx-14 overflow-y-auto">
              <Table className="mb-2">
                <Table.Thead>
                  <Table.Tr>
                    {ROLE_COLUMNS.map((column, idx) => (
                      <Table.Th
                        key={idx}
                        itemClassName={cn({
                          "!pl-14": idx === 0,
                          "!pr-14": idx === ROLE_COLUMNS.length - 1,
                        })}
                      >
                        {column.label}
                      </Table.Th>
                    ))}
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {data?.map((item, idx) => (
                    <Table.Tr key={idx}>
                      {ROLE_COLUMNS.map((column, idx1) => (
                        <Table.Td
                          key={idx1}
                          className={cn({
                            "!pl-14": idx1 === 0,
                            "!pr-14 !z-auto": idx1 === ROLE_COLUMNS.length - 1,
                            "z-auto": column?.id === "role",
                          })}
                        >
                          <StyledRoleValue user={item} valueKey={column?.id} />
                        </Table.Td>
                      ))}
                    </Table.Tr>
                  ))}
                </Table.Tbody>
              </Table>
              {!!(total > data?.length) && (
                <div
                  className="w-full py-2 flex justify-center items-center"
                  ref={sentryRef}
                >
                  <Loading />
                </div>
              )}
            </div>
          )}
        </section>
        {/* <Footer /> */}
      </div>
    </LayoutHome>
  );
};

export default RoleManagementDetail;
