import { CheckOutlined, Close } from "@mui/icons-material";
import { Button } from "ui-atoms";
import { Modal } from "ui-molecules";
import cn from "classnames";
import "./styles.css";
import { useApiCall } from "hooks";
import { getSourceAPI } from "services";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { capitalize, getDecimalFormating, getFormatedDate } from "utils";

interface DataSourceModalProps {
  isOpen: boolean;
  setIsOpen: any;
}

const INIT_KEYS = [
  { label: "Name", id: "building_name", type: "" },
  { label: "Address", id: "building_address", type: "" },
  { label: "City", id: "building_city", type: "" },
  { label: "State", id: "building_state", type: "" },
  { label: "Zip Code", id: "building_zip", type: "" },
  { label: "Commencement", id: "commencement_date", type: "date" },
  { label: "Execution Date", id: "execution_date", type: "date" },
  { label: "Expiration", id: "expiration_date", type: "date" },
  { label: "Rental Rate", id: "rental_rate", type: "amount" },
  { label: "RSF", id: "rsf", type: "number" },
];

const DataSourceModal: React.FC<DataSourceModalProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const { closeoutId } = useParams();
  const [getSource] = useApiCall(getSourceAPI);
  const [data, setData] = useState<any>({});
  const [columns, setColumns] = useState<any>([
    { label: "Data Source", id: "source" },
  ]);
  const [keys, setKeys] = useState(INIT_KEYS);

  useEffect(() => {
    if (!isOpen || !closeoutId) {
      setData({});
      setColumns([{ label: "Data Source", id: "source" }]);
      setKeys(INIT_KEYS);
      return;
    }
    getSource(closeoutId).then((res: any) => {
      if (!res?.combined) return;
      setData(res?.combined);
      const cols = Object.keys(res?.combined).map((key) => {
        return { label: capitalize(key), id: key };
      });
      setColumns([{ label: "Data Source", id: "source" }, ...cols]);
      let key_options: any = [];
      Object.keys(res?.combined).forEach((key) => {
        if (!res?.combined?.[key]?.dates) return;
        Object.keys(res?.combined?.[key]?.dates)?.forEach((deep_key) => {
          if (key_options?.find((item: any) => item?.id === deep_key)) return;
          key_options.push({
            label: capitalize(deep_key).replaceAll("_", " "),
            id: deep_key,
            type: "deep_date",
          });
        });
      });
      setKeys([...INIT_KEYS, ...key_options]);
    });
  }, [isOpen, closeoutId]);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="xlarge">
      <Modal.Header>
        <div className="flex flex-row items-start justify-between">
          <div>
            <h3 className="text-2xl leading-6 text-jll-text-base-default mb-2.5">
              Data sources
            </h3>
            <p className="text-jll-text-base-subdued">
              Changing source will replace current close out data with what you
              select” or something of that nature
            </p>
          </div>
          <Button
            variant="neutral"
            onClick={() => setIsOpen(false)}
            leadingIcon={Close}
            size="large"
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="mt-8 data-source">
          <table className="text-black w-full">
            <thead>
              <tr>
                {columns.map((column: any, idx: number) => (
                  <th
                    className="px-4 py-2 bg-jll-surface-base-secondary font-normal"
                    key={idx}
                  >
                    {column.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {keys?.map((option: any, idx: number) => (
                <tr key={idx}>
                  <td className="px-4 py-2">{option?.label}</td>
                  {Object.keys(data)?.map((key, idx1) => (
                    <>
                      <td className="px-4 py-2" key={idx1}>
                        {(() => {
                          switch (option?.type) {
                            case "date":
                              return (
                                <>
                                  {getFormatedDate(data?.[key]?.[option?.id]) ||
                                    ""}
                                </>
                              );

                            case "deep_date":
                              return (
                                <>
                                  {getFormatedDate(
                                    data?.[key]?.dates?.[option?.id],
                                  ) || ""}
                                </>
                              );

                            case "number":
                              return (
                                <>
                                  {getDecimalFormating(
                                    data?.[key]?.[option?.id],
                                  ) || ""}
                                </>
                              );

                            case "amount":
                              return (
                                <>{`${getDecimalFormating(data?.[key]?.[option?.id]) || ""}`}</>
                              );

                            default:
                              if (
                                data?.[key]?.[option?.id] &&
                                typeof data?.[key]?.[option?.id] === "object"
                              ) {
                                if (option?.id === "building_address")
                                  return data?.[key]?.[option?.id]?.[
                                    "Full address"
                                  ];
                                let str = "";
                                Object.keys(data?.[key]?.[option?.id])?.forEach(
                                  (key1, idx) => {
                                    if (idx > 0) str += ", ";
                                    str += `${key1}: ${data?.[key]?.[option?.id]?.[key1]}`;
                                  },
                                );
                                return str;
                              }
                              return <>{data?.[key]?.[option?.id] || ""}</>;
                          }
                        })()}
                      </td>
                    </>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex flex-row space-x-3 w-full justify-end">
          <Button
            variant="secondary"
            className="w-32"
            size="medium"
            onClick={() => setIsOpen(false)}
          >
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DataSourceModal;
