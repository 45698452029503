const styles: any = {
  view: {
    height: "100%",
    width: "100%",
  },
  title: {
    color: "#01151C",
    fontSize: 24,
    paddingBottom: 32,
    backgroundColor: "#F6F9FA",
    paddingLeft: 54,
    paddingRight: 54,
  },

  detail: {
    view: {
      width: "100%",
      paddingLeft: 54,
      paddingRight: 54,
      backgroundColor: "#F6F9FA",
      paddingBottom: 16,
      display: "flex",
      flexDirection: "row",
      marginBottom: 20,
      flexWrap: "wrap",
    },
    item: {
      view: {
        display: "flex",
        flexDirection: "column",
        width: "20%",
        marginBottom: 12,
      },
      label: {
        color: "#01151C",
        fontSize: 10,
      },
      valueView: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        paddingTop: 4,
        paddingBottom: 4,
      },
      value: {
        color: "#01151C",
        fontSize: 12,
      },
      unit: {
        color: "#01151C",
        fontSize: 12,
      },
    },
  },

  section: {
    view: {
      paddingLeft: 54,
      paddingRight: 54,
    },
    list: {
      view: {
        display: "flex",
        flexDirection: "column",
      },
      row: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      },
      item: {
        fontSize: 10,
        color: "#01151C",
        paddingLeft: 10,
        paddingRight: 10,
      },
      footnote: {
        color: "#01151C",
        fontSize: 10,
        lineHeight: 1.4,
        paddingTop: 6,
        paddingLeft: 10,
        paddingRight: 10,
        display: "flex",
        flexWrap: "wrap",
      },
    },
  },
};

export default styles;
