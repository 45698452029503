import { FC, useContext } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import cn from "classnames";
import { capitalizeName, lazyLoadImageOnError } from "utils";
import { GlobalContext } from "context";
import { renderToString } from "react-dom/server";

interface AvatarGroupProps {
  className?: string;
  brokers: any[];
  max?: number;
  uniqueId?: string | number;
  fontClassName?: string;
}

/**
 * Avatar Group
 */
const AvatarGroup: FC<AvatarGroupProps> = ({
  className,
  brokers,
  max = 5,
  uniqueId = "",
  fontClassName,
  ...props
}) => {
  const { state } = useContext(GlobalContext);
  const { showTooltip, hideTooltip } = state;
  const limit = max || brokers.length;

  return (
    <>
      <ul
        aria-label="Avatar group"
        className={cn("flex -space-x-2", className)}
      >
        {brokers.slice(0, max).map((item, index) => {
          return (
            <li
              key={index}
              id={`${uniqueId}_item_${index}_${item?.id}`}
              className="list-item cursor-pointer"
              data-tooltip-content={
                item?.display_name ||
                [item?.first_name, item?.last_name]?.join(" ") ||
                item?.email ||
                ""
              }
              onMouseOver={() =>
                showTooltip(`${uniqueId}_item_${index}_${item?.id}`)
              }
              onMouseLeave={() => {
                hideTooltip();
              }}
            >
              <div className="rounded-full flex justify-center items-center">
                {!!item?.headshot?.length ? (
                  <LazyLoadImage
                    className="inline-block h-10 min-w-[40px] w-10 rounded-full object-cover border-2 border-white"
                    src={item?.headshot}
                    onError={lazyLoadImageOnError}
                    {...props}
                  />
                ) : (
                  <div className="h-10 w-10 rounded-full border-2 border-white flex justify-center items-center bg-jll-surface-base-secondary text-jll-text-base-subdued">
                    <span className={cn("text-base", fontClassName)}>
                      {capitalizeName(
                        item?.display_name ||
                          [item?.first_name, item?.last_name]?.join(" ") ||
                          "",
                      )}
                    </span>
                  </div>
                )}
              </div>
            </li>
          );
        })}
        {brokers.length - max > 0 && (
          <li className="list-item">
            <div className="rounded-full border-white">
              <div
                className="inline-block h-10 w-10 rounded-full bg-jll-surface-base-secondary flex items-center text-jll-text-base-subdued justify-center"
                id={`${uniqueId}_item_show_more`}
                onMouseOver={() => showTooltip(`${uniqueId}_item_show_more`)}
                onMouseLeave={() => {
                  hideTooltip();
                }}
                data-tooltip-html={renderToString(
                  <div className="w-fit text-jll-text-base-default p-1 space-y-4">
                    {brokers.slice(max)?.map((item, idx) => (
                      <div
                        className="flex flex-row space-x-3 items-center"
                        key={idx}
                      >
                        <div className="rounded-full flex justify-center items-center">
                          {!!item?.headshot?.length ? (
                            <img
                              className="inline-block h-7 w-7 rounded-full object-cover"
                              src={item?.headshot}
                              onError={lazyLoadImageOnError}
                              {...props}
                            />
                          ) : (
                            <div className="h-7 w-7 rounded-full flex justify-center items-center bg-jll-surface-base-secondary text-jll-text-base-subdued">
                              <span className={cn("text-sm", fontClassName)}>
                                {capitalizeName(
                                  item?.display_name ||
                                    [item?.first_name, item?.last_name]?.join(
                                      " ",
                                    ) ||
                                    "",
                                )}
                              </span>
                            </div>
                          )}
                        </div>
                        <span className="text-jll-text-base-default">
                          {item?.display_name ||
                            [item?.first_name, item?.last_name]?.join(" ") ||
                            item?.email ||
                            ""}
                        </span>
                      </div>
                    ))}
                  </div>,
                )}
              >
                +{brokers.length - max}
              </div>
            </div>
          </li>
        )}
      </ul>
    </>
  );
};

export default AvatarGroup;
