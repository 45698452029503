import { Search } from "@mui/icons-material";
import { SET_DETAIL_INFO, SET_FULL_LOADING } from "constant";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getBuildingsAPI, patchDetailAPI } from "services";
import { Input, InputSearch, Select } from "ui-atoms";
import { capitalize, getMetaOptions } from "utils";
import * as Yup from "yup";

const INIT_OFFICE = {
  jll_building_name: "",
  jll_building_address: "",
  jll_building_city: "",
  jll_building_zip: "",
  jll_building_state: "",
  jll_building_phone: "",
};

const validationSchema = Yup.object().shape({
  jll_building_phone: Yup.string().required("This field is required"),
});

const MainOffice = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { meta, detailInfo } = state;
  const { closeoutId } = useParams();
  const [patchDetail] = useApiCall(patchDetailAPI);
  const [getBuildings] = useApiCall(getBuildingsAPI);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIsLoading, setSelectedIsLoading] = useState<string[]>([]);

  useEffect(() => {
    if (detailInfo?.id?.toString() !== closeoutId?.toString()) return;
    let formValues: any = {};
    Object.keys(INIT_OFFICE).forEach((key: string) => {
      formValues = {
        ...formValues,
        [key]: detailInfo?.[key] || "",
      };
    });
    setValues({ ...formValues });
  }, [detailInfo]);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: INIT_OFFICE,
    validationSchema,
    onSubmit: async () => {},
  });

  const handleDetail = (names: string[], values: any[], e?: any) => {
    try {
      if (names?.length === 1 && values?.[0] === detailInfo?.[names?.[0]])
        return;
      let payload = {};
      names?.forEach((name, idx) => {
        payload = {
          ...payload,
          [name]: values[idx] || "",
        };
      });
      setSelectedIsLoading(names);
      patchDetail({ id: closeoutId, payload })
        .then((res: any) => {
          if (!res) return;
          let updated = {};
          names?.forEach((name, idx) => {
            updated = {
              ...payload,
              [name]: res?.[name] || "",
            };
          });
          dispatch({
            type: SET_DETAIL_INFO,
            payload: {
              ...detailInfo,
              ...updated,
            },
          });
        })
        .finally(() => {
          setSelectedIsLoading([]);
        });
    } catch (err) {
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: false,
          label: "",
        },
      });
    }
  };

  const onSelectBuilding = (item: any) => {
    setIsLoading(true);
    const payload = {
      jll_building_name: item?.name || "",
      jll_building_address: item?.address || "",
      jll_building_city: item?.city || "",
      jll_building_zip: item?.zip || "",
      jll_building_state: item?.state || "",
      jll_building_phone: item?.phone || "",
    };

    patchDetail({ id: closeoutId, payload })
      .then((res: any) => {
        if (!res) return;
        let updated = {};
        Object.keys(INIT_OFFICE)?.forEach((name, idx) => {
          updated = {
            ...payload,
            [name]: res?.[name] || "",
          };
        });
        dispatch({
          type: SET_DETAIL_INFO,
          payload: {
            ...detailInfo,
            ...updated,
          },
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="mt-8 flex flex-col h-full">
      <p className="text-jll-text-base-subdued mb-8">
        Add your office location to include on contacts section of the
        deliverable.
      </p>
      <div className="max-w-[550px]">
        <InputSearch
          placeholder="Search office location"
          leadingIcon={Search}
          className="!mb-4"
          serviceApi={getBuildings}
          onChange={(item: any) => onSelectBuilding(item)}
        />

        <Input
          name="jll_building_name"
          label="Name"
          className="!mb-4"
          onChange={handleChange}
          error={touched.jll_building_name ? errors.jll_building_name : ""}
          value={values?.jll_building_name}
          isLoading={
            isLoading || selectedIsLoading?.includes("jll_building_name")
          }
          onBlur={(e) => {
            handleBlur(e);
            handleDetail(["jll_building_name"], [values?.jll_building_name], e);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleDetail(["jll_building_name"], [values?.jll_building_name]);
            }
          }}
        />

        <div className="flex flex-row">
          <Input
            name="jll_building_address"
            label="Address"
            className="w-full !mb-4"
            onChange={handleChange}
            error={
              touched.jll_building_address ? errors.jll_building_address : ""
            }
            value={values?.jll_building_address}
            isLoading={
              isLoading || selectedIsLoading?.includes("jll_building_address")
            }
            onBlur={(e) => {
              handleBlur(e);
              handleDetail(
                ["jll_building_address"],
                [values?.jll_building_address],
                e,
              );
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleDetail(
                  ["building_address"],
                  [values?.jll_building_address],
                );
              }
            }}
          />
        </div>

        <div className="flex flex-row space-x-6">
          <Input
            name="jll_building_city"
            label="City"
            className="w-full !mb-4"
            onChange={handleChange}
            error={touched.jll_building_city ? errors.jll_building_city : ""}
            value={values?.jll_building_city}
            isLoading={
              isLoading || selectedIsLoading?.includes("jll_building_city")
            }
            onBlur={(e) => {
              handleBlur(e);
              handleDetail(
                ["jll_building_city"],
                [values?.jll_building_city],
                e,
              );
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleDetail(
                  ["jll_building_city"],
                  [values?.jll_building_city],
                );
              }
            }}
          />
          <Select.Single
            options={getMetaOptions(meta?.leasecloseout?.building_state) || []}
            label="State"
            name="jll_building_state"
            divClassName="w-full !mb-4"
            onBlur={(e) => {
              handleBlur(e);
            }}
            onChange={(value: any) => {
              if (!value) {
                setFieldValue("jll_building_state", null);
                handleDetail(["jll_building_state"], [null]);
              } else {
                setFieldValue("jll_building_state", value);
                handleDetail(["jll_building_state"], [value]);
              }
            }}
            align="top"
            selectedOption={
              values?.jll_building_state
                ? {
                    label:
                      meta?.leasecloseout?.building_state?.[
                        values?.jll_building_state
                      ]?.value,
                    value: values?.jll_building_state,
                  }
                : undefined
            }
            disabled={
              isLoading || selectedIsLoading?.includes("jll_building_state")
            }
          />
          <Input
            name="jll_building_zip"
            label="Zip"
            className="w-full !mb-4"
            onChange={handleChange}
            error={touched.jll_building_zip ? errors.jll_building_zip : ""}
            value={values?.jll_building_zip}
            isLoading={
              isLoading || selectedIsLoading?.includes("jll_building_zip")
            }
            onBlur={(e) => {
              handleBlur(e);
              handleDetail(["jll_building_zip"], [values?.jll_building_zip], e);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleDetail(["jll_building_zip"], [values?.jll_building_zip]);
              }
            }}
          />
        </div>

        <Input
          name="jll_building_phone"
          label="Phone Number"
          className="!mb-4"
          onChange={handleChange}
          error={touched.jll_building_phone ? errors.jll_building_phone : ""}
          value={values?.jll_building_phone}
          isLoading={
            isLoading || selectedIsLoading?.includes("jll_building_phone")
          }
          onBlur={(e) => {
            handleBlur(e);
            handleDetail(
              ["jll_building_phone"],
              [values?.jll_building_phone],
              e,
            );
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleDetail(
                ["jll_building_phone"],
                [values?.jll_building_phone],
              );
            }
          }}
        />
      </div>
    </div>
  );
};

export default MainOffice;
