import { Close } from "@mui/icons-material";
import { SET_DETAIL_INFO, SET_FULL_LOADING } from "constant";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { patchContactAPI, postContactAPI, postGroupAPI } from "services";
import { Button, Input, Select } from "ui-atoms";
import { Modal, OptionItem } from "ui-molecules";
import * as Yup from "yup";

interface IContactModal {
  isOpen: boolean;
  setIsOpen: any;
  groups?: any;
  selectedContact?: any;
}

const INIT_CONTACT = {
  name: "", // Group Name
  contact_name: "",
  email: "",
  company_name: "",
  phone: "",
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("This field is required"),
  contact_name: Yup.string().required("This field is required"),
  email: Yup.string().required("This field is required").email("Invalid Email"),
  company_name: Yup.string().required("This field is required"),
  phone: Yup.string().required("This field is required"),
});

const ContactModal: React.FC<IContactModal> = ({
  isOpen,
  setIsOpen,
  groups,
  selectedContact,
}) => {
  const { dispatch, state } = useContext(GlobalContext);
  const { detailInfo } = state;
  const [postGroup] = useApiCall(postGroupAPI);
  const [postContact] = useApiCall(postContactAPI);
  const [patchContact] = useApiCall(patchContactAPI);
  const [type, setType] = useState<any>();
  const { closeoutId } = useParams();

  const typeList = [
    ...(groups || [])?.map((option: any) => ({
      label: option.name,
      value: option.id,
    })),
    ...[{ label: "Custom", value: "custom" }],
  ];

  useEffect(() => {
    if (!isOpen) return;
    setTouched({}, false);
    if (!selectedContact) {
      setValues(INIT_CONTACT);
      setType("");
      return;
    }
    let formValues: any = {};
    Object.keys(INIT_CONTACT).forEach((key: string) => {
      formValues = {
        ...formValues,
        [key]: selectedContact?.[key] || "",
      };
    });
    setValues(formValues);
    setFieldValue("name", selectedContact?.lease_closeout_contact_group?.id);
  }, [selectedContact, isOpen]);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
    setValues,
    isValid,
    dirty,
    setTouched,
  } = useFormik({
    initialValues: INIT_CONTACT,
    validationSchema,
    onSubmit: async () => {
      try {
        dispatch({
          type: SET_FULL_LOADING,
          payload: {
            open: true,
            label: "Submitting",
          },
        });
        let lease_closeout_contact_group;
        if (type === "custom") {
          const result = await postGroup({
            name: values.name,
            lease_closeout: closeoutId,
          });
          if (!!result) {
            lease_closeout_contact_group = result.id;
            let groups = [...detailInfo?.groups];
            groups.push(result);
            dispatch({
              type: SET_DETAIL_INFO,
              payload: {
                ...detailInfo,
                groups,
              },
            });
          }
        } else {
          lease_closeout_contact_group = values.name;
        }

        if (!selectedContact)
          postContact({
            lease_closeout_contact_group,
            contact_name: values.contact_name,
            email: values.email,
            company_name: values.company_name,
            phone: values?.phone,
          })
            .then((res: any) => {
              if (!res) return;
              let contacts = [...detailInfo?.contacts];
              contacts.push(res);
              dispatch({
                type: SET_DETAIL_INFO,
                payload: {
                  ...detailInfo,
                  contacts,
                },
              });
            })
            .finally(() => {
              dispatch({
                type: SET_FULL_LOADING,
                payload: {
                  open: false,
                  label: "",
                },
              });
              setIsOpen(false);
            });
        else
          patchContact({
            id: selectedContact?.id,
            payload: {
              ...values,
            },
          })
            .then((res: any) => {
              if (!res) return;
              let contacts = [...detailInfo?.contacts];
              const index = [...contacts].findIndex(
                (contact) => contact.id === res?.id,
              );
              if (index < 0) return;
              contacts[index] = {
                ...contacts[index],
                ...res,
              };
              dispatch({
                type: SET_DETAIL_INFO,
                payload: {
                  ...detailInfo,
                  contacts,
                },
              });
            })
            .finally(() => {
              dispatch({
                type: SET_FULL_LOADING,
                payload: {
                  open: false,
                  label: "",
                },
              });
              setIsOpen(false);
            });
      } catch (err) {
        dispatch({
          type: SET_FULL_LOADING,
          payload: {
            open: false,
            label: "",
          },
        });
      }
    },
  });

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="normal">
      <Modal.Header>
        <div className="flex flex-row items-center justify-between">
          <h3 className="text-xl leading-6 text-jll-text-base-default">
            Additional Contact
          </h3>
          <Button
            variant="neutral"
            onClick={() => setIsOpen(false)}
            leadingIcon={Close}
            size="large"
          />
        </div>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="mt-6">
            {/* <Select.Single
              options={typeList}
              label="Contact Type"
              name="name"
              divClassName="w-full !mb-6"
              onBlur={handleBlur as any}
              onChange={(value: any) => {
                if (!value) {
                  setType("");
                  setFieldValue("name", null);
                } else {
                  if (value === "custom") {
                    setType("custom");
                    setFieldValue("name", null);
                  } else {
                    setType("");
                    setFieldValue("name", value);
                  }
                }
              }}
              selectedOption={
                type === "custom"
                  ? { label: "Custom", value: "custom" }
                  : typeList?.find((option) => option.value === values.name)
              }
              disabled={!!selectedContact}
            /> */}
            <div className="flex flex-col w-full mb-6">
              <p className="text-jll-text-base-subdued mb-4">Contact Type</p>
              <div className="flex flex-wrap gap-2.5">
                {typeList.map((option, idx) => (
                  <OptionItem
                    key={idx}
                    label={option.label}
                    value={option.value}
                    isSelected={
                      type === "custom" ? true : option.value === values?.name
                    }
                    onSelect={(value: any) => {
                      if (!value) {
                        setType("");
                        setFieldValue("name", null);
                      } else {
                        if (value === "custom") {
                          setType("custom");
                          setFieldValue("name", null);
                        } else {
                          setType("");
                          setFieldValue("name", value);
                        }
                      }
                    }}
                  />
                ))}
              </div>
            </div>

            {type === "custom" && (
              <Input
                name="name"
                label="Custom Type Name"
                onChange={handleChange}
                error={touched.name ? errors.name : ""}
                onBlur={handleBlur}
                value={values?.name}
              />
            )}

            {/* <div className="flex flex-row space-x-6"> */}
            <Input
              name="company_name"
              label="Company Name"
              className="mb-2 w-full"
              onChange={handleChange}
              error={touched.company_name ? errors.company_name : ""}
              onBlur={handleBlur}
              value={values?.company_name}
            />
            <Input
              name="contact_name"
              label="Contact Name"
              className="mb-2 w-full"
              onChange={handleChange}
              error={touched.contact_name ? errors.contact_name : ""}
              onBlur={handleBlur}
              value={values?.contact_name}
            />
            {/* </div> */}

            {/* <div className="flex flex-row space-x-6"> */}
            <Input
              name="email"
              label="Email"
              className="mb-2 w-full"
              onChange={handleChange}
              error={touched.email ? errors.email : ""}
              onBlur={handleBlur}
              value={values?.email}
            />
            <Input
              name="phone"
              label="Phone"
              className="mb-2 w-full"
              onChange={handleChange}
              error={touched.phone ? errors.phone : ""}
              onBlur={handleBlur}
              value={values?.phone}
            />
            {/* </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex flex-row space-x-3 w-full justify-end">
            <Button
              variant="secondary"
              className="w-32"
              size="medium"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="w-32"
              type="submit"
              size="medium"
              disabled={!isValid || !dirty}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ContactModal;
