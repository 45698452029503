import { Close, DragIndicatorOutlined } from "@mui/icons-material";
import {
  ADDITIONAL_CONTACT,
  CONTENT,
  COVER,
  DEFAULT_LEASE_OVERVIEW_FIELDS,
  FLOORPLAN,
  KEY_DATES,
  LEASE_OVERVIEW,
  RENT_EXPENSES,
  SET_DETAIL_INFO,
  SET_FULL_LOADING,
  TEAM,
} from "constant";
import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import {
  getDetailAPI,
  patchDeliverableAPI,
  postDeliverableAPI,
} from "services";
import { Button, Checkbox } from "ui-atoms";
import { Modal, Table } from "ui-molecules";
import { getDetailValue, getFormatedDate, reorder } from "utils";
import cn from "classnames";

interface ILeaseOverviewOrderModal {
  isOpen: boolean;
  setIsOpen: any;
}

const LeaseOverviewOrderModal: React.FC<ILeaseOverviewOrderModal> = ({
  isOpen,
  setIsOpen,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { detailInfo, meta } = state;
  const { closeoutId } = useParams();
  const [getDetail] = useApiCall(getDetailAPI);
  const [postDeliverable] = useApiCall(postDeliverableAPI);
  const [patchDeliverable] = useApiCall(patchDeliverableAPI);
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if (!closeoutId || !isOpen) {
      return;
    }
    dispatch({
      type: SET_FULL_LOADING,
      payload: {
        open: true,
        label: "Loading Detail",
      },
    });
    getDetail(closeoutId)
      ?.then((res: any) => {
        if (!res) {
          dispatch({
            type: SET_DETAIL_INFO,
            payload: null,
          });
          setData({});
          return;
        }
        if (res?.deliverable?.overview) {
          setData({ ...res?.deliverable, pk: res?.id });
          return;
        }
        let totalOverviewData: any = [];
        DEFAULT_LEASE_OVERVIEW_FIELDS.forEach(({ label, id, visible }: any) => {
          const value = getDetailValue(res, id, meta);
          if (!value?.length) return;
          totalOverviewData.push({
            label,
            value,
            isChecked: visible,
          });
        });
        if (!!res?.additional?.length)
          res?.additional?.forEach((item: any) => {
            totalOverviewData.push({
              label: item?.name,
              value: item?.description,
              isChecked: true,
            });
          });

        if (!!res?.dates?.length)
          res?.dates?.forEach((item: any) => {
            totalOverviewData.push({
              label: item?.type,
              value: `${item?.value_min && item?.value_max ? `${getFormatedDate(item?.value_min)} - ${getFormatedDate(item?.value_max)}` : item?.value_min ? getFormatedDate(item?.value_min) : item?.value_max && getFormatedDate(item?.value_max)}\n${item?.description}`,
              isChecked: false,
            });
          });

        setData({
          ...(res?.deliverable || {}),
          overview: totalOverviewData,
          pk: res?.id,
        });

        dispatch({
          type: SET_DETAIL_INFO,
          payload: res,
        });
      })
      .finally(() => {
        dispatch({
          type: SET_FULL_LOADING,
          payload: {
            open: false,
            label: "",
          },
        });
      });
  }, [closeoutId, isOpen]);

  const handleSelect = (id: number, isChecked: boolean) => {
    try {
      const new_itemList = [...data?.overview];
      const index = new_itemList?.findIndex((item: any) => item?.label === id);
      if (index > -1) {
        new_itemList[index].isChecked = isChecked;
      }
      setData({ ...data, overview: new_itemList });
      if (!!data?.id)
        patchDeliverable({
          id: data?.pk,
          payload: {
            ...data,
            pages: data?.pages || [
              COVER,
              CONTENT,
              TEAM,
              LEASE_OVERVIEW,
              RENT_EXPENSES,
              FLOORPLAN,
              KEY_DATES,
              ADDITIONAL_CONTACT,
            ],
            overview: new_itemList,
          },
        }).then((res: any) => {
          if (!res) return;
          dispatch({
            type: SET_DETAIL_INFO,
            payload: {
              ...detailInfo,
              deliverable: res,
            },
          });
        });
      else
        postDeliverable({
          payload: {
            ...data,
            lease_closeout: closeoutId,
            pages: data?.pages || [
              COVER,
              CONTENT,
              TEAM,
              LEASE_OVERVIEW,
              RENT_EXPENSES,
              FLOORPLAN,
              KEY_DATES,
              ADDITIONAL_CONTACT,
            ],
            overview: new_itemList,
          },
        }).then((res: any) => {
          if (!res) return;
          dispatch({
            type: SET_DETAIL_INFO,
            payload: {
              ...detailInfo,
              deliverable: res,
            },
          });
        });
    } catch (err) {}
  };

  const handleAllChecked = (isChecked: boolean) => {
    try {
      const new_itemList = [...data?.overview]?.map((item: any) => {
        return { ...item, isChecked };
      });
      setData({ ...data, overview: new_itemList });
      if (!!data?.id)
        patchDeliverable({
          id: data?.pk,
          payload: {
            ...data,
            pages: data?.pages || [
              COVER,
              CONTENT,
              TEAM,
              LEASE_OVERVIEW,
              RENT_EXPENSES,
              FLOORPLAN,
              KEY_DATES,
              ADDITIONAL_CONTACT,
            ],
            overview: new_itemList,
          },
        }).then((res: any) => {
          if (!res) return;
          dispatch({
            type: SET_DETAIL_INFO,
            payload: {
              ...detailInfo,
              deliverable: res,
            },
          });
        });
      else
        postDeliverable({
          payload: {
            ...data,
            pages: data?.pages || [
              COVER,
              CONTENT,
              TEAM,
              LEASE_OVERVIEW,
              RENT_EXPENSES,
              FLOORPLAN,
              KEY_DATES,
              ADDITIONAL_CONTACT,
            ],
            lease_closeout: closeoutId,
            overview: new_itemList,
          },
        }).then((res: any) => {
          if (!res) return;
          dispatch({
            type: SET_DETAIL_INFO,
            payload: {
              ...detailInfo,
              deliverable: res,
            },
          });
          setData({ ...data, ...res });
        });
    } catch (err) {}
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const newOrder = reorder(
      [...data?.overview],
      result?.source.index,
      result?.destination.index,
    );
    try {
      setData({ ...data, overview: newOrder });
      if (!!data?.id)
        patchDeliverable({
          id: data?.pk,
          payload: {
            ...data,
            pages: data?.pages || [
              COVER,
              CONTENT,
              TEAM,
              LEASE_OVERVIEW,
              RENT_EXPENSES,
              FLOORPLAN,
              KEY_DATES,
              ADDITIONAL_CONTACT,
            ],
            overview: newOrder,
          },
        }).then((res: any) => {
          if (!res) return;
          dispatch({
            type: SET_DETAIL_INFO,
            payload: {
              ...detailInfo,
              deliverable: res,
            },
          });
        });
      else
        postDeliverable({
          payload: {
            ...data,
            pages: data?.pages || [
              COVER,
              CONTENT,
              TEAM,
              LEASE_OVERVIEW,
              RENT_EXPENSES,
              FLOORPLAN,
              KEY_DATES,
              ADDITIONAL_CONTACT,
            ],
            lease_closeout: closeoutId,
            overview: newOrder,
          },
        }).then((res: any) => {
          if (!res) return;
          dispatch({
            type: SET_DETAIL_INFO,
            payload: {
              ...detailInfo,
              deliverable: res,
            },
          });
          setData({ ...data, ...res });
        });
    } catch (err) {}
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="xlarge">
      <Modal.Header>
        <div className="flex flex-row items-center justify-between">
          <h3 className="text-xl leading-6 text-jll-text-base-default">
            Lease Overview
          </h3>
          <Button
            variant="neutral"
            onClick={() => setIsOpen(false)}
            leadingIcon={Close}
            size="large"
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="mt-6">
          <div className="flex flex-row items-center justify-between mb-4">
            <span>
              Select information to include and rearrange the display order.
            </span>
            <div className="flex flex-row items-center space-x-3 mr-[18px]">
              <span className="text-jll-text-base-default">Display All</span>
              <Checkbox
                id={""}
                checked={
                  !!data?.overview?.length &&
                  data?.overview?.length ===
                    data?.overview?.filter((item: any) => item.isChecked)
                      ?.length
                }
                onChange={(e: any) => handleAllChecked(e?.target?.checked)}
              />
            </div>
          </div>
          <div>
            <Table className="!border !border-jll-color-stroke-base-default !border-solid">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="overview-reorder">
                  {(provided, snapshot) => (
                    <Table.Tbody
                      {...provided.droppableProps}
                      ref={provided?.innerRef}
                      className="relative"
                    >
                      {data?.overview?.map((item: any, idx: number) => (
                        <Draggable
                          key={idx}
                          draggableId={`overview-item-${idx}`}
                          index={idx}
                        >
                          {(provided, snapshot) => (
                            <Table.Tr
                              key={idx}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className={cn({
                                "w-full table bg-white !top-auto !left-auto":
                                  snapshot.isDragging,
                              })}
                            >
                              <Table.Td className="w-6 align-top !px-2 !py-2 bg-jll-surface-base-secondary">
                                <div
                                  className="w-5 h-6 flex items-center justify-center"
                                  {...provided.dragHandleProps}
                                >
                                  <DragIndicatorOutlined className="!w-full !h-full text-jll-text-base-subdued" />
                                </div>
                              </Table.Td>
                              <Table.Td className="!px-2 !py-2 relative bg-jll-surface-base-secondary w-[200px]">
                                <div className="whitespace-normal absolute left-2 right-2 top-2 bottom-2">
                                  {item?.label}
                                </div>
                              </Table.Td>
                              <Table.Td className="!px-4 !py-2">
                                <div className="w-full items-center justify-between flex">
                                  <span className="whitespace-pre-wrap">
                                    {item?.value}
                                  </span>
                                  <Checkbox
                                    checked={item?.isChecked}
                                    id={`overview-item-${idx}`}
                                    onChange={(e: any) =>
                                      handleSelect(
                                        item?.label,
                                        e.target.checked,
                                      )
                                    }
                                  />
                                </div>
                              </Table.Td>
                            </Table.Tr>
                          )}
                        </Draggable>
                      ))}
                    </Table.Tbody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LeaseOverviewOrderModal;
