import {
  AdminPanelSettingsOutlined,
  GroupAddOutlined,
  ImportExportOutlined,
  ListAltOutlined,
  ViewComfyOutlined,
} from "@mui/icons-material";

export const DETAIL_MENU_LIST = [
  {
    label: "Admin",
    value: "admin",
    icon: AdminPanelSettingsOutlined,
    sub_menu: [
      {
        label: "Settings",
        value: "settings",
      },
    ],
  },
  {
    label: "Lease Details",
    value: "lease_details",
    icon: ListAltOutlined,
    sub_menu: [
      {
        label: "Property",
        value: "property",
      },
      {
        label: "Spaces",
        value: "space",
      },
      {
        label: "Key Dates & Options",
        value: "key_dates",
      },
      {
        label: "Rent & Expenses",
        value: "rent_expenses",
      },
      {
        label: "Contacts",
        value: "contacts",
      },
      {
        label: "Additional Info",
        value: "additional_info",
      },
      // {
      //   label: "Documents",
      //   value: "additional_documents",
      // },
    ],
  },
  {
    label: "PDF Layout",
    icon: ViewComfyOutlined,
    value: "page_reorder",
    isRoot: true,
  },
  {
    label: "Collaborators",
    value: "collaborators",
    icon: GroupAddOutlined,
    isRoot: true,
  },
];

export const BASE_RENT_COLUMNS = [
  {
    label: "Date",
    id: "date",
  },
  {
    label: "Months",
    id: "months",
  },
  {
    label: "$/RSF/Year",
    id: "Rent PSF",
  },
  {
    label: "Monthly Cost",
    id: "Monthly Cost",
  },
  {
    label: "Annual Cost",
    id: "Annual Cost",
  },
];
