import {
  ArrowCircleLeftOutlined,
  ArrowCircleRightOutlined,
  CloseOutlined,
  OpenInFullOutlined,
  RemoveOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@mui/icons-material";
import { Button } from "ui-atoms";
import cn from "classnames";
import { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "context";
import { SET_LEASE_PDF, SET_LEASE_PDF_CONTENT } from "constant";
import { getDocFileAPI } from "services";
import { useApiCall } from "hooks";
import { useParams } from "react-router-dom";
import { Loading } from "ui-molecules";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`;

const LeasePDFViewer = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const [getDocFile] = useApiCall(getDocFileAPI);
  const { closeoutId } = useParams();
  const { leasePDFViewerOpen, leasePDFViewerContent, detailInfo } = state;
  const [numPages, setNumPages] = useState<any>(null);
  const scaleRef = useRef(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const canvasRef = useRef<any>(null);
  const layerRef = useRef<any>(null);
  const [isFirst, setIsFirst] = useState(true);
  const [currentPage, setCurrentPage] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [height, setHeight] = useState(0);
  let timeout: any;

  useEffect(() => {
    setTimeout(() => {
      setIsFirst(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (!canvasRef?.current) return;
    setHeight(canvasRef?.current?.offsetHeight);
  }, [canvasRef]);

  useEffect(() => {
    if (!detailInfo?.files?.length || leasePDFViewerOpen < 1) return;
    if (
      !leasePDFViewerContent?.fileData ||
      closeoutId !== leasePDFViewerContent?.closeoutId
    )
      fetchFile();
  }, [leasePDFViewerOpen, leasePDFViewerContent]);

  useEffect(() => {
    if (!layerRef?.current) return;
    layerRef?.current?.addEventListener("mousemove", handleMouseMove);
    return () =>
      layerRef?.current?.removeEventListener("mousemove", handleMouseMove);
  }, [layerRef]);

  const fetchFile = async () => {
    try {
      setIsLoading(true);
      let props: any = {
        doc_id: detailInfo?.files?.[0]?.DocID,
      };
      if (detailInfo?.is_lease_abstracted)
        props = {
          ...props,
          highlight: true,
        };
      const data = await getDocFile({
        id: closeoutId,
        props,
      });
      if (!data) {
        setIsLoading(false);
        return;
      }
      const fileData = new Blob([data]);
      dispatch({
        type: SET_LEASE_PDF_CONTENT,
        payload: {
          closeoutId,
          fileData,
        },
      });
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    dispatch({
      type: SET_LEASE_PDF_CONTENT,
      payload: null,
    });
    dispatch({
      type: SET_LEASE_PDF,
      payload: 0,
    });
  };

  const handleMinimum = () => {
    dispatch({
      type: SET_LEASE_PDF,
      payload: 2,
    });
  };

  const handleExpand = () => {
    dispatch({
      type: SET_LEASE_PDF,
      payload: 3,
    });
  };

  const handleZoomIn = () => {
    scaleRef.current = scaleRef.current + 0.1;
    layerRef.current.style.transform = `translate(${position.x}px, ${position.y}px) scale(${scaleRef.current})`;
    layerRef.current.style.transformOrigin = "top left";
  };

  const handleZoomOut = () => {
    scaleRef.current = Math.max(0.1, scaleRef.current - 0.1);
    layerRef.current.style.transform = `translate(${position.x}px, ${position.y}px) scale(${scaleRef.current})`;
    layerRef.current.style.transformOrigin = "top left";
  };

  const handlePan = (deltaX: number, deltaY: number) => {
    setPosition((prevPosition) => ({
      x: prevPosition.x + deltaX,
      y: prevPosition.y + deltaY,
    }));
  };

  const handleMouseMove = (e: any) => {
    if (e.buttons === 1) {
      const deltaX = e.movementX;
      const deltaY = e.movementY;
      handlePan(deltaX, deltaY);
    }
  };

  const handleWheel = (event: React.WheelEvent<HTMLCanvasElement>) => {
    const deltaY = event.deltaY;
    const deltaScale = deltaY < 0 ? -0.1 : 0.1;
    if (deltaScale < 0 && currentPage === 1) return;
    if (deltaScale > 0 && currentPage === numPages) return;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setCurrentPage((prev: number) => (deltaScale < 0 ? prev - 1 : prev + 1));
    }, 50);
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    if (currentPage < 0 && numPages > 0) {
      setCurrentPage(1);
    }
    setNumPages(numPages);
  };

  return (
    <div
      className={cn(
        "fixed bottom-0 z-[25] bg-white shadow-pdfViwer border-l border-t border-jll-stroke-default flex flex-col",
        {
          "animate-pdfViwerOpen right-0 w-[850px] h-full":
            leasePDFViewerOpen === 1,
          "animate-pdfViwerClose -right-[850px] h-full w-[850px]":
            leasePDFViewerOpen === 0,
          "animate-pdfViwerMinimum right-0 w-[550px] h-[80px] bottom-0 top-auto":
            leasePDFViewerOpen === 2,
          "animate-pdfViwerExpand right-0 w-[850px] bottom-0 h-full":
            leasePDFViewerOpen === 3,
          "!-right-[850px]": isFirst,
        },
      )}
    >
      {/* Header */}
      <section className="w-full min-h-[80px] flex flex-row justify-between items-center relative pl-10 pr-5 bg-jll-surface-base-secondary border-b border-b-jll-stroke-default">
        <span className="text-xl text-jll-text-base-default z-[2]">
          Executed Lease
        </span>
        {leasePDFViewerOpen !== 2 && numPages && (
          <span className="absolute left-0 right-0 text-center text-xl text-jll-text-base-default z-[1]">
            {currentPage > 0 ? currentPage : ""}/{numPages}
          </span>
        )}
        <div className="flex z-[2]">
          {(leasePDFViewerOpen === 3 || leasePDFViewerOpen === 1) && (
            <Button
              variant="neutral"
              size="medium"
              leadingIcon={RemoveOutlined}
              leadingIconClass="!w-6 !h-6"
              onClick={handleMinimum}
            />
          )}
          {leasePDFViewerOpen === 2 && (
            <Button
              variant="neutral"
              size="medium"
              leadingIcon={OpenInFullOutlined}
              leadingIconClass="!w-6 !h-6"
              onClick={handleExpand}
            />
          )}
          <Button
            variant="neutral"
            size="medium"
            leadingIcon={CloseOutlined}
            leadingIconClass="!w-6 !h-6"
            onClick={handleClose}
          />
        </div>
      </section>
      {/* Body */}
      <section className="h-full flex flex-row" onWheel={handleWheel}>
        <button
          className={cn(
            "w-[100px] h-full flex justify-center items-center z-[1] text-jll-text-base-default",
            {
              "cursor-not-allowed !text-jll-color-text-disabled":
                currentPage <= 1,
            },
          )}
          disabled={currentPage <= 1 || isLoading}
          onClick={() => setCurrentPage((prev: number) => prev - 1)}
        >
          <ArrowCircleLeftOutlined className="w-6 h-6 " />
        </button>
        <div className="h-full w-full overflow-hidden relative" ref={canvasRef}>
          {leasePDFViewerContent?.fileData && (
            <Document
              file={URL.createObjectURL(leasePDFViewerContent?.fileData)}
              onLoadSuccess={onDocumentLoadSuccess}
              options={{
                cMapUrl: "/cmaps/",
                standardFontDataUrl: "/standard_fonts/",
              }}
            >
              <Page
                pageNumber={currentPage}
                renderTextLayer={true}
                height={height}
                canvasRef={layerRef}
              />
            </Document>
          )}
          {isLoading && (
            <div className="absolute top-0 right-0 left-0 bottom-0 flex justify-center items-center bg-white z-[1]">
              <Loading />
            </div>
          )}
        </div>
        <button
          className={cn(
            "w-[100px] h-full flex justify-center items-center z-[1] text-jll-text-base-default",
            {
              "cursor-not-allowed !text-jll-color-text-disabled":
                currentPage >= numPages,
            },
          )}
          disabled={currentPage >= numPages || isLoading}
          onClick={() => setCurrentPage((prev: number) => prev + 1)}
        >
          <ArrowCircleRightOutlined className="w-6 h-6" />
        </button>
      </section>
      {/* Footer */}
      <section className="w-full min-h-[80px] flex flex-row items-center justify-center space-x-2 bg-jll-surface-base-secondary border-t border-t-jll-stroke-default z-[1]">
        <Button
          variant="neutral"
          size="medium"
          leadingIcon={ZoomInOutlined}
          leadingIconClass="!w-6 !h-6"
          onClick={handleZoomIn}
          disabled={isLoading}
        />
        <Button
          variant="neutral"
          size="medium"
          leadingIcon={ZoomOutOutlined}
          leadingIconClass="!w-6 !h-6"
          onClick={handleZoomOut}
          disabled={scaleRef.current < 0.8 || isLoading}
        />
      </section>
    </div>
  );
};

export default LeasePDFViewer;
