import { Close, InfoOutlined } from "@mui/icons-material";
import { STATUS_IN_REVIEW, STATUS_TO_DO } from "constant";
import { Button } from "ui-atoms";
import { Modal } from "ui-molecules";

interface IStatusConfirmModal {
  isOpen: boolean;
  setIsOpen: any;
  setStatus?: any;
  selectedValue: number;
}

const StatusConfirmModal: React.FC<IStatusConfirmModal> = ({
  isOpen,
  setIsOpen,
  setStatus,
  selectedValue,
}) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="normal">
      <Modal.Body>
        <div className="flex flex-row items-start justify-between">
          <div className="flex flex-row space-x-4">
            <div className="rounded-full bg-jll-surface-base-secondary p-3 h-fit">
              <InfoOutlined className="!w-8 !h-8 text-jll-text-base-subdued" />
            </div>
            <div className="text-jll-text-base-default">
              <h3 className="text-xl mb-1">
                Change status to{" "}
                {selectedValue === STATUS_TO_DO
                  ? "To Do"
                  : selectedValue === STATUS_IN_REVIEW
                    ? "Review"
                    : "Ready"}
                ?
              </h3>
              <p>
                Lease marked ready means it is complete. Brokers might start to
                edit the lease details.
              </p>
            </div>
          </div>
          <div>
            <Button
              variant="neutral"
              onClick={() => setIsOpen(false)}
              leadingIcon={Close}
              size="large"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex flex-row space-x-3 w-full justify-end">
          <Button
            variant="secondary"
            className="w-32"
            onClick={() => {
              setIsOpen(false);
            }}
            size="medium"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="w-32"
            size="medium"
            onClick={() => {
              setStatus(selectedValue);
              setIsOpen(false);
            }}
          >
            Yes
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default StatusConfirmModal;
