import { useEffect, useState } from "react";
import queryString from "query-string";
import { useNavigate, useLocation } from "react-router-dom";
import type { FC } from "react";
import { Checkbox } from "ui-atoms";

interface FacetProps {
  label: string;
  id: string;
  count: number;
  parentId: string;
  value: string;
  pageId: string;
  defaultChecked?: boolean;
}

const Facet: FC<FacetProps> = ({ label, id, count, value, parentId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [paramFilters, setParamFilters] = useState<any>(null);

  useEffect(() => {
    const filters = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    setParamFilters(filters);
  }, [location]);

  const onChangeCheckbox = (e: any) => {
    const isChecked = e.target.checked;
    const updatedFilters = { ...paramFilters };

    if (isChecked) {
      // Add facet
      if (value?.toString()?.includes("_missing")) {
        updatedFilters[`${parentId}_missing`] = true;
      } else {
        if (!updatedFilters.hasOwnProperty(parentId)) {
          updatedFilters[parentId] = [value];
        } else if (
          !(
            updatedFilters[parentId].includes(value) ||
            updatedFilters[parentId].includes(value.toString())
          )
        ) {
          if (Array.isArray(updatedFilters[parentId])) {
            updatedFilters[parentId].push(value);
          } else {
            updatedFilters[parentId] = [updatedFilters[parentId], value];
          }
        }
      }
    } else if (!isChecked) {
      // Remove facet
      if (value?.toString()?.includes("_missing")) {
        if (updatedFilters[`${parentId}_missing`])
          delete updatedFilters[`${parentId}_missing`];
      } else {
        if (
          updatedFilters.hasOwnProperty(parentId) &&
          (updatedFilters[parentId].includes(value) ||
            updatedFilters[parentId].includes(value.toString()))
        ) {
          updatedFilters[parentId] = updatedFilters[parentId].filter(
            (x: string) => x.toString() !== value.toString(),
          );
        }
        if (
          updatedFilters.hasOwnProperty(parentId) &&
          !updatedFilters[parentId]?.length
        ) {
          delete updatedFilters[parentId];
        }
      }
    }

    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`,
    );
  };

  return (
    <div className="flex items-center justify-between space-x-2">
      <Checkbox
        label={label}
        id={id}
        onChange={onChangeCheckbox}
        boxSize="small"
        labelClassName={
          value === `${parentId}_missing`
            ? "!text-jll-text-rag-danger-accessible text-sm"
            : "!text-jll-text-base-default text-sm"
        }
        isMiss={value === `${parentId}_missing`}
        defaultChecked={
          (paramFilters?.[parentId] &&
            paramFilters?.[parentId]?.includes(value?.toString())) ||
          (paramFilters?.[`${parentId}_missing`] &&
            value === `${parentId}_missing`)
        }
        className="!space-x-2"
      />
      <div className="text-jll-text-base-subdued text-sm">{count}</div>
    </div>
  );
};

export default Facet;
