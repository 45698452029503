import { Close } from "@mui/icons-material";
import { Button } from "ui-atoms";
import { Modal } from "ui-molecules";

interface IRentClearModal {
  isOpen: boolean;
  setIsOpen: any;
  action?: any;
}

const RentClearModal: React.FC<IRentClearModal> = ({
  isOpen,
  setIsOpen,
  action,
}) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="default">
      <Modal.Header>
        <div className="flex flex-row items-center justify-between">
          <h3 className="text-xl leading-6 text-jll-text-base-default">
            Clear table?
          </h3>
          <Button
            variant="neutral"
            onClick={() => setIsOpen(false)}
            leadingIcon={Close}
            size="large"
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <p className="text-jll-text-base-subdued mt-4">
          Clearing will remove all data inputted in the table.
        </p>
      </Modal.Body>
      <Modal.Footer className="!mt-8">
        <div className="flex flex-row space-x-3 justify-end w-full">
          <Button
            variant="secondary"
            className="w-32"
            onClick={() => setIsOpen(false)}
            size="medium"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="w-32"
            onClick={() => {
              action();
              setIsOpen(false);
            }}
            size="medium"
          >
            Confirm
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default RentClearModal;
