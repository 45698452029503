import { NotificationsOutlined } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { Button, Chip, Radio, SelectAutoComplete } from "ui-atoms";
import DataSourceModal from "./DataSourceModal";
import { useContext, useEffect, useState } from "react";
import StatusConfirmModal from "./StatusConfirmModal";
import {
  SEARCH_RESULT_LIMIT,
  SET_DETAIL_INFO,
  SET_FULL_LOADING,
  SET_NO_DATA_MODAL,
  STATUS_AI_ABSTRACTION_COMPLETE,
  STATUS_IN_REVIEW,
  STATUS_READY,
  STATUS_TO_DO,
} from "constant";
import { GlobalContext } from "context";
import {
  getDocFileAPI,
  getTriggerAbstractionAPI,
  getTriggerDataAPI,
  getUserAPI,
  getUsersAPI,
  patchDetailAPI,
  postTriggerAbstractionAPI,
} from "services";
import { useApiCall } from "hooks";
import { Footer } from "ui-molecules";
import AbstractionModal from "./AbstractionModal";
import StatusAbstractionModal from "./StatusAbstractionModal";
import { getFormatedDateTime } from "utils";
import cn from "classnames";

const SettingDetail = () => {
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const { state, dispatch } = useContext(GlobalContext);
  const { detailInfo, meta } = state;
  const { closeoutId } = useParams();
  const [getDocFile] = useApiCall(getDocFileAPI);
  const [patchDetail] = useApiCall(patchDetailAPI);
  const [getUsers] = useApiCall(getUsersAPI);
  const [getUser] = useApiCall(getUserAPI);
  const [postTriggerAbstraction] = useApiCall(postTriggerAbstractionAPI);
  const [getTriggerAbstraction] = useApiCall(
    getTriggerAbstractionAPI,
    false,
    true,
  );
  const [getTriggerData] = useApiCall(getTriggerDataAPI);
  const [isOpen, setIsOpen] = useState(false);
  const [isAbstractionOpen, setIsAbstractionOpen] = useState(false);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isStatusAbstractionOpen, setIsStatusAbstractionOpen] = useState(false);
  const [statusAbstractionData, setStatusAbstractionData] = useState(null);
  const [status, setStatus] = useState(STATUS_TO_DO);
  const [pendingStatus, setPendingStatus] = useState(STATUS_TO_DO);
  const [selectedReviewer, setSelectedReviewer] = useState<any>([]);
  const [triggerStatus, setTriggerStatus] = useState<any>(null);

  useEffect(() => {
    if (!closeoutId) return;
    loadData();
  }, [closeoutId]);

  useEffect(() => {
    if (!detailInfo) return;
    setStatus(detailInfo?.status);
    if (
      detailInfo?.assignee &&
      detailInfo?.assignee?.toString() !== selectedReviewer?.value
    ) {
      getUser(detailInfo?.assignee).then((user: any) => {
        if (!user) return;
        setSelectedReviewer({
          value: user?.usermeta?.user || user?.id,
          label:
            user?.usermeta?.name ||
            user?.get_full_name ||
            user?.usermeta?.email ||
            user?.email,
        });
      });
    } else {
      setSelectedReviewer(null);
    }
  }, [detailInfo]);

  const loadData = () => {
    getTriggerAbstraction(closeoutId).then((res: any) => {
      if (!res) return;
      setTriggerStatus(res);
    });
  };

  const handleDownload = async (item: any) => {
    try {
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: true,
          label: "Downloading...",
        },
      });
      const data = await getDocFile({
        id: closeoutId,
        props: { doc_id: item?.DocID },
      });
      const fileData = new Blob([data]);
      const fileUrl = URL.createObjectURL(fileData);
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = `${item?.File_Name}`;
      link.click();
      URL.revokeObjectURL(fileUrl);
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: false,
          label: null,
        },
      });
    } catch (err) {
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: false,
          label: null,
        },
      });
    }
  };

  const handleStatus = (value: number) => {
    try {
      setStatus(value);
      patchDetail({ id: closeoutId, payload: { status: value } }).then(
        (res: any) => {
          if (!res) return;
          dispatch({
            type: SET_DETAIL_INFO,
            payload: {
              ...detailInfo,
              status: res?.status,
            },
          });
        },
      );
    } catch (err) {}
  };

  const loadReviewerOptions = async (keyword: string) => {
    const group = meta?.user_group?.find((group: any) =>
      group?.name?.toString()?.toLowerCase()?.includes("admin"),
    );
    return await getUsers({
      keyword,
      limit: SEARCH_RESULT_LIMIT,
      page: 1,
      group: [group?.id || 1],
    }).then((res: any) => {
      return res.docs.map((user: any) => {
        return {
          value: user.pk,
          label:
            user?.first_name || user?.last_name
              ? [user?.first_name, user?.last_name].join(" ")
              : user?.email,
        };
      });
    });
  };

  const handleReviewer = (option: any) => {
    try {
      setSelectedReviewer(option);
      patchDetail({
        id: closeoutId,
        payload: { assignee: option?.value || null },
      }).then((res: any) => {
        if (!res) return;
        dispatch({
          type: SET_DETAIL_INFO,
          payload: {
            ...detailInfo,
            assignee: res?.assignee,
          },
        });
      });
    } catch (err) {}
  };

  const handleTrigger = async () => {
    try {
      if (
        detailInfo?.property_type?.toString()?.toLowerCase() !== "office" ||
        !detailInfo?.files?.length
      ) {
        dispatch({
          type: SET_NO_DATA_MODAL,
          payload: {
            open: true,
            content: {
              label:
                "We currently are only abstracting office leases with valid files.",
            },
          },
        });
        return;
      }
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: true,
          label: "Triggering...",
        },
      });
      postTriggerAbstraction({ closeout: closeoutId })
        .then(async (res: any) => {
          if (!res) {
            return;
          }
          setTriggerStatus(res);
        })
        .finally(() => {
          loadData();
          dispatch({
            type: SET_FULL_LOADING,
            payload: {
              open: false,
              label: "Triggering...",
            },
          });
        });
    } catch (err) {
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: false,
          label: "Triggering...",
        },
      });
    }
  };

  return (
    <>
      <div className="relative w-full h-full">
        <section className="absolute top-0 left-0 right-0 bottom-[80px] pt-10 flex pb-4 flex-col pl-14">
          <p className="text-jll-text-base-default text-2xl mb-8">Settings</p>
          <div className="h-full overflow-y-auto">
            {detailInfo?.broker_modified && (
              <div className="md:w-6/12 w-full max-w-[650px] rounded bg-jll-color-surface-warningSubdued text-jll-color-text-warning p-4 flex flex-row items-center space-x-4 mb-8">
                <div className="p-2">
                  <NotificationsOutlined className="!w-7 !h-7" />
                </div>
                <p>
                  Broker has started to edit this lease. You cannot make any
                  changes.
                </p>
              </div>
            )}
            <div className="md:w-6/12 w-full rounded border border-jll-stroke-default max-w-[650px]">
              <div className="px-8 py-8 border-b border-b-jll-stroke-subdued">
                <p className="text-xl text-black mb-4">Lease Reviewer</p>
                <SelectAutoComplete
                  name="reviewer"
                  placeholder="Assign a Reviewer"
                  onChange={(value) => {
                    handleReviewer(value);
                  }}
                  value={selectedReviewer}
                  loadOptions={loadReviewerOptions}
                  className="!mb-0 w-6/12"
                />
              </div>

              <div className="px-8 py-8 border-b border-b-jll-stroke-subdued">
                <p className="text-xl text-black mb-2">Data source</p>
                <div className="flex flex-row items-center justify-between space-x-4 mt-8">
                  <p
                    className={cn("text-jll-text-base-default", {
                      "mb-6": triggerStatus?.status?.runtimeStatus,
                    })}
                  >
                    Dealio
                  </p>
                  <div className="">
                    <div className="flex flex-row items-center space-x-4 justify-end">
                      {detailInfo?.lease_abstraction ? (
                        <Button
                          variant="secondary"
                          size="medium"
                          onClick={() => setIsAbstractionOpen(true)}
                        >
                          AI Abstraction Data
                        </Button>
                      ) : (
                        <Button
                          variant="secondary"
                          size="medium"
                          onClick={handleTrigger}
                        >
                          Trigger AI Abstraction
                        </Button>
                      )}
                      <Button
                        variant="secondary"
                        size="medium"
                        onClick={() => setIsOpen(true)}
                      >
                        Update Source
                      </Button>
                    </div>
                    {triggerStatus?.status?.runtimeStatus && (
                      <p className="text-jll-text-base-subdued text-sm text-right mt-1">
                        Abstraction {triggerStatus?.status?.runtimeStatus}
                        {" - "}
                        {detailInfo?.is_lease_abstracted
                          ? "API Callback Complete"
                          : "API Callback Incomplete"}{" "}
                        ({getFormatedDateTime(triggerStatus?.update_timestamp)})
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="px-8 py-8 border-b border-b-jll-stroke-subdued">
                <p className="text-xl text-black mb-4">Status</p>
                <div className="flex flex-col space-y-4">
                  <Radio
                    id="to_do"
                    name="to_do"
                    value={STATUS_TO_DO}
                    checked={status === STATUS_TO_DO}
                    label="To Do"
                    description="Lease is sitting in queue"
                    onChange={(e) => {
                      if (e?.target?.checked) {
                        handleStatus(STATUS_TO_DO);
                      }
                    }}
                  />
                  <Radio
                    id="ai"
                    name="ai"
                    value={STATUS_AI_ABSTRACTION_COMPLETE}
                    checked={status === STATUS_AI_ABSTRACTION_COMPLETE}
                    label="AI Abstraction"
                    description="Lease has successfully gone through AI Abstraction"
                    onChange={(e) => {
                      if (e?.target?.checked) {
                        handleStatus(STATUS_AI_ABSTRACTION_COMPLETE);
                      }
                    }}
                  />
                  <Radio
                    id="in_review"
                    name="in_review"
                    value={STATUS_IN_REVIEW}
                    checked={status === STATUS_IN_REVIEW}
                    label="In Review"
                    description="Lease is currently being reviewed"
                    onChange={(e) => {
                      if (e?.target?.checked) {
                        handleStatus(STATUS_IN_REVIEW);
                      }
                    }}
                  />
                  <Radio
                    id="ready"
                    name="ready"
                    value={STATUS_READY}
                    checked={status === STATUS_READY}
                    label="Ready"
                    description="Lease review is complete; ready to notify deal team"
                    onChange={(e) => {
                      if (e?.target?.checked) {
                        setIsStatusOpen(true);
                        setPendingStatus(STATUS_READY);
                      }
                    }}
                  />
                </div>
              </div>

              <div className="px-8 py-8 border-b border-b-jll-stroke-subdued">
                <div className="flex flex-col space-y-4">
                  <div className="flex flex-row items-center justify-between w-full">
                    <p className="text-xl text-black">Dealio ID</p>
                    <span className="text-jll-text-base-default">
                      #{detailInfo?.dealio || ""}
                    </span>
                  </div>

                  {detailInfo?.assignment_type && (
                    <div className="flex flex-row items-center justify-between w-full">
                      <p className="text-xl text-black">Deal Type</p>
                      <Chip
                        variant={"primary"}
                        className="flex flex-row items-center"
                      >
                        {detailInfo?.assignment_type}
                      </Chip>
                    </div>
                  )}

                  {detailInfo?.assignment_sub_type && (
                    <div className="flex flex-row items-center justify-between w-full">
                      <p className="text-xl text-black">Sub Type</p>
                      <Chip
                        variant={"secondary"}
                        className="flex flex-row items-center"
                      >
                        {detailInfo?.assignment_sub_type}
                      </Chip>
                    </div>
                  )}
                </div>
              </div>

              <div className="px-8 py-8">
                <p className="text-xl text-black mb-4">Lease Document</p>
                {!!detailInfo?.files?.length && (
                  <div className="flex flex-col space-y-1">
                    {detailInfo?.files?.map((file: any, idx: number) => (
                      <a
                        type="button"
                        className="text-jll-color-text-interaction cursor-pointer"
                        onClick={() => handleDownload(file)}
                        key={idx}
                      >
                        {file?.File_Name || ""}
                      </a>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>

      <DataSourceModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <AbstractionModal
        isOpen={isAbstractionOpen}
        setIsOpen={setIsAbstractionOpen}
      />
      <StatusAbstractionModal
        isOpen={isStatusAbstractionOpen}
        setIsOpen={setIsStatusAbstractionOpen}
      />
      <StatusConfirmModal
        isOpen={isStatusOpen}
        setIsOpen={setIsStatusOpen}
        setStatus={handleStatus}
        selectedValue={pendingStatus}
      />
    </>
  );
};

export default SettingDetail;
