import { BASE_RENT_COLUMNS } from "constant";

export const reorder = (
  list: any[] = [],
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const transformObject = (obj: any) => {
  if (!obj) return;
  const result = [];
  const keys = Object.keys(obj);
  const length = obj[keys?.[0]]?.length || 0;

  for (let i = 0; i < length; i++) {
    let newObj: any = {};
    keys.forEach((key) => {
      newObj[key] = obj[key][i];
    });
    result.push(newObj);
  }

  return result;
};

export const overrideObject = (obj: any) => {
  if (!obj)
    return {
      cols: ["", "", "", "", ""],
      // rows: [...Array(20)]?.map(() => ["", "", "", "", ""]),
      rows: [],
    };
  let columns: any = [];
  let rows: any = [];
  Object.keys(obj).forEach((key, idx) => {
    columns.push(obj?.[key]?.[0] || "");

    Object.keys(obj?.[key]).forEach((key1, idx1) => {
      if (key1?.toString() === "0") return;
      if (!rows[Number(idx1)]) rows[Number(idx1)] = [];
      rows[Number(idx1)][Number(idx)] = obj?.[key]?.[key1];
    });
  });
  return { cols: columns, rows };
};
