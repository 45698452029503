import {
  DeleteOutlineOutlined,
  FileUploadOutlined,
  Search,
} from "@mui/icons-material";
import { SET_DETAIL_INFO, SET_ERROR_MODAL, SET_FULL_LOADING } from "constant";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import { useContext, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useParams } from "react-router-dom";
import {
  getPropertiesAPI,
  patchDetailAPI,
  postImageAPI,
  postPropertyAPI,
} from "services";
import {
  Button,
  Dropzone,
  Input,
  InputSearch,
  MapView,
  Select,
  Textarea,
} from "ui-atoms";
import { Footer } from "ui-molecules";
import { checkFileType, getMetaOptions, lazyLoadImageOnError } from "utils";
import * as Yup from "yup";

const INIT_PROPERTY = {
  building_name: "",
  building_address: "",
  building_city: "",
  building_zip: "",
  building_state: "",
  building_images: [],
  building_size: "",
  // building_comments: "",
  building_latitude: "",
  building_longitude: "",
};

const validationSchema = Yup.object().shape({
  building_size: Yup.number()
    .nullable()
    .optional()
    .max(99999999, "That can't be over than 100,000,000"),
});

const PropertyDetail = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { meta, detailInfo } = state;
  const { closeoutId } = useParams();
  const [getProperties] = useApiCall(getPropertiesAPI);
  const [postProperty] = useApiCall(postPropertyAPI);
  const [patchDetail] = useApiCall(patchDetailAPI);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIsLoading, setSelectedIsLoading] = useState<string[]>([]);

  useEffect(() => {
    if (detailInfo?.id?.toString() !== closeoutId?.toString()) return;
    let formValues: any = {};
    Object.keys(INIT_PROPERTY).forEach((key: string) => {
      formValues = {
        ...formValues,
        [key]: detailInfo?.[key] || "",
      };
    });
    setValues({ ...formValues });
  }, [detailInfo]);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: INIT_PROPERTY,
    validationSchema,
    onSubmit: async () => {},
  });

  const handleSubmitImg = async (files: any) => {
    try {
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: true,
          label: "Uploading Image...",
        },
      });
      let results: any[] = [...values?.building_images];
      if (files?.length > 0) {
        await Promise.all(
          files?.map(async (file: any) => {
            const result = await postImageAPI(file);
            if (result) {
              results.unshift(result);
            }
          }),
        );
      }

      patchDetail({ id: closeoutId, payload: { building_images: results } })
        .then((res: any) => {
          if (!res) return;
          dispatch({
            type: SET_DETAIL_INFO,
            payload: {
              ...detailInfo,
              building_images: res?.building_images,
            },
          });
        })
        .finally(() => {
          dispatch({
            type: SET_FULL_LOADING,
            payload: {
              open: false,
              label: "",
            },
          });
        });
    } catch (err) {
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: false,
          label: "",
        },
      });
    }
  };

  const handleDetail = (names: string[], values: any[], e?: any) => {
    try {
      if (names?.length === 1 && values?.[0] === detailInfo?.[names?.[0]])
        return;
      let payload = {};
      names?.forEach((name, idx) => {
        payload = {
          ...payload,
          [name]: values[idx] || null,
        };
      });
      setSelectedIsLoading(names);
      patchDetail({ id: closeoutId, payload })
        .then((res: any) => {
          if (!res) return;
          let updated = {};
          names?.forEach((name, idx) => {
            updated = {
              ...payload,
              [name]: res?.[name] || null,
            };
          });
          dispatch({
            type: SET_DETAIL_INFO,
            payload: {
              ...detailInfo,
              ...updated,
            },
          });
        })
        .finally(() => {
          setSelectedIsLoading([]);
        });
    } catch (err) {
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: false,
          label: "",
        },
      });
    }
  };

  const onSelectBuilding = (item: any) => {
    setIsLoading(true);
    postProperty({
      lease_closeout_id: Number(closeoutId),
      property_id: item?.resultId,
    })
      .then((res: any) => {
        if (!res) return;
        let formValues: any = {};
        let updatedValues: any[] = [];
        Object.keys(INIT_PROPERTY).forEach((key: string) => {
          formValues = {
            ...formValues,
            [key]: res?.[key] || "",
          };
          updatedValues.push(res?.[key] || "");
        });
        setValues({ ...formValues });
        handleDetail(Object.keys(formValues), updatedValues);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="relative w-full h-full">
      <section className="absolute top-0 left-0 right-0 bottom-[80px] pt-10 flex pb-4 flex-col pl-14">
        <p className="text-jll-text-base-default text-2xl mb-8 pr-14">
          Property
        </p>
        <div className="h-full flex lg:flex-row md:flex-col overflow-y-auto pr-14">
          <div className="lg:w-6/12 w-full lg:pr-16 lg:border-r lg:border-r-jll-stroke-default h-fit">
            <p className="text-jll-text-base-subdued mb-2">Property Image</p>

            {!values?.building_images?.length && (
              <div className="flex flex-row items-end space-x-8 mb-8 h-[180px]">
                <Dropzone
                  className="min-w-[180px] min-h-[180px] h-[180px] bg-jll-surface-base-secondary-subdued"
                  setDropFiles={async (files: any) => {
                    const fileType = await checkFileType(files[0]);

                    if (
                      !fileType?.includes("image/jpeg") &&
                      !fileType?.includes("image/png")
                    ) {
                      dispatch({
                        type: SET_ERROR_MODAL,
                        payload: {
                          open: true,
                          content: {
                            label: "Warning",
                            value:
                              "File not accepted. Only PNG and JPEG files can be rendered in the deliverable. Please upload a PNG or JPEG. ",
                          },
                        },
                      });
                      return;
                    }
                    handleSubmitImg(files);
                  }}
                  acceptType={{ "image/*": [".png", ".jpg", ".jpeg"] }}
                  maxFileCount={1}
                >
                  <div className="flex flex-col justify-center items-center w-[130px]">
                    <FileUploadOutlined className="!w-8 !h-8 text-jll-color-text-interaction mb-2" />
                    <p className="text-jll-text-base-subdued text-sm text-center">
                      Drag an image here, or click to browse
                    </p>
                  </div>
                </Dropzone>
                <div className="flex flex-col h-full">
                  <p className="text-jll-text-base-subdued">
                    Use jpg or png format smaller than 2MB
                  </p>
                </div>
              </div>
            )}

            {!!values?.building_images?.length && (
              <div className="flex flex-row items-end space-x-8 mb-8 h-[180px]">
                <LazyLoadImage
                  className="w-[180px] h-[180px] rounded object-contain"
                  src={values?.building_images[0] || ""}
                  onError={lazyLoadImageOnError}
                />
                <div className="flex flex-col h-full justify-between">
                  <p className="text-jll-text-base-subdued">
                    Use jpg or png format smaller than 2MB
                  </p>
                  <div className="flex flex-col space-y-2 w-fit">
                    <Dropzone
                      divClassName="!border-none"
                      setDropFiles={async (files: any) => {
                        const fileType = await checkFileType(files[0]);

                        if (
                          !fileType?.includes("image/jpeg") &&
                          !fileType?.includes("image/png")
                        ) {
                          dispatch({
                            type: SET_ERROR_MODAL,
                            payload: {
                              open: true,
                              content: {
                                label: "Warning",
                                value:
                                  "File not accepted. Only PNG and JPEG files can be rendered in the deliverable. Please upload a PNG or JPEG. ",
                              },
                            },
                          });
                          return;
                        }
                        handleSubmitImg(files);
                      }}
                      acceptType={{ "image/*": [".png", ".jpg", ".jpeg"] }}
                      maxFileCount={1}
                    >
                      <Button
                        variant="secondary"
                        leadingIcon={FileUploadOutlined}
                        size="medium"
                      >
                        Add Image
                      </Button>
                    </Dropzone>
                    <Button
                      variant="secondary"
                      className="flex flex-row justify-center"
                      leadingIcon={DeleteOutlineOutlined}
                      size="medium"
                      onClick={() => {
                        const arr = [...values?.building_images];
                        arr?.shift();
                        handleDetail(["building_images"], [arr]);
                      }}
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              </div>
            )}

            <InputSearch
              placeholder="Search a building address"
              leadingIcon={Search}
              className="!mb-4"
              serviceApi={getProperties}
              onChange={(item: any) => onSelectBuilding(item)}
            />

            <Input
              name="building_name"
              label="Building Name"
              className="!mb-4"
              onChange={handleChange}
              error={touched.building_name ? errors.building_name : ""}
              value={values?.building_name}
              isLoading={
                isLoading || selectedIsLoading?.includes("building_name")
              }
              onBlur={(e) => {
                handleBlur(e);
                handleDetail(["building_name"], [values?.building_name], e);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleDetail(["building_name"], [values?.building_name]);
                }
              }}
            />

            <div className="flex flex-row">
              <Input
                name="building_address"
                label="Address"
                className="w-full !mb-4"
                onChange={handleChange}
                error={touched.building_address ? errors.building_address : ""}
                value={values?.building_address}
                isLoading={
                  isLoading || selectedIsLoading?.includes("building_address")
                }
                onBlur={(e) => {
                  handleBlur(e);
                  handleDetail(
                    ["building_address"],
                    [values?.building_address],
                    e,
                  );
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleDetail(
                      ["building_address"],
                      [values?.building_address],
                    );
                  }
                }}
              />
            </div>

            <div className="flex flex-row space-x-6">
              <Input
                name="building_city"
                label="City"
                className="w-full !mb-4"
                onChange={handleChange}
                error={touched.building_city ? errors.building_city : ""}
                value={values?.building_city}
                isLoading={
                  isLoading || selectedIsLoading?.includes("building_city")
                }
                onBlur={(e) => {
                  handleBlur(e);
                  handleDetail(["building_city"], [values?.building_city], e);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleDetail(["building_city"], [values?.building_city]);
                  }
                }}
              />
              <Select.Single
                options={
                  getMetaOptions(meta?.leasecloseout?.building_state) || []
                }
                label="State"
                name="building_state"
                divClassName="w-full !mb-4"
                onBlur={(e) => {
                  handleBlur(e);
                }}
                onChange={(value: any) => {
                  if (!value) {
                    setFieldValue("building_state", null);
                    handleDetail(["building_state"], [null]);
                  } else {
                    setFieldValue("building_state", value);
                    handleDetail(["building_state"], [value]);
                  }
                }}
                align="top"
                disabled={
                  isLoading || selectedIsLoading?.includes("building_state")
                }
                selectedOption={
                  values?.building_state
                    ? {
                        label:
                          meta?.leasecloseout?.building_state?.[
                            values?.building_state
                          ]?.value,
                        value: values?.building_state,
                      }
                    : undefined
                }
              />
              <Input
                name="building_zip"
                label="Zip"
                className="w-full !mb-4"
                onChange={handleChange}
                error={touched.building_zip ? errors.building_zip : ""}
                value={values?.building_zip}
                isLoading={
                  isLoading || selectedIsLoading?.includes("building_zip")
                }
                onBlur={(e) => {
                  handleBlur(e);
                  handleDetail(["building_zip"], [values?.building_zip], e);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleDetail(["building_zip"], [values?.building_zip]);
                  }
                }}
              />
            </div>
          </div>
          <div className="lg:w-6/12 w-full lg:pl-16">
            <Input
              label="Building Size (SF)"
              className="!mb-4"
              name="building_size"
              onChange={(e: any) => {
                const inputValue = e?.target?.value?.replaceAll(",", "");
                const regex = /^[0-9\b]+$/;
                if (regex.test(inputValue) || inputValue === "") {
                  setFieldValue("building_size", Number(inputValue));
                }
              }}
              error={touched.building_size ? errors.building_size : ""}
              value={values?.building_size}
              isComma
              isLoading={
                isLoading || selectedIsLoading?.includes("building_size")
              }
              onBlur={(e) => {
                handleBlur(e);
                if (Number(values?.building_size) >= 100000000) return;
                handleDetail(["building_size"], [values?.building_size], e);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (Number(values?.building_size) >= 100000000) return;
                  handleDetail(["building_size"], [values?.building_size]);
                }
              }}
            />

            <div className="w-full h-[350px] mb-4">
              <MapView
                lat={detailInfo?.building_latitude}
                lng={detailInfo?.building_longitude}
              />
            </div>

            <div className="flex flex-row space-x-3">
              <Input
                name="building_latitude"
                label="Latitude"
                className="w-6/12 !mb-4"
                onChange={handleChange}
                error={
                  touched.building_latitude ? errors.building_latitude : ""
                }
                value={values?.building_latitude}
                isLoading={
                  isLoading || selectedIsLoading?.includes("building_latitude")
                }
                onBlur={(e) => {
                  handleBlur(e);
                  handleDetail(
                    ["building_latitude"],
                    [values?.building_latitude],
                    e,
                  );
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleDetail(
                      ["building_latitude"],
                      [values?.building_latitude],
                    );
                  }
                }}
              />
              <Input
                name="building_longitude"
                label="Longitude"
                className="w-6/12 !mb-4"
                onChange={handleChange}
                error={
                  touched.building_longitude ? errors.building_longitude : ""
                }
                value={values?.building_longitude}
                isLoading={
                  isLoading || selectedIsLoading?.includes("building_longitude")
                }
                onBlur={(e) => {
                  handleBlur(e);
                  handleDetail(
                    ["building_longitude"],
                    [values?.building_longitude],
                    e,
                  );
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleDetail(
                      ["building_longitude"],
                      [values?.building_longitude],
                    );
                  }
                }}
              />
            </div>
            {/* <Textarea
              label="Comments"
              placeholder="Type additional information, i.e. amenities"
              name="building_comments"
              isLoading={
                isLoading || selectedIsLoading?.includes("building_comments")
              }
              onChange={handleChange}
              error={touched.building_comments ? errors.building_comments : ""}
              value={values?.building_comments}
              onBlur={(e) => {
                handleBlur(e);
                handleDetail(
                  ["building_comments"],
                  [values?.building_comments],
                  e,
                );
              }}
            /> */}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default PropertyDetail;
