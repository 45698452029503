import { useEffect, useContext } from "react";
import type { FC } from "react";
import { useApiCall } from "hooks";
import { GlobalContext } from "context";
import { SET_META } from "constant";
import { getMetaAPI } from "services";
import { ConfirmModal } from "ui-molecules";

interface LayoutBaseProps {
  children: any;
}

const LayoutBase: FC<LayoutBaseProps> = ({ children }) => {
  const { dispatch, state } = useContext(GlobalContext);
  const { meta } = state;
  const [getMeta] = useApiCall(getMetaAPI);

  useEffect(() => {
    if (!!meta) return;
    getMeta().then((response: any) => {
      dispatch({
        type: SET_META,
        payload: response,
      });
    });
  }, []);

  return (
    <>
      {children}
      <ConfirmModal />
    </>
  );
};

export default LayoutBase;
