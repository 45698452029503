import {
  AddOutlined,
  CalculateOutlined,
  DeleteOutlineOutlined,
  EditOutlined,
} from "@mui/icons-material";
import { Button } from "ui-atoms";
import {
  CalculatorModal,
  Footer,
  Loading,
  StyledKeyDateValue,
  StyledKeyDateValueProps,
  Table,
} from "ui-molecules";
import KeyDateModal from "./KeyDateModal";
import { useContext, useEffect, useRef, useState } from "react";
import { useApiCall } from "hooks";
import { deleteKeyDateAPI, getKeyDatesAPI } from "services";
import {
  INIT_KEY_DATA,
  KEY_DATE_COLUMNS,
  SEARCH_RESULT_LIMIT,
  SET_FULL_LOADING,
} from "constant";
import { useParams } from "react-router-dom";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { KeyDateProps } from "types";
import { GlobalContext } from "context";
import DateModal from "./DateModal";

const KeyDateDetail = () => {
  const { dispatch, state } = useContext(GlobalContext);
  const { detailInfo } = state;
  const { closeoutId } = useParams();
  const calcRef = useRef<any>(null);
  const tableRef = useRef<any>(null);
  const [getKeyDates, isLoading] = useApiCall(getKeyDatesAPI);
  const [deleteKeyDate] = useApiCall(deleteKeyDateAPI);
  const [isOpen, setIsOpen] = useState(false);
  const [isDateOpen, setIsDateOpen] = useState(false);
  const [selectedItem, setSeletectedItem] = useState(null);
  const [selectedId, setSelectedId] = useState(undefined);
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setPage(1);
    if (tableRef?.current) {
      tableRef.current.scrollTop = 0;
    }
    getKeyDates({
      lease_closeout: closeoutId,
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      active_status: [1],
    })
      .then((res: any) => {
        if (!res) return;
        setData(res?.docs);
        setPage(res?.page);
        setTotal(res?.total);
      })
      .catch(() => {
        setData([]);
        setTotal(0);
      });
  }, []);

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    getKeyDates({
      lease_closeout: closeoutId,
      page: nextPage,
      limit: SEARCH_RESULT_LIMIT,
      active_status: [1],
    }).then((res: any) => {
      if (!res) return;
      setData((prevData: KeyDateProps[]) => [...prevData, ...res?.docs]);
      setPage(res?.page);
      setTotal(res?.total);
    });
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: total > data?.length,
    onLoadMore: loadMore,
  });

  const handleRemove = (pk: number) => {
    try {
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: true,
          label: "Deleting...",
        },
      });
      deleteKeyDate(pk)
        .then((res: any) => {
          const new_data = [...data]?.filter((item) => item?.pk !== pk);
          setData(new_data);
        })
        .finally(() => {
          dispatch({
            type: SET_FULL_LOADING,
            payload: {
              open: false,
              label: "",
            },
          });
        });
    } catch (err) {
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: false,
          label: "",
        },
      });
    }
  };

  return (
    <>
      <div className="relative w-full h-full">
        <section className="absolute top-0 left-0 right-0 bottom-[80px] pt-10 flex flex-col px-14">
          <div className="flex flex-row items-center justify-between mb-8">
            <span className="text-jll-text-base-default text-2xl">
              Key Dates & Options
            </span>
            <div className="flex flex-row items-center space-x-4">
              <Button
                variant="neutral"
                leadingIcon={CalculateOutlined}
                size="medium"
                onClick={() => calcRef.current.openCalc()}
              >
                Date Calculator
              </Button>
              <Button
                variant="secondary"
                size="medium"
                leadingIcon={AddOutlined}
                onClick={() => {
                  setIsOpen(true);
                  setSeletectedItem(null);
                }}
              >
                Add Lease Option
              </Button>
            </div>
          </div>
          <div className="h-full -mx-14 overflow-y-auto" ref={tableRef}>
            <Table className="mb-2">
              <Table.Thead>
                <Table.Tr>
                  {KEY_DATE_COLUMNS.map((column: any, idx: number) => (
                    <Table.Th
                      key={idx}
                      itemClassName={idx === 0 ? "!pl-14" : ""}
                    >
                      {column.label}
                    </Table.Th>
                  ))}
                  <Table.Th itemClassName="!pr-14" />
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {[...INIT_KEY_DATA, ...(data || [])]?.map((item, idx) => (
                  <Table.Tr key={idx}>
                    {KEY_DATE_COLUMNS.map((column, idx1: number) => (
                      <Table.Td
                        key={idx1}
                        className={
                          idx1 === 0 ? "!pl-14 align-top" : "align-top"
                        }
                      >
                        <StyledKeyDateValue
                          keyDate={item}
                          valueKey={column?.id}
                          detail={detailInfo}
                          index={idx}
                        />
                      </Table.Td>
                    ))}
                    <Table.Td className="!pr-14 text-right align-top">
                      <Button
                        variant="neutral"
                        kind="icon"
                        leadingIcon={EditOutlined}
                        onClick={() => {
                          if (
                            item?.id === "commencement_date" ||
                            item?.id === "expiration_date" ||
                            item?.id === "execution_date"
                          ) {
                            setIsDateOpen(true);
                            setSelectedId(item?.id);
                          } else {
                            setIsOpen(true);
                            setSeletectedItem(item);
                          }
                        }}
                      />
                      {item?.id !== "commencement_date" &&
                        item?.id !== "expiration_date" &&
                        item?.id !== "execution_date" && (
                          <Button
                            variant="neutral"
                            kind="icon"
                            leadingIcon={DeleteOutlineOutlined}
                            onClick={() => handleRemove(item?.pk)}
                          />
                        )}
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
            {!!(total > data?.length) && (
              <div
                className="w-full py-2 flex justify-center items-center"
                ref={sentryRef}
              >
                <Loading />
              </div>
            )}
          </div>
        </section>
        <Footer />
      </div>
      <KeyDateModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        content={selectedItem}
        data={data}
        setData={setData}
      />

      <DateModal
        isOpen={isDateOpen}
        setIsOpen={setIsDateOpen}
        selectedId={selectedId}
      />
      <CalculatorModal ref={calcRef} />
    </>
  );
};

export default KeyDateDetail;
