import { DragIndicatorOutlined } from "@mui/icons-material";
import {
  ADDITIONAL_CONTACT,
  CONTENT,
  COVER,
  FLOORPLAN,
  KEY_DATES,
  LEASE_OVERVIEW,
  RENT_EXPENSES,
  SEARCH_RESULT_LIMIT,
  SET_DETAIL_INFO,
  SET_FULL_LOADING,
  TEAM,
} from "constant";
import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import {
  getDetailAPI,
  getKeyDatesAPI,
  patchDeliverableAPI,
  postDeliverableAPI,
} from "services";
import { Button, Checkbox } from "ui-atoms";
import { reorder } from "utils";
import cn from "classnames";
import LeaseOverviewOrderModal from "./LeaseOverviewOrderModal";

const DragItem = ({
  provided,
  item,
  handleSelect,
  detailInfo,
  keyData,
  setIsOpen,
}: any) => {
  let isNoData = false;
  switch (item?.value) {
    case RENT_EXPENSES:
      if (!detailInfo?.rent) isNoData = true;
      break;

    case FLOORPLAN:
      if (
        !detailInfo?.spaces?.filter(
          (space: any) =>
            space?.active_status === 1 && !!space?.floorplan?.length,
        )?.length
      )
        isNoData = true;
      break;

    case KEY_DATES:
      if (
        !detailInfo?.commencement_date_description &&
        !detailInfo?.expiration_date_description &&
        !detailInfo?.execution_date_description &&
        !keyData?.length
      ) {
        isNoData = true;
      }
      break;

    case ADDITIONAL_CONTACT:
      if (!detailInfo?.contacts || !detailInfo?.contacts?.length) {
        isNoData = true;
      }
      break;
  }
  return (
    <div
      className="py-4 px-6 bg-white rounded border border-jll-stroke-subdued flex flex-row items-center justify-between"
      ref={provided.innerRef}
      {...provided.draggableProps}
    >
      <div className="flex flex-row items-center space-x-6">
        {!isNoData && item?.isDragable && item?.isChecked ? (
          <div className="w-6 h-6" {...provided.dragHandleProps}>
            <DragIndicatorOutlined className="!w-full !h-full text-jll-text-base-subdued" />
          </div>
        ) : (
          <div className="w-6 h-6" />
        )}
        <span
          className={cn({
            "text-jll-text-base-default": !isNoData,
            "text-jll-text-base-subdued": isNoData,
          })}
        >{`${item?.label}${isNoData ? " (No Data)" : ""}`}</span>
      </div>
      {!isNoData && (
        <div className="flex flex-row space-x-6">
          {LEASE_OVERVIEW === item?.value && (
            <Button
              variant="secondary"
              size="medium"
              disabled={!item?.isDragable}
              onClick={() => setIsOpen(true)}
            >
              Edit
            </Button>
          )}
          {!item?.isDragable ? (
            <Checkbox id={""} checked disabled />
          ) : (
            <Checkbox
              id={`item-${item?.value}`}
              checked={item?.isChecked}
              onChange={(e: any) => handleSelect(item?.value, e.target.checked)}
            />
          )}
        </div>
      )}
    </div>
  );
};

const PageReorderDetail = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { meta } = state;
  const [postDeliverable] = useApiCall(postDeliverableAPI);
  const [patchDeliverable] = useApiCall(patchDeliverableAPI);
  const [getKeyDates] = useApiCall(getKeyDatesAPI);
  const [getDetail] = useApiCall(getDetailAPI);
  const { closeoutId } = useParams();
  const [itemList, setItemList] = useState<any>([]);
  const [keyData, setKeyData] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!closeoutId) {
      return;
    }
    dispatch({
      type: SET_FULL_LOADING,
      payload: {
        open: true,
        label: "Loading Detail",
      },
    });
    getDetail(closeoutId)
      ?.then((res: any) => {
        if (!res) {
          dispatch({
            type: SET_DETAIL_INFO,
            payload: null,
          });
          return;
        }
        setData(res);
        dispatch({
          type: SET_DETAIL_INFO,
          payload: res,
        });
      })
      .finally(() => {
        dispatch({
          type: SET_FULL_LOADING,
          payload: {
            open: false,
            label: "",
          },
        });
      });
    getKeyDates({
      lease_closeout: data?.id,
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      active_status: [1],
    }).then((res: any) => {
      if (!res) return;
      setKeyData(res?.docs);
    });
  }, [closeoutId]);

  useEffect(() => {
    if (!meta || !data) return;
    if (!data?.deliverable || !data?.deliverable?.pages) {
      let pages = meta?.leasecloseoutdeliverable?.pages;

      const sortedPages = Object.keys(pages)
        .map((key) => ({
          key: Number(key),
          value: pages[key].value,
          sort: pages[key].sort,
        }))
        .sort((a, b) => a.sort - b.sort);

      const init_options = sortedPages.map((page) => ({
        value: page.key,
        label: page.value,
        isChecked: true,
        isDragable: true,
      }));

      setItemList(init_options);
      return;
    }
    let options = Object.keys(meta?.leasecloseoutdeliverable?.pages).map(
      (key) => {
        return {
          value: Number(key),
          label: meta?.leasecloseoutdeliverable?.pages[key].value,
          isChecked: data?.deliverable?.pages?.includes(Number(key))
            ? true
            : false,
          isDragable: true,
        };
      },
    );
    if (!!data?.deliverable?.pages?.length) {
      const checkedOptions = options?.filter((item) => item?.isChecked);
      const unCheckedOptions = options?.filter((item) => !item?.isChecked);

      checkedOptions.sort((a: any, b: any) => {
        const indexA = data?.deliverable?.pages.indexOf(a.value);
        const indexB = data?.deliverable?.pages.indexOf(b.value);
        return indexA - indexB;
      });
      setItemList([...checkedOptions, ...unCheckedOptions]);
    } else {
      setItemList(options);
    }
  }, [data]);

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const newOrder = reorder(
      [...itemList],
      result?.source.index,
      result?.destination.index,
    );

    try {
      setItemList(newOrder);
      if (!!data?.deliverable)
        patchDeliverable({
          id: data?.id,
          payload: {
            pages: newOrder
              ?.filter((item) => item?.isChecked)
              ?.map((item) => Number(item?.value)) || [
              COVER,
              CONTENT,
              TEAM,
              LEASE_OVERVIEW,
              RENT_EXPENSES,
              FLOORPLAN,
              KEY_DATES,
              ADDITIONAL_CONTACT,
            ],
            lease_closeout: closeoutId,
            cover_style: data?.deliverable?.cover_style || 1,
          },
        }).then((res: any) => {
          if (!res) return;
          dispatch({
            type: SET_DETAIL_INFO,
            payload: {
              ...data,
              deliverable: res,
            },
          });
        });
      else
        postDeliverable({
          payload: {
            pages: newOrder
              ?.filter((item) => item?.isChecked)
              ?.map((item) => Number(item?.value)) || [
              COVER,
              CONTENT,
              TEAM,
              LEASE_OVERVIEW,
              RENT_EXPENSES,
              FLOORPLAN,
              KEY_DATES,
              ADDITIONAL_CONTACT,
            ],
            lease_closeout: closeoutId,
            cover_style: data?.deliverable?.cover_style || 1,
          },
        }).then((res: any) => {
          if (!res) return;
          dispatch({
            type: SET_DETAIL_INFO,
            payload: {
              ...data,
              deliverable: res,
            },
          });
        });
    } catch (err) {}
  };

  const handleSelect = (id: number, isChecked: boolean) => {
    try {
      const new_itemList = [...itemList];
      const index = new_itemList?.findIndex((item: any) => item?.value === id);
      if (index > -1) {
        new_itemList[index].isChecked = isChecked;
      }
      setItemList(new_itemList);
      const filteredPages = new_itemList
        ?.filter((item: any) => !!item?.isChecked)
        ?.map((item) => item?.value);
      if (!!data?.deliverable)
        patchDeliverable({
          id: data?.id,
          payload: {
            pages: filteredPages || [],
            lease_closeout: closeoutId,
            cover_style: data?.deliverable?.cover_style || 1,
          },
        }).then((res: any) => {
          if (!res) return;
          dispatch({
            type: SET_DETAIL_INFO,
            payload: {
              ...data,
              deliverable: res,
            },
          });
        });
      else
        postDeliverable({
          payload: {
            pages: filteredPages || [
              COVER,
              CONTENT,
              TEAM,
              LEASE_OVERVIEW,
              RENT_EXPENSES,
              FLOORPLAN,
              KEY_DATES,
              ADDITIONAL_CONTACT,
            ],
            lease_closeout: closeoutId,
            cover_style: data?.deliverable?.cover_style || 1,
          },
        }).then((res: any) => {
          if (!res) return;
          dispatch({
            type: SET_DETAIL_INFO,
            payload: {
              ...data,
              deliverable: res,
            },
          });
        });
    } catch (err) {}
  };

  const handleAllChecked = (isChecked: boolean) => {
    try {
      const new_itemList = [...itemList]?.map((item: any) => {
        return { ...item, isChecked };
      });
      setItemList(new_itemList);
      const filteredPages = new_itemList
        ?.filter((item: any) => !!item?.isChecked)
        ?.map((item) => item?.value);
      if (!!data?.deliverable)
        patchDeliverable({
          id: data?.id,
          payload: {
            pages: filteredPages || [
              COVER,
              CONTENT,
              TEAM,
              LEASE_OVERVIEW,
              RENT_EXPENSES,
              FLOORPLAN,
              KEY_DATES,
              ADDITIONAL_CONTACT,
            ],
            lease_closeout: closeoutId,
            cover_style: data?.deliverable?.cover_style || 1,
          },
        }).then((res: any) => {
          if (!res) return;
          dispatch({
            type: SET_DETAIL_INFO,
            payload: {
              ...data,
              deliverable: res,
            },
          });
        });
      else
        postDeliverable({
          payload: {
            pages: filteredPages || [
              COVER,
              CONTENT,
              TEAM,
              LEASE_OVERVIEW,
              RENT_EXPENSES,
              FLOORPLAN,
              KEY_DATES,
              ADDITIONAL_CONTACT,
            ],
            lease_closeout: closeoutId,
            cover_style: data?.deliverable?.cover_style || 1,
          },
        }).then((res: any) => {
          if (!res) return;
          dispatch({
            type: SET_DETAIL_INFO,
            payload: {
              ...data,
              deliverable: res,
            },
          });
        });
    } catch (err) {}
  };

  return (
    <>
      <div className="relative w-full h-full">
        <section className="absolute top-0 left-0 right-0 bottom-0 pt-10 flex pb-4 flex-col px-14 overflow-y-auto">
          <p className="text-jll-text-base-default text-2xl mb-8">PDF Layout</p>
          <div className="py-2.5 flex flex-row items-center justify-end">
            {/* <span className="text-jll-text-base-subdued text-sm">
            Drag and drop to reorder pages. Only selected pages will be included
            in the generated lease package.
          </span> */}
            <div className="flex flex-row items-center space-x-3 mb-4 mr-[26px]">
              <span className="text-jll-text-base-default">Display All</span>
              <Checkbox
                id={""}
                checked={
                  !!itemList?.length &&
                  itemList?.length ===
                    itemList?.filter((item: any) => item.isChecked)?.length
                }
                onChange={(e: any) => handleAllChecked(e?.target?.checked)}
              />
            </div>
          </div>
          <div className="h-full">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="page-reorder">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided?.innerRef}
                    className="flex flex-col space-y-4"
                  >
                    {itemList.map((item: any, idx: number) => (
                      <>
                        {item?.isDragable ? (
                          <Draggable
                            key={idx}
                            draggableId={`item-${idx}`}
                            index={idx}
                          >
                            {(provided, snapshot) => (
                              <DragItem
                                provided={provided}
                                item={item}
                                detailInfo={data}
                                handleSelect={handleSelect}
                                keyData={keyData}
                                setIsOpen={setIsOpen}
                              />
                            )}
                          </Draggable>
                        ) : (
                          <DragItem
                            provided={provided}
                            item={item}
                            detailInfo={data}
                            keyData={keyData}
                            setIsOpen={setIsOpen}
                          />
                        )}
                      </>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </section>
      </div>

      <LeaseOverviewOrderModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default PageReorderDetail;
