import type { FC } from "react";
import Label from "../Label";
import cn from "classnames";
import DotAnimation from "assets/images/dot-animation.svg";

interface ITextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: any;
  className?: string;
  optional?: boolean;
  isLoading?: boolean;
}

const Textarea: FC<ITextareaProps> = ({
  label = "",
  rows = 4,
  error,
  className,
  optional,
  isLoading,
  ...props
}) => {
  return (
    <div className={cn("relative mb-6", className)}>
      {label && (
        <div className="flex flex-row items-center">
          {label ? (
            <Label htmlFor={props.id} className="mb-2">
              {label}
            </Label>
          ) : (
            <span></span>
          )}
        </div>
      )}
      <div>
        <textarea
          rows={rows}
          className={cn(
            "block w-full rounded focus:border-jll-surface-interaction-default focus:ring-jll-surface-interaction-default text-jll-text-base-default placeholder:text-jll-text-base-subdued focus:text-jll-surface-interaction-default disabled:bg-jll-color-coldGray-2",
            {
              "border-jll-color-surface-accent-default": !!error,
              "border-jll-stroke-default": !error,
              "pr-12": isLoading,
            },
          )}
          disabled={isLoading}
          {...props}
        />
        {isLoading && (
          <div
            className={cn(
              "pointer-events-none absolute inset-y-0 right-0 flex items-end pr-3 mb-2",
            )}
          >
            <img src={DotAnimation} />
          </div>
        )}
        {error && (
          <p className="absolute -bottom-4.5 text-sm text-jll-text-rag-danger-accessible">
            {error}
          </p>
        )}
      </div>
    </div>
  );
};

export default Textarea;
