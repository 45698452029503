import {
  AddOutlined,
  CalendarMonthOutlined,
  RemoveOutlined,
} from "@mui/icons-material";
import { useState } from "react";
import { Button, DatePicker, Input } from "ui-atoms";
import { getFormatedDate, getUTCDate } from "utils";
import { add, sub } from "date-fns";
import cn from "classnames";

const DateCalc = () => {
  const [date, setDate] = useState<any>(null);
  const [year, setYear] = useState(0);
  const [month, setMonth] = useState(0);
  const [day, setDay] = useState(0);
  const [mode, setMode] = useState("add"); // add or sub
  const [result, setResult] = useState("");

  const handleCalc = () => {
    if (mode === "add") {
      const re = add(new Date(date), {
        years: year,
        months: month,
        days: day,
      });
      setResult(getFormatedDate(re?.toString()));
    } else {
      const re = sub(new Date(date), {
        years: year,
        months: month,
        days: day,
      });
      setResult(getFormatedDate(re?.toString()));
    }
  };

  return (
    <section className="mt-6">
      <DatePicker
        className="w-full !mb-4"
        trailingIcon={CalendarMonthOutlined}
        selected={date ? getUTCDate(date) : null}
        value={date ? getFormatedDate(getUTCDate(date).toString()) : undefined}
        onChange={(d: any) => setDate(d)}
        label="Date"
        placeholderText="MM/DD/YYYY"
        trailingLabel="Populate with expiration date"
      />
      <div className="flex flex-row space-x-4 items-end mb-6">
        <div className="flex flex-row h-[42px] min-w-[84px]">
          <button
            className={cn(
              "h-full w-full flex justify-center items-center bg-white rounded-l",
              {
                "!bg-jll-color-text-interaction !bg-opacity-5 border border-jll-color-text-interaction mr-px":
                  mode === "add",
                "border border-jll-stroke-default border-r-0": mode !== "add",
              },
            )}
            onClick={() => setMode("add")}
          >
            <AddOutlined
              className={cn("w-6 h-6", {
                "text-jll-text-base-subdued": mode !== "add",
                "text-jll-color-text-interaction": mode === "add",
              })}
            />
          </button>
          <button
            className={cn(
              "h-full w-full flex justify-center items-center bg-white rounded-r",
              {
                "!bg-jll-color-text-interaction !bg-opacity-5 border border-jll-color-text-interaction ml-px":
                  mode === "sub",
                "border border-jll-stroke-default border-l-0": mode !== "sub",
              },
            )}
            onClick={() => setMode("sub")}
          >
            <RemoveOutlined
              className={cn("w-6 h-6", {
                "text-jll-text-base-subdued": mode !== "sub",
                "text-jll-color-text-interaction": mode === "sub",
              })}
            />
          </button>
        </div>
        <Input
          type="number"
          name="year"
          label="Years"
          value={year}
          className="!mb-0"
          onChange={(e: any) => setYear(e?.target.value)}
        />
        <Input
          type="number"
          name="month"
          label="Months"
          value={month}
          className="!mb-0"
          onChange={(e: any) => setMonth(e?.target.value)}
        />
        <Input
          type="number"
          name="day"
          label="Days"
          value={day}
          className="!mb-0"
          onChange={(e: any) => setDay(e?.target.value)}
        />
      </div>
      <div className="flex flex-row justify-end mb-6">
        <Button variant="secondary" onClick={handleCalc} disabled={!date}>
          Calculate
        </Button>
      </div>
      <div className="rounded bg-jll-color-text-interaction flex flex-row justify-between items-center bg-opacity-5 py-4 px-[26px]">
        <span className="text-jll-text-base-subdued text-sm">RESULT</span>
        <span className="text-jll-surface-base-reversed">{result || "-"}</span>
      </div>
    </section>
  );
};

export default DateCalc;
