import { Text, View } from "@react-pdf/renderer";
import styles from "./styles";
import { getDecimalFormating, getFormatedDate } from "utils";
import PageLayoutV2 from "../PageLayoutV2";

interface IPageRentExpenseV2 {
  detail?: any;
  pageNumber?: number;
  rent?: any;
  meta?: any;
  id?: string;
  isLast?: boolean;
}

const PageRentExpenseV2: React.FC<IPageRentExpenseV2> = ({
  detail,
  pageNumber,
  rent,
  meta,
  id,
  isLast,
}) => {
  return (
    <PageLayoutV2
      pageNumber={pageNumber}
      id={id}
      detail={detail}
      headerStyle={{
        backgroundColor: "#F6F9FA",
      }}
    >
      <View style={styles.view}>
        <Text style={{ ...styles.title }}>Rent & Expenses</Text>
        <View style={styles.detail.view}>
          {!!rent?.rent_type && (
            <View style={styles.detail.item.view}>
              <Text style={styles.detail.item.label}>Rent Type</Text>
              <View style={styles.detail.item.valueView}>
                <Text style={styles.detail.item.value}>
                  {
                    meta?.leasecloseoutrentcalculator?.rent_type?.[
                      rent?.rent_type
                    ]?.value
                  }
                </Text>
              </View>
            </View>
          )}
          {!!rent?.rent && (
            <View style={styles.detail.item.view}>
              <Text style={styles.detail.item.label}>Base Rent</Text>
              <View style={styles.detail.item.valueView}>
                <Text style={styles.detail.item.value}>
                  ${getDecimalFormating(rent?.rent, true)}
                </Text>
                <Text style={styles.detail.item.unit}>/RSF/Year</Text>
              </View>
            </View>
          )}
          {!!detail?.lease_rsf && (
            <View style={styles.detail.item.view}>
              <Text style={styles.detail.item.label}>Total Size</Text>
              <View style={styles.detail.item.valueView}>
                <Text style={styles.detail.item.value}>
                  {getDecimalFormating(detail?.lease_rsf)}
                </Text>
                <Text style={styles.detail.item.unit}> RSF</Text>
              </View>
            </View>
          )}
          {!!rent?.term && (
            <View style={styles.detail.item.view}>
              <Text style={styles.detail.item.label}>Term</Text>
              <View style={styles.detail.item.valueView}>
                <Text style={styles.detail.item.value}>{rent?.term}</Text>
                <Text style={styles.detail.item.unit}> months</Text>
              </View>
            </View>
          )}
          {!!rent?.free_rent && (
            <View style={styles.detail.item.view}>
              <Text style={styles.detail.item.label}>Free Rent</Text>
              <View style={styles.detail.item.valueView}>
                <Text style={styles.detail.item.value}>{rent?.free_rent}</Text>
                <Text style={styles.detail.item.unit}> months</Text>
              </View>
            </View>
          )}
          {!!rent?.escalation && (
            <View style={styles.detail.item.view}>
              <Text style={styles.detail.item.label}>Escalation</Text>
              <View style={styles.detail.item.valueView}>
                <Text style={styles.detail.item.value}>
                  {Number(rent?.escalation_type) === 1 ? "" : "$"}
                  {getDecimalFormating(rent?.escalation, true)}
                  {Number(rent?.escalation_type) !== 1 ? "" : "%"}
                </Text>
              </View>
            </View>
          )}
          {!!rent?.escalation_date && (
            <View style={styles.detail.item.view}>
              <Text style={styles.detail.item.label}>
                Escalation Start Date
              </Text>
              <View style={styles.detail.item.valueView}>
                <Text style={styles.detail.item.value}>
                  {getFormatedDate(rent?.escalation_date)}
                </Text>
              </View>
            </View>
          )}
          {!!rent?.operating_expenses && (
            <View style={styles.detail.item.view}>
              <Text style={styles.detail.item.label}>Operating Expenses</Text>
              <View style={styles.detail.item.valueView}>
                <Text style={styles.detail.item.value}>
                  ${getDecimalFormating(rent?.operating_expenses, true)}
                </Text>
                <Text style={styles.detail.item.unit}>/RSF/Year</Text>
              </View>
            </View>
          )}
          {!!rent?.opex_year && (
            <View style={styles.detail.item.view}>
              <Text style={styles.detail.item.label}>OPEX Year</Text>
              <View style={styles.detail.item.valueView}>
                <Text style={styles.detail.item.value}>{rent?.opex_year}</Text>
              </View>
            </View>
          )}
        </View>

        <View style={styles.section.view}>
          <View style={styles.section.list.view}>
            <View style={{ ...styles.section.list.row, height: 30 }}>
              {(!rent?.use_custom ||
                (rent?.use_custom && rent?.colCount > 0)) && (
                <Text
                  style={{
                    ...styles.section.list.item,
                    width: rent?.use_custom
                      ? rent?.colCount > 0
                        ? `${100 / rent?.colCount}%`
                        : "0%"
                      : "25%",
                  }}
                >
                  {rent?.columns?.[0] || ""}
                </Text>
              )}
              {(!rent?.use_custom ||
                (rent?.use_custom && rent?.colCount > 1)) && (
                <Text
                  style={{
                    ...styles.section.list.item,
                    width: rent?.use_custom
                      ? rent?.colCount > 1
                        ? `${100 / rent?.colCount}%`
                        : "0%"
                      : "15%",
                  }}
                >
                  {rent?.columns?.[1] || ""}
                </Text>
              )}
              {(!rent?.use_custom ||
                (rent?.use_custom && rent?.colCount > 2)) && (
                <Text
                  style={{
                    ...styles.section.list.item,
                    width: rent?.use_custom
                      ? rent?.colCount > 2
                        ? `${100 / rent?.colCount}%`
                        : "0%"
                      : "20%",
                  }}
                >
                  {rent?.columns?.[2] || ""}
                </Text>
              )}
              {(!rent?.use_custom ||
                (rent?.use_custom && rent?.colCount > 3)) && (
                <Text
                  style={{
                    ...styles.section.list.item,
                    width: rent?.use_custom
                      ? rent?.colCount > 3
                        ? `${100 / rent?.colCount}%`
                        : "0%"
                      : "20%",
                  }}
                >
                  {rent?.columns?.[3] || ""}
                </Text>
              )}
              {(!rent?.use_custom ||
                (rent?.use_custom && rent?.colCount > 4)) && (
                <Text
                  style={{
                    ...styles.section.list.item,
                    width: rent?.use_custom
                      ? rent?.colCount > 4
                        ? `${100 / rent?.colCount}%`
                        : "0%"
                      : "20%",
                  }}
                >
                  {rent?.columns?.[4] || ""}
                </Text>
              )}
            </View>
            {rent?.rows?.map((row: any, idx: number) => {
              if (!row) return;
              return (
                <View
                  style={{
                    ...styles.section.list.row,
                    backgroundColor: idx % 2 === 0 ? "#F6F9FA" : "white",
                    height: row?.[0]?.includes("\n") ? 40 : 30,
                  }}
                  key={idx}
                >
                  {(!rent?.use_custom ||
                    (rent?.use_custom && rent?.colCount > 0)) && (
                    <Text
                      style={{
                        ...styles.section.list.item,
                        width: rent?.use_custom
                          ? rent?.colCount > 0
                            ? `${100 / rent?.colCount}%`
                            : "0%"
                          : "25%",
                      }}
                    >
                      {row?.[0] || ""}
                    </Text>
                  )}
                  {(!rent?.use_custom ||
                    (rent?.use_custom && rent?.colCount > 1)) && (
                    <Text
                      style={{
                        ...styles.section.list.item,
                        width: rent?.use_custom
                          ? rent?.colCount > 1
                            ? `${100 / rent?.colCount}%`
                            : "0%"
                          : "15%",
                      }}
                    >
                      {row?.[1] || ""}
                    </Text>
                  )}
                  {(!rent?.use_custom ||
                    (rent?.use_custom && rent?.colCount > 2)) && (
                    <Text
                      style={{
                        ...styles.section.list.item,
                        width: rent?.use_custom
                          ? rent?.colCount > 2
                            ? `${100 / rent?.colCount}%`
                            : "0%"
                          : "20%",
                      }}
                    >
                      {row?.[2] || ""}
                    </Text>
                  )}
                  {(!rent?.use_custom ||
                    (rent?.use_custom && rent?.colCount > 3)) && (
                    <Text
                      style={{
                        ...styles.section.list.item,
                        width: rent?.use_custom
                          ? rent?.colCount > 3
                            ? `${100 / rent?.colCount}%`
                            : "0%"
                          : "20%",
                      }}
                    >
                      {row?.[3] || ""}
                    </Text>
                  )}
                  {(!rent?.use_custom ||
                    (rent?.use_custom && rent?.colCount > 4)) && (
                    <Text
                      style={{
                        ...styles.section.list.item,
                        width: rent?.use_custom
                          ? rent?.colCount > 4
                            ? `${100 / rent?.colCount}%`
                            : "0%"
                          : "20%",
                      }}
                    >
                      {row?.[4] || ""}
                    </Text>
                  )}
                </View>
              );
            })}
            {isLast &&
            rent?.monthly_opex &&
            rent?.opex_year &&
            rent?.operating_expenses ? (
              <Text
                style={{
                  ...styles.section.list.footnote,
                  textAlign: "left",
                }}
              >
                **Note that the above rent schedule does not include Operating
                Expenses which, in {rent?.opex_year}, are $
                {rent?.operating_expenses} /RSF/Year, and are estimated to be an
                additional ${getDecimalFormating(rent?.monthly_opex)} per month
                to Tenant**
              </Text>
            ) : (
              <></>
            )}
          </View>
        </View>
      </View>
    </PageLayoutV2>
  );
};

export default PageRentExpenseV2;
