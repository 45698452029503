import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import { GlobalContext } from "context";
import "react-tooltip/dist/react-tooltip.css";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import RootRoutes from "routes";
import { SET_TOOLTIP } from "constant";
import {
  DeleteModal,
  ErrorModal,
  Loading,
  NoDataModal,
  PreviewPDFModal,
} from "ui-molecules";

const App = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const [anchorId, setAnchorId] = useState("");
  const isLocal = process.env?.REACT_APP_AUTH_API_PREFIX_URL?.includes("local")
    ? true
    : false;

  const showTooltip = useCallback(
    (id: string) => {
      if (document.hasFocus()) {
        setAnchorId(id);
      }
    },
    [anchorId],
  );

  const hideTooltip = useCallback(() => {
    setAnchorId("");
  }, [anchorId]);

  useEffect(() => {
    if (!dispatch) return;
    dispatch({
      type: SET_TOOLTIP,
      payload: { showTooltip, hideTooltip },
    });
  }, [dispatch]);

  return (
    <>
      <Helmet prioritizeSeoTags>
        {!isLocal && (
          <meta
            http-equiv="Content-Security-Policy"
            content="upgrade-insecure-requests"
          />
        )}
      </Helmet>
      <RootRoutes />
      <PreviewPDFModal />
      <Loading.Screen />
      <ErrorModal />
      <NoDataModal />
      <DeleteModal />
      <Tooltip
        anchorId={anchorId}
        place="top-start"
        variant="light"
        isOpen={!!anchorId}
      />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
        className="!w-[400px]"
        bodyClassName="!px-[10px] !py-3"
      />
    </>
  );
};

export default App;
