import { useRef, forwardRef } from "react";
import cn from "classnames";
import { DirectionEnum } from "types";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

export interface ThProps {
  className?: string;
  children?: any;
  onClick?: (id: string, direction?: DirectionEnum) => void;
  // TODO: add typing for filters
  filters?: any;
  filterId?: string;
  sortFields?: string[];
  isEdit?: boolean;
  itemClassName?: string;
  draggableProps?: any;
  dragHandleProps?: any;
  handleColumnSubmit?: any;
  currentColumns?: any;
  width?: number | null;
}

const Th = forwardRef<any, ThProps>(
  (
    {
      children,
      className,
      filterId = "",
      onClick,
      sortFields,
      filters,
      isEdit,
      itemClassName,
      handleColumnSubmit,
      currentColumns,
      width,
      ...props
    }: ThProps,
    ref,
  ) => {
    const thRef = useRef(null);
    let direction: DirectionEnum | undefined = undefined;
    const isSortable = sortFields?.includes(filterId);
    const columnRef = useRef<any>([]);

    if (isSortable) {
      if (
        filters?.sort === filterId &&
        filters.direction === DirectionEnum.asc
      ) {
        direction = DirectionEnum.desc;
      } else if (filters?.sort === filterId) {
        direction = DirectionEnum.asc;
      }
    }
    columnRef.current = currentColumns;

    return (
      <th
        scope="col"
        className={cn(
          "p-0 text-left text-sm tracking-wider font-normal text-jll-text-base-default bg-jll-surface-base-secondary-subdued uppercase",
          {
            "cursor-pointer transition-colors hover:text-jll-text-base-subdued":
              isSortable,
          },
          className,
        )}
        style={{ minWidth: width ? `${width}px` : 0 }}
        ref={thRef}
      >
        <div
          className={cn("px-3 py-4 h-full relative", itemClassName, {
            // "!translate-x-0": !props?.draggableProps?.style?.width,
          })}
          ref={ref}
          {...props?.draggableProps}
          style={{
            ...props?.draggableProps?.style,
          }}
        >
          <div className="flex items-center justify-between w-full">
            <div
              className="flex items-center space-x-2 w-full"
              onClick={() => isSortable && onClick?.(filterId, direction)}
            >
              <div className="w-full">{children}</div>
              {isSortable && (
                <>
                  {direction === DirectionEnum.asc && (
                    <ExpandMore className="text-jll-text-base-subdued w-4 h-4" />
                  )}
                  {direction === DirectionEnum.desc && (
                    <ExpandLess className="text-jll-text-base-subdued w-4 h-4" />
                  )}
                  {direction !== DirectionEnum.asc &&
                    direction !== DirectionEnum.desc && (
                      <ExpandMore className="text-jll-text-base-subdued w-4 h-4" />
                    )}
                </>
              )}
            </div>
          </div>
        </div>
      </th>
    );
  },
);

export default Th;
