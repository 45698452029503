import cn from "classnames";

interface IRemoveDescriptionItem {
  description?: string;
  target?: string | number;
  suffix?: string;
}
const RemoveDescriptionItem: React.FC<IRemoveDescriptionItem> = ({
  target,
  description = 'Click "Confirm" to delete',
  suffix,
}) => {
  return (
    <p className="pt-4 pb-6 text-jll-color-text-base-subdued">
      {description}
      <span
        className={cn("font-semibold", {
          "mx-1": !!target,
        })}
      >
        {target || ""}.
      </span>
      {suffix || ""}
    </p>
  );
};

export default RemoveDescriptionItem;
