import { Document, Font } from "@react-pdf/renderer";
import SourceSansProRegular from "assets/fonts/SourceSansPro-Regular.ttf";
import SourceSansProLight from "assets/fonts/SourceSansPro-Light.ttf";
import SourceSansProSemibold from "assets/fonts/SourceSansPro-SemiBold.ttf";
import SourceSansProBold from "assets/fonts/SourceSansPro-Bold.ttf";
import SourceSerifProItalic from "assets/fonts/SourceSerifPro-Light-Italic.ttf";
import { sliceIntoChunks } from "utils";
import {
  ADDITIONAL_CONTACT,
  CONTENT,
  COVER,
  FLOORPLAN,
  KEY_DATES,
  LEASE_OVERVIEW,
  RENT_EXPENSES,
  TEAM,
} from "constant";
import PageCoverV2 from "./PageCoverV2";
import PageContentV2 from "./PageContentV2";
import PageContactV2 from "./PageContactV2";
import PageOverviewV2 from "./PageOverviewV2";
import PageKeyLeaseV2 from "./PageKeyLeaseV2";
import PageRentExpenseV2 from "./PageRentExpenseV2";
import PageFloorplanV2 from "./PageFloorplanV2";
import PageBackV2 from "./PageBackV2";
import PageAdditionalContactV2 from "./PageAdditionalContactV2";

Font.register({
  family: "Source Sans Pro",
  fonts: [
    {
      src: SourceSansProLight,
      fontWeight: 300,
    },
    {
      src: SourceSansProRegular,
      fontWeight: 400,
    },
    {
      src: SourceSansProSemibold,
      fontWeight: 600,
    },
    {
      src: SourceSansProBold,
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "Source Serif Pro Italic",
  fonts: [
    {
      src: SourceSerifProItalic,
      fontWeight: 300,
    },
  ],
});

interface IDeliverablePDF {
  detail: any;
  contactsPerPage?: any;
  rentData?: any;
  meta?: any;
  keyDataPerPage?: any;
  userData?: any;
  overviewPage?: any;
  mapData?: any;
}

const DeliverablePDF = ({
  detail,
  contactsPerPage,
  rentData,
  meta,
  keyDataPerPage,
  userData,
  overviewPage,
  mapData,
}: IDeliverablePDF) => {
  const isJLLAddress =
    !!detail?.jll_building_name?.length ||
    !!detail?.jll_building_address?.length ||
    !!detail?.jll_building_city?.length ||
    !!detail?.jll_building_state?.length ||
    !!detail?.jll_building_zip?.length
      ? true
      : false;

  const isJLLNumber = !!detail?.jll_building_phone?.length ? true : false;
  const limitCountUser = !!isJLLAddress || !!isJLLNumber ? 8 : 10;
  const user_list = !!userData?.length
    ? sliceIntoChunks(userData, limitCountUser)
    : [];

  let newRows: any = [];
  let newRow: any = [];
  let rentHeight = 0;
  rentData?.rows?.forEach((row: any, idx: number) => {
    let h = 0;
    if (row[0]?.includes("\n")) {
      h += 40;
    } else h += 30;
    if (rentHeight + h + (idx === rentData?.rows?.length - 1 ? 50 : 0) > 400) {
      newRows.push(newRow);
      newRow = [row];
      rentHeight = h;
    } else {
      newRow.push(row);
      rentHeight += h;
    }
  });
  if (!!newRow?.length) newRows.push(newRow);

  const rent_list = rentData
    ? !!Object.keys(rentData).length
      ? !!rentData.rows?.length
        ? newRows?.map((row: any) => ({
            ...rentData,
            rows: row,
          }))
        : [{ ...rentData, rows: [] }]
      : []
    : [];

  let page_list: any[] = [];
  let current_pageNumber = 0;
  let pageNumberList: any = [];

  const deliverable_pages = detail?.deliverable?.pages || [
    COVER,
    CONTENT,
    TEAM,
    LEASE_OVERVIEW,
    RENT_EXPENSES,
    FLOORPLAN,
    KEY_DATES,
    ADDITIONAL_CONTACT,
  ];
  deliverable_pages.forEach((pageIdx: number) => {
    let page;
    switch (pageIdx) {
      case ADDITIONAL_CONTACT:
        if (!contactsPerPage?.length) return;
        pageNumberList.push({
          number: current_pageNumber + 1,
          label: meta?.leasecloseoutdeliverable?.pages?.[pageIdx]?.value,
          id: pageIdx,
        });
        page = contactsPerPage?.map((page: any, idx: number) => {
          current_pageNumber++;
          return (
            <PageAdditionalContactV2
              key={idx}
              detail={detail}
              contacts={page}
              pageNumber={current_pageNumber}
              id={idx === 0 ? `Page_Item_${ADDITIONAL_CONTACT}` : ""}
            />
          );
        });
        page_list.push(page);
        break;

      case RENT_EXPENSES:
        if (!rent_list?.length) return;
        pageNumberList.push({
          number: current_pageNumber + 1,
          label: meta?.leasecloseoutdeliverable?.pages?.[pageIdx]?.value,
          id: pageIdx,
        });
        page = rent_list?.map((rent: any, idx: number) => {
          current_pageNumber++;
          return (
            <PageRentExpenseV2
              detail={detail}
              key={idx}
              rent={rent}
              meta={meta}
              pageNumber={current_pageNumber}
              id={idx === 0 ? `Page_Item_${RENT_EXPENSES}` : ""}
              isLast={idx === rent_list?.length - 1}
            />
          );
        });
        page_list.push(page);
        break;

      case FLOORPLAN:
        if (
          !detail?.spaces?.filter(
            (space: any) =>
              space?.active_status === 1 && !!space?.floorplan?.length,
          )?.length
        )
          return;
        pageNumberList.push({
          number: current_pageNumber + 1,
          label: meta?.leasecloseoutdeliverable?.pages?.[pageIdx]?.value,
          id: pageIdx,
        });
        page = detail?.spaces
          ?.filter(
            (space: any) =>
              space?.active_status === 1 && !!space?.floorplan?.length,
          )
          ?.map((space: any, idx: number) => {
            current_pageNumber++;
            return (
              <PageFloorplanV2
                detail={detail}
                space={space}
                pageNumber={current_pageNumber}
                id={idx === 0 ? `Page_Item_${FLOORPLAN}` : ""}
              />
            );
          });
        page_list.push(page);
        break;

      case KEY_DATES:
        if (
          !detail?.commencement_date_description &&
          !detail?.execution_date_description &&
          !detail?.expiration_date_description &&
          !keyDataPerPage?.length
        )
          return;

        pageNumberList.push({
          number: current_pageNumber + 1,
          label: meta?.leasecloseoutdeliverable?.pages?.[pageIdx]?.value,
          id: pageIdx,
        });
        page = keyDataPerPage?.map((item: any, idx: number) => {
          current_pageNumber++;
          return (
            <PageKeyLeaseV2
              key={idx}
              detail={detail}
              keyData={item}
              pageNumber={current_pageNumber}
              id={idx === 0 ? `Page_Item_${KEY_DATES}` : ""}
            />
          );
        });

        page_list.push(page);
        break;

      case LEASE_OVERVIEW:
        pageNumberList.push({
          number: current_pageNumber + 1,
          label: meta?.leasecloseoutdeliverable?.pages?.[pageIdx]?.value,
          id: pageIdx,
        });
        if (!overviewPage?.length) {
          current_pageNumber++;
          return (
            <PageOverviewV2
              detail={detail}
              pageNumber={current_pageNumber}
              id={`Page_Item_${LEASE_OVERVIEW}`}
              mapData={mapData}
            />
          );
        }
        current_pageNumber++;
        page = [
          <PageOverviewV2
            detail={detail}
            pageNumber={current_pageNumber}
            id={`Page_Item_${LEASE_OVERVIEW}`}
            mapData={mapData}
          />,
        ];
        page = [
          ...page,
          ...overviewPage?.map((overview: any, idx: number) => {
            current_pageNumber++;
            return (
              <PageOverviewV2.Detail
                key={idx}
                detail={detail}
                overview={overview}
                pageNumber={current_pageNumber}
              />
            );
          }),
        ];
        page_list.push(page);
        break;

      case COVER:
        page = <PageCoverV2 detail={detail} />;
        page_list.push(page);
        break;

      case CONTENT:
        page = <PageContentV2 pageNumberList={pageNumberList} />;
        page_list.push(page);
        break;

      case TEAM:
        if (!user_list?.length) return;
        pageNumberList.push({
          number: current_pageNumber + 1,
          label: meta?.leasecloseoutdeliverable?.pages?.[pageIdx]?.value,
          id: pageIdx,
        });
        page = user_list?.map((users: any, idx: number) => {
          current_pageNumber++;
          return (
            <PageContactV2
              key={idx}
              users={users}
              detail={detail}
              pageNumber={idx + 1}
              id={idx === 0 ? "Page_Item_0" : ""}
            />
          );
        });
        page_list.push(page);
        break;
    }
  });

  return (
    <Document style={{ fontFamily: "Source Sans Pro", fontWeight: 400 }}>
      {page_list?.map((Page: any) => Page)}

      <PageBackV2 detail={detail} />
    </Document>
  );
};

export default DeliverablePDF;
