import { BASE_RENT_COLUMNS, SET_FULL_LOADING } from "constant";
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { EditableField, Table } from "ui-molecules";
import {
  getDecimalFormating,
  getFormatedDate,
  overrideObject,
  transformObject,
} from "utils";
import cn from "classnames";
import { GlobalContext } from "context";
import { putRentAPI } from "services";
import { useApiCall } from "hooks";
import { useParams } from "react-router-dom";

interface IStandard {
  data?: any;
  isEditing?: boolean;
  setIsEditing?: any;
  setData?: any;
  values?: any;
}

const Standard = forwardRef(
  ({ data, isEditing, setIsEditing, setData, values }: IStandard, ref) => {
    const { dispatch } = useContext(GlobalContext);
    const { closeoutId } = useParams();
    const [putRent] = useApiCall(putRentAPI);
    const [rowData, setRowData] = useState<any>([]);
    const [originData, setOriginData] = useState<any>({ cols: [], rows: [] });

    useEffect(() => {
      if (!data) {
        setRowData([]);
        return;
      }
      if (!data?.overrides) {
        const grid = transformObject(data?.grid) || [];
        const rows = grid?.map((rent) => {
          return [
            `${rent?.["Period Start"] ? getFormatedDate(rent?.["Period Start"]) : ""}${rent?.["Period Start"] && rent?.["Period End"] ? "-" : ""}${rent?.["Period End"] ? getFormatedDate(rent?.["Period End"]) : ""}`,
            `${rent?.["Month Start"]}-${rent?.["Month End"]}`,
            getDecimalFormating(Number(rent?.["Rent PSF"]), true),
            getDecimalFormating(Number(rent?.["Monthly Cost"]), true),
            getDecimalFormating(Number(rent?.["Annual Cost"]), true),
          ];
        });
        setRowData(rows);
        setOriginData({ rows });
      } else {
        const { cols, rows } = overrideObject(data?.overrides);
        setRowData(rows);
        setOriginData({ rows });
      }
    }, [data]);

    useImperativeHandle(ref, () => ({
      onSave() {
        handleSave();
      },
      onCancel() {
        handleCancel();
      },
      onClear() {
        handleClear();
      },
    }));

    const handleSave = () => {
      try {
        dispatch({
          type: SET_FULL_LOADING,
          payload: {
            open: true,
            label: "Updating",
          },
        });
        const columns = BASE_RENT_COLUMNS?.map(({ label }) => label);
        let overrides: any = {};
        [...Array(5)]?.forEach((_, idx) => {
          if (!overrides[idx]) overrides[idx] = {};
          overrides[idx][0] = columns[idx];
        });
        const filteredRows = rowData?.filter((row: any) => {
          let flag = false;
          row?.forEach((item: any) => {
            if (!!item?.length && item !== "-") {
              flag = true;
              return;
            }
          });
          if (flag) return row;
        });
        filteredRows?.forEach((row: any, rowIdx: number) => {
          row?.forEach((val: any, colIdx: number) => {
            if (colIdx >= 2) {
              val = getDecimalFormating(
                Number(val?.toString().replace(",", "")),
                true,
              );
            }
            overrides[colIdx][rowIdx + 1] = val;
          });
        });

        let payload = {
          ...data,
          use_custom: false,
          overrides,
        };

        putRent({ id: closeoutId, payload })
          .then((res: any) => {
            if (!res) {
              setData([]);
              return;
            }
            setData(res);
          })
          .finally(() => {
            dispatch({
              type: SET_FULL_LOADING,
              payload: {
                open: false,
                label: "",
              },
            });
            setIsEditing(false);
          });
      } catch (err) {
        dispatch({
          type: SET_FULL_LOADING,
          payload: {
            open: false,
            label: "",
          },
        });
      }
    };

    const handleCancel = () => {
      setRowData(originData?.rows);
      setIsEditing(false);
    };

    const handleClear = () => {
      setRowData([]);
    };

    return (
      <>
        {!isEditing && !rowData?.length ? (
          <Table.Loading size={6} />
        ) : (
          <div className="h-full -mx-14">
            <Table>
              <Table.Thead>
                <Table.Tr>
                  {BASE_RENT_COLUMNS.map((column: any, idx: number) => (
                    <Table.Th
                      key={idx}
                      itemClassName={cn("w-full flex flex-wrap", {
                        "!pl-14": idx === 0,
                        "!pr-14": idx === BASE_RENT_COLUMNS?.length - 1,
                      })}
                    >
                      {column?.label}
                    </Table.Th>
                  ))}
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {(!isEditing
                  ? [...rowData]
                  : [...rowData, ...Array(1)?.fill(["", "", "", "", ""])]
                )?.map((override: any, idx: number) => (
                  <Table.Tr key={idx}>
                    {override?.map((item: any, idx1: number) => (
                      <Table.Td
                        key={idx1}
                        className={cn({
                          "!pl-14": idx1 === 0,
                          "!pr-14": idx1 === override?.length - 1,
                        })}
                      >
                        <EditableField
                          isCustom={isEditing}
                          leadingCurrency={idx1 >= 2 ? "$" : ""}
                          value={item}
                          kind={idx1 === 0 ? "date" : undefined}
                          type={idx1 >= 2 ? "number" : "text"}
                          rowIndex={idx}
                          colIndex={idx1}
                          rowData={rowData}
                          setRowData={setRowData}
                        >
                          {item}
                        </EditableField>
                      </Table.Td>
                    ))}
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
            {values?.monthly_opex &&
            values?.opex_year &&
            values?.operating_expenses ? (
              <div className="text-sm text-jll-text-base-default flex flex-row justify-center pr-14 pl-14">
                **Note that the above rent schedule does not include Operating
                Expenses which, in {values?.opex_year} are $
                {values?.operating_expenses} /RSF/Year, and are estimated to be
                an additional ${getDecimalFormating(values?.monthly_opex)} per
                month to Tenant**
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </>
    );
  },
);

export default Standard;
