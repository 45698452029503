import { Close } from "@mui/icons-material";
import { Button } from "ui-atoms";
import { Modal } from "ui-molecules";

interface IStatusAbstractionModal {
  isOpen: boolean;
  setIsOpen: any;
}

const StatusAbstractionModal: React.FC<IStatusAbstractionModal> = ({
  isOpen,
  setIsOpen,
}) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="xlarge">
      <Modal.Header>
        <div className="flex flex-row items-start justify-between">
          <div>
            <h3 className="text-2xl leading-6 text-jll-text-base-default mb-2.5">
              Abstraction Status
            </h3>
          </div>
          <Button
            variant="neutral"
            onClick={() => setIsOpen(false)}
            leadingIcon={Close}
            size="large"
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="mt-8"></div>
      </Modal.Body>
    </Modal>
  );
};

export default StatusAbstractionModal;
