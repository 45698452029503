import { FC, useEffect, useState } from "react";
import cn from "classnames";
import { Label } from "ui-atoms";
import { CancelOutlined } from "@mui/icons-material";

interface ICheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: string;
  error?: boolean;
  className?: string;
  labelClassName?: string;
  defaultChecked?: boolean;
  onChange?: any;
  boxSize?: "default" | "small";
  disabled?: boolean;
  isMiss?: boolean;
}

const Checkbox: FC<ICheckboxProps> = ({
  label = "",
  error,
  className,
  defaultChecked = false,
  onChange,
  labelClassName = "",
  boxSize = "default",
  disabled,
  isMiss,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(defaultChecked);
  }, [defaultChecked]);

  const handleChange = (e: any) => {
    setIsChecked(e.target.checked);
    onChange(e);
  };

  let inputClassName;
  if (boxSize === "default") {
    inputClassName = "h-5 w-5";
  } else if (boxSize === "small") {
    inputClassName = "h-4 w-4";
  }

  return (
    <div className={cn("flex space-x-3 items-center", className)}>
      <div className="flex h-5 items-center">
        <input
          type="checkbox"
          className={cn(
            "rounded text-jll-surface-interaction-default cursor-pointer border",
            {
              "border-jll-text-rag-danger-accessible": !!error,
              "border-jll-stroke-default": !error,
              "!border-jll-color-stroke-disabled !text-jll-surface-utilities-disabled p-1 !bg-[url('assets/images/disable-check.svg')]":
                disabled,
            },
            inputClassName,
          )}
          checked={isChecked}
          onChange={handleChange}
          disabled={disabled}
          {...props}
        />
      </div>
      {label && (
        <Label
          htmlFor={props.id}
          className={cn(
            "cursor-pointer text-black !font-normal text-base flex items-center",
            labelClassName,
            {
              "!text-jll-text-rag-danger-accessible": !!error,
            },
          )}
        >
          {!!isMiss && <CancelOutlined className="mr-1 !w-4 !h-4" />}
          {label}
        </Label>
      )}
    </div>
  );
};

export default Checkbox;
